import http from "@/services/http";

export const exportCommunities = params =>
  http.get("/communities/export", {
    params
  });

export const getCommunitiesCount = params =>
  http.get("/communities/count", {
    params
  });
