import { fetchAlerts, fetchAlertTemplate, searchPatients, searchPrescribers } from "@/modules/common/_api";
import * as api from "../_api";

const state = {
  alerts: [],
  count: 0,
  lastRequestId: null,
  alertTemplate: {},
  taxonomies: {
    alertStatuses: [],
    alertReminders: []
  },
  formattedQuery: {}
};

const getters = {
  alerts: state => state.alerts,
  count: state => state.count,
  taxonomies: state => state.taxonomies,
  alertTemplate: state => state.alertTemplate,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ALERTS: (state, alerts) => {
    state.alerts = alerts;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_TAXONOMIES: (state, taxonomies) => {
    state.taxonomies = taxonomies;
  },
  SET_ALERT_TEMPLATE: (state, alertTemplate) => {
    state.alertTemplate = alertTemplate;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  fetchAlerts(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, alertTemplate } = context.state;
      fetchAlerts({ ...params, query: { division: "R", alertTemplateIds: [alertTemplate._id], ...formattedQuery } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { count, results } = res.data.body;
          context.commit("SET_COUNT", count);
          context.commit("SET_ALERTS", results);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAlertTemplate(context, templateId) {
    return new Promise((resolve, reject) => {
      fetchAlertTemplate(templateId)
        .then(res => {
          context.commit("SET_ALERT_TEMPLATE", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { patients, prescribers, creationDate, installationDate, ...formattedQuery } = query;
    if (patients && patients.length) {
      formattedQuery.patientIds = patients.map(item => item._id);
    }
    if (prescribers && prescribers.length) {
      formattedQuery.prescriptionAddressIds = prescribers.map(item => item.prescriptionAddressIds).flat();
    }
    if (creationDate) {
      formattedQuery.creationDateStart = creationDate.start || undefined;
      formattedQuery.creationDateEnd = creationDate.end || undefined;
    }
    if (installationDate) {
      formattedQuery.installationDateStart = installationDate.start || undefined;
      formattedQuery.installationDateEnd = installationDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  resetState(context) {
    context.commit("SET_COUNT", 0);
    context.commit("SET_ALERTS", []);
  },
  updateAlerts(context, data) {
    return api.editAlerts(data);
  },
  exportAlerts(context, data) {
    const { formattedQuery, alertTemplate } = context.state;
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    const { sort, currentCount } = data;
    return api.exportAlerts({
      sort,
      query: {
        division: "R",
        alertTemplateIds: [alertTemplate._id],
        ...formattedQuery,
        user: {
          email,
          firstname,
          lastname,
          _id
        },
        currentCount
      }
    });
  },
  searchPatients(context, input) {
    const query = { input };

    if (context.rootGetters["login/$user"].divisionIds && context.rootGetters["login/$user"].divisionIds.length) {
      query.divisions = context.rootGetters["login/$user"].divisionIds;
    }

    return searchPatients({ query, skip: 0, limit: 10, source: "local" });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  }
};
export default { state, getters, mutations, actions, namespaced: true };
