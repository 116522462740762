import * as api from "../_api";

const state = {
  synchroStatuses: {
    sap: null,
    telesuivi: null
  }
};

const getters = {
  synchroStatuses: state => {
    return {
      sap: state.synchroStatuses.sap,
      telesuivi: state.synchroStatuses.telesuivi
    };
  }
};

const mutations = {
  SET_SYNCHRO_STATUSES: (state, data) => {
    state.synchroStatuses.sap = data.synchroSap;
    state.synchroStatuses.telesuivi = data.synchroTelesuivi;
  }
};

const actions = {
  getSynchroStatuses(context) {
    api.getSynchroStatuses().then(response => {
      if (!response.data) return;
      context.commit("SET_SYNCHRO_STATUSES", { ...response.data.body });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
