import divisions from "@ui/helpers/_constants/urlDivisionsParams";
import { fetchCommunitiesGroups } from "@/modules/common/_api";

import * as api from "../_api";

const state = {
  communitiesGroups: [],
  currentDivision: null,
  count: {
    r: 0,
    d: 0
  },
  currentCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  communitiesGroups: state => state.communitiesGroups,
  currentDivision: state => state.currentDivision,
  count: state => state.count,
  currentCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_COMMUNITIES_GROUPS: (state, communitiesGroups) => {
    state.communitiesGroups = communitiesGroups;
  },
  SET_CURRENT_DIVISION: (state, division) => {
    const currentDivisionParams = divisions.find(item => item.code === division).params;
    state.currentDivision = {
      code: division,
      params: currentDivisionParams
    };
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  getCommunitiesGroupsCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getCommunitiesGroupsCount(params)
        .then(res => {
          context.commit("SET_COUNT", { division: params.query.division.toLowerCase(), count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchCommunitiesGroups(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;

      if (formattedQuery.poleIds && formattedQuery.poleIds.length) {
        formattedQuery.division = "R";
      }

      fetchCommunitiesGroups({ ...params, query: formattedQuery })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_COMMUNITIES_GROUPS", results);
          context.commit("SET_CURRENT_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { communities, delegates, locationCodes, ...formattedQuery } = query;
    if (communities && communities.length) {
      formattedQuery.communityIds = communities.map(item => item._id);
    }
    if (delegates && delegates.length) {
      formattedQuery.delegateIds = delegates.map(item => item._id);
    }
    if (locationCodes && locationCodes.length) {
      formattedQuery.locationCodes = locationCodes.map(item => item.zipCode || item.departmentCode);
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  setCurrentDivision(context, division) {
    context.commit("SET_CURRENT_DIVISION", division);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
