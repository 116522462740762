const getCountryLabelFromCountryCode = require("../getCountryLabelFromCountryCode");

const { getLocationType } = require("../getLocationTypes");

const prelabel = (locationType, platform) => {
  if (!locationType) {
    return;
  }

  return getLocationType(locationType, platform)?.supLabel ?? "";
};

/**
 * Format applies specific format linked with data model to location object
 * @param {Object} location
 * @returns {String}
 */
const formatInterventionLocation = (location, platform) => {
  if (!location) {
    return "";
  }

  let str = "";

  if (location.type) {
    const preLabel = prelabel(location.type, platform);

    if (preLabel) {
      str = `${preLabel} : `;
    }
  }

  if (location.label) {
    str += `${location.label} - `;
  }

  if (location.number) {
    str += location.number;
  }

  if (location.street) {
    if (str) {
      if (location.number) {
        str += ", ";
      }
      str += location.street;
    } else {
      str = location.street;
    }
  }

  if (location.zipCode) {
    if (str) {
      str += `, ${location.zipCode}`;
    } else {
      str = location.zipCode;
    }
  }

  if (location.locality) {
    if (str) {
      str += ` ${location.locality}`;
    } else {
      str = location.locality;
    }
  }

  if (location.countryCode) {
    const country = getCountryLabelFromCountryCode(location.countryCode);

    if (country) {
      if (str) {
        str += `, ${country.toUpperCase()}`;
      } else {
        str = country.toUpperCase();
      }
    }
  }

  return str;
};

module.exports = formatInterventionLocation;
