import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  addReport(context, data) {
    return api.addReport(data);
  },
  fetchReportCount(context, query) {
    return api.fetchReportCount(query);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
