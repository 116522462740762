import moment from "moment";

/**
 * Return formated data for creation or updating user event
 *
 * @param {Object} userEvent user event's form data
 * @returns {Object} formated user event
 */
const bindUserEventData = userEvent => {
  const { startDateTimeAllDay, endDateTimeAllDay, user, ...data } = userEvent;
  const endDateTime = moment(data.endDateTime);
  if (startDateTimeAllDay) {
    data.startDateTime = moment(data.startDateTime).startOf("day").toISOString();
  }
  if (endDateTimeAllDay || endDateTime.hours() === 0) {
    data.endDateTime = moment(data.endDateTime).endOf("day").toISOString();
  }
  if (user) {
    data.userId = user._id;
  }
  return data;
};

export default bindUserEventData;
