/* eslint-disable no-shadow */
import * as api from "../_api";

const state = {
  prescribersGroup: {},
  poles: []
};

const getters = {
  prescribersGroup: state => state.prescribersGroup
};

const mutations = {
  SET_PRESCRIBERS_GROUP: (state, prescribersGroup) => {
    state.prescribersGroup = prescribersGroup;
  },
  UPDATE_LOADING: (state, loadingItems) => {
    state.loading = { ...state.loading, ...loadingItems };
  },
  SET_POLES: (state, poles) => {
    state.poles = poles;
  }
};

const actions = {
  fetchPrescribersGroup(context, idPrescribersGroup) {
    return new Promise((resolve, reject) => {
      api
        .fetchPrescribersGroup(idPrescribersGroup)
        .then(res => {
          context
            .dispatch("bindPrescribersGroup", res.data.body)
            .then(resolve)
            .catch(err => reject(err));
        })
        .catch(err => {
          context.commit("UPDATE_LOADING", {
            prescribersGroup: false
          });
          reject(err);
        });
    });
  },
  bindPrescribersGroup(context, data) {
    return new Promise(resolve => {
      const prescribersGroup = data;
      prescribersGroup.prescribers = prescribersGroup.prescribers.map(({ prescriptionAddresses, ...prescriber }) => ({
        ...prescriber,
        prescriptionAddresses: prescriptionAddresses || []
      }));
      context.commit("SET_PRESCRIBERS_GROUP", prescribersGroup);
      resolve();
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
