import * as api from "../_api";

const state = {
  channels: []
};

const getters = {
  taxonomies: state => state.taxonomies
};

const mutations = {
  SET_CHANNELS: (state, channels) => {
    state.channels = channels;
  },
  SET_TAXONOMIES: (state, taxonomies) => {
    state.taxonomies = taxonomies;
  }
};

const actions = {
  checkCommunityEmailAvailability(context, params) {
    return api.checkCommunityEmailAvailability(params);
  },
  editCommunityInternals(context, data) {
    /*
     * Need to clean community object and map delegates and technicians because they are composed
     * of array of objects (this format is returned by input-search) but we need a different format (cf. json schema)
     */
    const {
      _id,
      delegates,
      patientCountByDivision,
      stock,
      managerIntervenant,
      idecName,
      idecEmail,
      mainTechnician,
      technicians,
      active,
      weekTypes,
      weekDay,
      dayPeriod,
      pendingRecoveries,
      ...community
    } = data;

    if (delegates) {
      community.delegates = delegates.map(delegate => {
        let id = delegate._id;

        if (delegate.delegate) {
          id = delegate.delegate._id;
          return { id, divisionId: delegate.divisionId, date: delegate.date };
        }
        return {};
      });
    }

    community.idec = { name: idecName || null, email: idecEmail.toLowerCase() || null };
    community.pendingRecoveries =
      pendingRecoveries?.map(pendingRecovery => {
        return {
          patientId: pendingRecovery.patient._id,
          patientName: pendingRecovery.patient.name,
          serialNumber: pendingRecovery.serialNumber,
          recoveryDate: pendingRecovery.recoveryDate
        };
      }) || [];
    if (mainTechnician) {
      community.technicians = [
        {
          id: mainTechnician._id,
          divisionId: mainTechnician.divisionId
        }
      ];
    } else {
      community.technicians = [];
    }

    if (active) {
      community.visitProtocol = {
        active,
        weekDay,
        weekTypes,
        dayPeriod
      };
    } else {
      community.visitProtocol = {};
    }

    return api.editCommunityInternals(_id, community);
  },
  editCommunityGlobals(context, data) {
    const { _id, title, patientCountByDivision, stock, pole, ...community } = data;

    community.siren = community?.siret?.length > 9 ? community.siret.substring(0, 9) : "";

    return api.editCommunityGlobals(_id, community);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
