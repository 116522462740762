/* eslint-disable no-shadow */
import { fetchPatients, searchPatients } from "@/modules/common/_api";
import { exportPatients } from "@/modules/patients/_api";
import formatCompleteStock from "@/helpers/_functions/stock/formatCompleteStock";

const state = {
  patients: [],
  patientsLastRequestId: null,
  patientsFormattedQuery: {},
  completeCommunityStock: null
};

const getters = {
  patients: state => state.patients,
  patientsFormattedQuery: state => state.patientsFormattedQuery,
  completeCommunityStock: state => state.completeCommunityStock
};

const mutations = {
  SET_PATIENTS: (state, patients) => {
    state.patients = patients;
  },
  SET_PATIENTS_FORMATTED_QUERY: (state, patientsFormattedQuery) => {
    state.patientsFormattedQuery = patientsFormattedQuery;
  },
  SET_PATIENTS_LAST_REQUEST_ID: (state, requestId) => {
    state.patientsLastRequestId = requestId;
  },
  SET_COMPLETE_COMMUNITY_STOCK: (state, completeCommunityStock) => {
    state.completeCommunityStock = completeCommunityStock;
  }
};

const actions = {
  exportPatients(context, sort) {
    const { patientsFormattedQuery } = context.state;
    return exportPatients({ sort, query: { ...patientsFormattedQuery, division: "R", communityId: context.state.community._id } });
  },
  fetchCompletedCommunityStock(context) {
    return new Promise((resolve, reject) => {
      if (context.state.completeCommunityStock) return resolve();
      context
        .dispatch("getStockContents")
        .then(stock => {
          const formattedStock = formatCompleteStock(stock);
          context.commit("SET_COMPLETE_COMMUNITY_STOCK", formattedStock);
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  fetchPatients(context, { requestId, query, community, ...params }) {
    context.dispatch("formatPatientQuery", query);
    context.commit("SET_PATIENTS_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { patientsFormattedQuery, patientsLastRequestId } = context.state;
      fetchPatients({
        ...params,
        query: { ...patientsFormattedQuery, division: "R", communityId: community ? community?._id : context.state.community._id }
      })
        .then(res => {
          if (!res || !res.data || !res.data.body || patientsLastRequestId !== requestId) {
            return resolve();
          }
          context.commit("SET_PATIENTS", res.data.body.results);
          return resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatPatientQuery(context, query) {
    const { installationDate, ...patientsFormattedQuery } = query;
    if (installationDate) {
      patientsFormattedQuery.installationDateStart = installationDate.start || undefined;
      patientsFormattedQuery.installationDateEnd = installationDate.end || undefined;
    }
    context.commit("SET_PATIENTS_FORMATTED_QUERY", patientsFormattedQuery);
  },
  searchPatients(context, input) {
    const { community } = context.state;
    return searchPatients({ query: { input, communityId: community._id, activeStatus: ["active"] }, skip: 0, limit: 40, source: "local" });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
