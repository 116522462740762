import eachSeries from "async/eachSeries";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import { fetchUser, fetchCommunity } from "@/modules/common/_api";
import { editCommunityTourComment, editCommunityRealStock } from "@/modules/community/edit/_api";
import * as api from "../../common/_api";
import * as apiIntervention from "../../../interventions/view/_api";
import * as apiEntityIntervention from "../_api";

import _entityDeliveries from "./entityDeliveries";
import _anomaly from "./anomaly";
import _entityStock from "./entityStock";

const entityTypes = require("@common/constants/entityTypes");

/* Factory function to create state on demand, so we can use it on destroy to reset clean state */
const getDefaultState = () => {
  return {
    ..._entityDeliveries.state,
    ..._anomaly.state,
    ..._entityStock.state,
    entityIntervention: null,
    rawEntityIntervention: null,
    technician: null,
    community: null,
    locations: [],
    formattedLocations: [],
    nextAction: null,
    nextRoute: null,
    interventionsLinked: [],
    feedbackMessages: {
      ..._entityDeliveries.state.feedbackMessages,
      success: null,
      error: null,
      errorForm: null
    },
    loading: {
      ..._entityDeliveries.state.loading,
      ..._entityStock.state.loading,
      fetchEntityIntervention: true,
      submit: false
    }
  };
};

const state = getDefaultState();

const getters = {
  ..._entityDeliveries.getters,
  ..._anomaly.getters,
  ..._entityStock.getters,
  entityIntervention: state => state.entityIntervention,
  rawEntityIntervention: state => state.rawEntityIntervention,
  formattedLocations: state => state.formattedLocations,
  technician: state => state.technician,
  taxonomies: state => state.taxonomies,
  community: state => state.community,
  feedbackMessages: state => state.feedbackMessages,
  loading: state => state.loading,
  nextAction: state => state.nextAction,
  nextRoute: state => state.nextRoute,
  interventionsLinked: state => state.interventionsLinked
};

const mutations = {
  ..._entityDeliveries.mutations,
  ..._anomaly.mutations,
  ..._entityStock.mutations,
  SET_ENTITY_INTERVENTION: (state, entityIntervention) => {
    state.entityIntervention = entityIntervention;
  },
  SET_INTERVENTIONS_LINKED: (state, interventionsLinked) => {
    state.interventionsLinked = interventionsLinked;
  },
  SET_RAW_ENTITY_INTERVENTION: (state, rawEntityIntervention) => {
    state.rawEntityIntervention = rawEntityIntervention;
  },
  SET_TECHNICIAN: (state, technician) => {
    state.technician = technician;
  },
  SET_TAXONOMIES: (state, taxonomies) => {
    state.taxonomies = taxonomies;
  },
  SET_COMMUNITY: (state, community) => {
    state.community = community;
  },
  SET_LOCATIONS: (state, locations) => {
    state.locations = locations;
  },
  SET_FORMATTED_LOCATIONS: (state, locations) => {
    state.formattedLocations = locations;
  },
  SET_NEXT_ACTION: (state, nextAction) => {
    state.nextAction = nextAction;
  },
  SET_NEXT_ROUTE: (state, nextRoute) => {
    state.nextRoute = nextRoute;
  },
  SET_FEEDBACK_MESSAGES: (state, messages) => {
    state.feedbackMessages = messages;
  },
  UPDATE_LOADING: (state, loadingItems) => {
    state.loading = { ...state.loading, ...loadingItems };
  },
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  ..._entityDeliveries.actions,
  ..._anomaly.actions,
  ..._entityStock.actions,
  fetchInterventionsLinked(context) {
    return new Promise((resolve, reject) => {
      const entityInterventionId = context.state.entityIntervention._id;

      apiEntityIntervention
        .fetchInterventionsLinkedByEntityInterventionId(entityInterventionId)
        .then(res => {
          const interventionsLinked = res?.data?.data?.body || null;
          if (!interventionsLinked) {
            context.commit("SET_INTERVENTIONS_LINKED", null);
            return resolve();
          }
          context.commit("SET_INTERVENTIONS_LINKED", interventionsLinked);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchEntityInterventionView(context, entityInterventionId) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { fetchEntityIntervention: true });

      /* First we fetch the intervention and taxonomies */
      Promise.all([
        context.dispatch("fetchEntityIntervention", entityInterventionId),
        context.dispatch(
          "common/fetchCommon",
          [
            "fileTypes",
            "entityTypes",
            "entityInterventionStatuses",
            "entityInterventionTypes",
            "communityAffiliationTypes",
            "communityEmergencySolutions",
            "deliveryStatuses",
            "forfaits",
            "treatmentTypes"
          ],
          { root: true }
        )
      ])
        .then(() => {
          Promise.all([context.dispatch("fetchCommunity"), context.dispatch("fetchTechnician"), context.dispatch("fetchInterventionsLinked")])
            .then(() => {
              resolve();
            })
            .catch(error => {
              context.commit("SET_FEEDBACK_MESSAGES", { error: error?.data?.errorMessage || defaultErrorMessages.fetch });
              reject(error);
            })
            .finally(() => {
              context.commit("UPDATE_LOADING", { fetchEntityIntervention: false });
            });
        })
        .catch(error => {
          context.commit("SET_FEEDBACK_MESSAGES", { error: error?.data?.errorMessage || defaultErrorMessages.fetch });
          context.commit("UPDATE_LOADING", { fetchEntityIntervention: false });
          reject(error);
        });
    });
  },
  fetchEntityIntervention(context, id) {
    return new Promise((resolve, reject) => {
      api
        .fetchEntityIntervention(id)
        .then(res => {
          const entityIntervention = res?.data?.body || null;

          if (!entityIntervention) {
            context.commit("SET_ENTITY_INTERVENTION", null);
            context.commit("SET_RAW_ENTITY_INTERVENTION", null);
            return resolve();
          }

          const { technician, entity, ...rawEntityIntervention } = entityIntervention;
          context.commit("SET_RAW_ENTITY_INTERVENTION", rawEntityIntervention);
          context.commit("SET_ENTITY_INTERVENTION", entityIntervention);

          const stockId = rawEntityIntervention?.stockId;

          if (stockId) {
            context.dispatch("common/setSearchInStocksStockId", stockId, { root: true });
          }

          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTechnician(context) {
    return new Promise((resolve, reject) => {
      const { intervenantId } = context.state.entityIntervention;
      fetchUser(intervenantId, true)
        .then(res => {
          context.commit("SET_TECHNICIAN", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCommunity(context) {
    return new Promise((resolve, reject) => {
      const { entityId, entityTypeId } = context.state.entityIntervention;
      if (entityTypeId === entityTypes.COMMUNITY) {
        fetchCommunity(entityId)
          .then(res => {
            const community = res.data?.body || null;

            context.commit("SET_COMMUNITY", community);

            const poleStockId = community?.pole?.stockId;

            if (poleStockId) {
              context.dispatch("common/setSearchInStocksPoleStockId", poleStockId, { root: true });
            }

            resolve();
          })
          .catch(err => reject(err));
      } else {
        context.commit("SET_COMMUNITY", null);
        resolve();
      }
    });
  },
  editEntityIntervention(context, data) {
    return new Promise((resolve, reject) => {
      const { technician, plannedDate, ...update } = data;
      const entityIntervention = context.state.rawEntityIntervention;

      if (technician) {
        entityIntervention.intervenantId = technician._id;
        entityIntervention.intervenantExternalId = technician.externalId;
      }

      if (plannedDate) {
        entityIntervention.plannedDateTimeStart = plannedDate.start;
        entityIntervention.plannedDateTimeEnd = plannedDate.end;
      }

      api
        .updateEntityIntervention(entityIntervention._id, { ...entityIntervention, ...update })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  achieveEntityIntervention(context, data) {
    return new Promise((resolve, reject) => {
      context.commit("SET_FEEDBACK_MESSAGES", { errorMvt: null });

      const { realStock, ...dataForEntityInterventionAchievement } = data;
      const entityIntervention = { ...context.state.entityIntervention, ...dataForEntityInterventionAchievement };
      const interventions = context.state.interventionsLinked;

      eachSeries(
        interventions,
        (intervention, nextIntervention) => {
          Promise.allSettled([
            apiIntervention.createLocationsOrder({ interventionId: intervention._id, deliveryDate: intervention.dateTimeStart }),
            apiIntervention.createSalesOrder({ interventionId: intervention._id, deliveryDate: intervention.dateTimeStart }),
            apiIntervention.createRecup({ interventionId: intervention._id, removeDate: intervention.dateTimeStart })
          ])
            .then(([locationsOrderResult, salesOrderResult, recupResult]) => {
              if ([locationsOrderResult.status, salesOrderResult.status, recupResult.status].includes("rejected")) {
                context.commit("SET_FEEDBACK_MESSAGES", {
                  error: defaultErrorMessages.stockMovment
                });
              }
            })
            .finally(() => {
              apiIntervention
                .achieveIntervention(intervention._id, { intervention })
                .then(() => {
                  nextIntervention();
                })
                .catch(error => {
                  context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.update });
                });
            });
        },
        errLoop => {
          if (errLoop) {
            return reject(errLoop);
          }

          Promise.allSettled([
            api.createEntityStockMovement({ entityInterventionId: entityIntervention._id }),
            api.createEntitySalesOrder({ entityInterventionId: entityIntervention._id }),
            api.createEntityRecup({ entityInterventionId: entityIntervention._id })
          ])
            .then(([entityOrderResult, entityRecupResult]) => {
              if ([entityOrderResult.status, entityRecupResult.status].includes("rejected")) {
                context.commit("SET_FEEDBACK_MESSAGES", {
                  errorMvt: defaultErrorMessages.stockMovment
                });
              }
            })
            .finally(() => {
              editCommunityRealStock(context.state.community._id, { realStock }).finally(() => {
                api
                  .achieveEntityIntervention(entityIntervention._id, { entityIntervention })
                  .then(response => {
                    context.commit("SET_FEEDBACK_MESSAGES", { success: response.data.successMessage });
                    resolve(response);
                  })
                  .catch(error => {
                    context.commit("SET_FEEDBACK_MESSAGES", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.update });
                    reject(error);
                  })
                  .finally(() => {
                    context.commit("UPDATE_LOADING", { submit: false });
                  });
              });
            });
        }
      );
    });
  },
  validateEntityIntervention(context) {
    const { entityIntervention } = context.state;
    return new Promise((resolve, reject) => {
      return api
        .validateEntityIntervention(entityIntervention._id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteEntityIntervention(context, id) {
    return api.deleteEntityIntervention(id);
  },
  deleteEntityInterventionAndReplanned(context, entityInterventionId) {
    return new Promise((resolve, reject) => {
      api
        .deleteEntityInterventionAndReplanned(entityInterventionId)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editCommunityTourComment(context, tourComment) {
    return editCommunityTourComment(context.state.community._id, { tourComment });
  },
  updateFeedbackMessages(context, message) {
    const { feedbackMessages } = context.state;
    context.commit("SET_FEEDBACK_MESSAGES", { ...feedbackMessages, ...message });
  },
  cleanFeedbackMessages(context) {
    const { feedbackMessages } = context.state;
    Object.keys(feedbackMessages).forEach(key => {
      feedbackMessages[key] = null;
    });
    context.commit("SET_FEEDBACK_MESSAGES", feedbackMessages);
  },
  resetEntityIntervention(context) {
    context.commit("RESET_STATE");
  },
  updateNextAction(context, nextAction) {
    context.commit("SET_NEXT_ACTION", nextAction);
  },
  updateNextRoute(context, nextRoute) {
    context.commit("SET_NEXT_ROUTE", nextRoute);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
