import { iconSettings } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuUsers from "./subMenuUsers";

const localPath = "menus.administration.settings";

const {
  usersRights: { VIEW_USER }
} = require("@common/services/acm/rights");

const menuUsersLogs = {
  title: localize(localPath, "settings.title"),
  slug: "settings",
  icon: iconSettings,
  notificationName: "countUnreadSupportTickets",
  disabled: false,
  rights: [VIEW_USER],
  items: [subMenuUsers]
};
export default menuUsersLogs;
