import i18n from "@/i18n";

const i18nKey = "helpers._constants.urlDivisionsParams";

export default [
  {
    params: i18n.t(`${i18nKey}.respiratory.params`),
    code: "R",
    label: i18n.t(`${i18nKey}.respiratory.label`)
  },
  {
    params: i18n.t(`${i18nKey}.nutrition.params`),
    code: "N",
    label: i18n.t(`${i18nKey}.nutrition.label`)
  },
  {
    params: i18n.t(`${i18nKey}.perfusion.params`),
    code: "P",
    label: i18n.t(`${i18nKey}.perfusion.label`)
  },
  {
    params: i18n.t(`${i18nKey}.diabete.params`),
    code: "D",
    label: i18n.t(`${i18nKey}.diabete.label`)
  }
];
