/* eslint-disable no-param-reassign */
import { searchPatients, searchUsers, searchPrescribers } from "@/modules/common/_api";
import calculateTotalPatientsCount from "@ui/helpers/_functions/calculateTotalPatientsCount";

import * as api from "../_api";

const state = {
  countPatients: {
    vni: null,
    ppc: null
  },
  totalPatients: null
};

const getters = {
  countPatients: state => state.countPatients,
  totalPatients: state => state.totalPatients
};

const mutations = {
  SET_COUNT_PATIENTS: (state, countPatients) => {
    state.countPatients = countPatients;
  },
  SET_TOTAL_PATIENTS: (state, totalPatients) => {
    state.totalPatients = totalPatients;
  }
};

const actions = {
  addTemplate(context, { patients, prescriptionAddresses, prescribers, flm, frq, iah, obs, vlm, ...data }) {
    return new Promise((resolve, reject) => {
      // rebind && remove prescriptionAddresses key from data
      if (prescriptionAddresses && prescriptionAddresses.length) {
        data.prescrIds = prescriptionAddresses.map(prescriptionAddr => prescriptionAddr._id);
      }

      // rebind && remove patients key from data
      if (patients && patients.length) {
        const contracts = patients.map(item => item.contracts).flat();
        data.contractIds = contracts.map(item => item.id);
      }

      /* rebind && remove rules keys from data */
      const rulesIn = Object.entries({ flm, frq, iah, obs, vlm })
        .map(rule => {
          return rule[1]
            ? {
                key: rule[0],
                value: rule[1]
              }
            : null;
        })
        .filter(item => item);
      data.rulesIn = rulesIn;

      if (["ppc-no-data", "vni-no-data"].includes(data.templateTypeId)) {
        data.rulesIn.push({ key: "no_data" });
      }

      switch (data.templateTypeId) {
        case "ppc":
        case "ppc-no-data":
          data.treatmentTypeId = "ppc";
          break;
        case "vni":
        case "vni-no-data":
          data.treatmentTypeId = "vni";
          break;
        default:
          break;
      }

      data.totalPatients = context.state.totalPatients;

      api
        .addAlertTemplate(data)
        .then(resolve)
        .catch(err => reject(err));
    });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  },
  searchPatients(context, input) {
    const query = { input, patientContractsStatus: "active" };

    if (context.rootGetters["login/$user"].divisionIds && context.rootGetters["login/$user"].divisionIds.length) {
      query.divisions = context.rootGetters["login/$user"].divisionIds;
    }

    return searchPatients({ query, skip: 0, limit: 10, source: "local" });
  },
  searchUsers(context, input) {
    return searchUsers({
      query: { input, active: true },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  updateCountPatients(context, templateTypeId) {
    return new Promise((resolve, reject) => {
      if (!templateTypeId) {
        resolve();
      }
      const { countPatients } = context.state;
      /* templateTypeId could be "vni", "ppc", "vni-no-data" or "ppc-no-data", so we have to check if
      templateTypeId's string contains ppc or vni to use templateTypeId on server request */
      const countType = templateTypeId.includes("vni") ? "vni" : "ppc";
      if (!countPatients[countType]) {
        api
          .getCountPatients(countType)
          .then(res => {
            countPatients[countType] = res.data.body;
            context.commit("SET_COUNT_PATIENTS", countPatients);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve();
      }
    });
  },
  updateTotalPatients(context, template) {
    let total = null;
    const { countPatients } = context.state;
    switch (template.monitoringTypeId) {
      case "patient":
        total = template && template.patients ? template.patients.length : null;
        break;
      case "general":
        if (!template || !template.templateTypeId) {
          break;
        }
        /* templateTypeId could be "vni", "ppc", "vni-no-data" or "ppc-no-data", so we have to check if
        templateTypeId's string contains ppc or vni to print countPatients.vni or countPatients.ppc */
        if (template.templateTypeId.includes("vni")) {
          total = countPatients.vni;
        }
        if (template.templateTypeId.includes("ppc")) {
          total = countPatients.ppc;
        }
        break;
      case "prescriber":
        total =
          template && template.prescriptionAddresses
            ? template.prescriptionAddresses.reduce((sum, current) => {
                return sum + calculateTotalPatientsCount(current.patientsCount);
              }, 0)
            : null;
        break;
      default:
        break;
    }
    context.commit("SET_TOTAL_PATIENTS", total);
  },
  resetTotalPatient(context) {
    context.commit("SET_TOTAL_PATIENTS", null);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
