import * as api from "../_api";
/* eslint-disable no-shadow */

const state = {
  product: {},
  loading: true
};

const getters = {
  loading: state => state.loading,
  product: state => state.product
};

const mutations = {
  UPDATE_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_PRODUCT: (state, product) => {
    state.product = product;
  }
};

const actions = {
  fetchProduct(context, idProduct) {
    return new Promise((resolve, reject) => {
      api
        .fetchProduct(idProduct)
        .then(res => {
          const product = res.data.body;
          if (product.providerId) {
            api
              .fetchProvider(product.providerId)
              .then(resProvider => {
                product.provider = resProvider.data.body;
                context.commit("SET_PRODUCT", product);
                resolve();
              })
              .catch(errFetchProvider => {
                context.commit("SET_PRODUCT", product);
                reject(errFetchProvider);
              });
          } else {
            context.commit("SET_PRODUCT", product);
            resolve();
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateLoadingState({ commit }, state) {
    commit("UPDATE_LOADING", state);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
