/**
 * Helper use in edit/add prescriber store.
 * The data model of prescriber need only one array, "prescriptionAddressIds".
 * This one is composed of a main address id, alway in first index, and other optional addresses ids
 *
 * @param {Array} mainPrescriptionAddressIds mainPrescriptionAddressIds, its an array with only 1 ID in it.
 * @param {Array} otherPrescriptionAddressIds otherPrescriptionAddressIds
 * @returns
 */
const mergeMainAndOtherPrescriptionAddressIds = (mainPrescriptionAddressIds, otherPrescriptionAddressIds) => {
  if (Array.isArray(mainPrescriptionAddressIds) && mainPrescriptionAddressIds.length > 0 && Array.isArray(otherPrescriptionAddressIds)) {
    // Filter to clear mainPrescriptionAddressIds from otherPrescriptionAddressIds to avoid duplication
    const mergePrescriptionAddressIds = otherPrescriptionAddressIds.filter(otherId => otherId !== mainPrescriptionAddressIds[0]);
    // Push main to first index
    mergePrescriptionAddressIds.unshift(mainPrescriptionAddressIds[0]);

    return mergePrescriptionAddressIds;
  }

  if (Array.isArray(otherPrescriptionAddressIds)) {
    return otherPrescriptionAddressIds;
  }

  if (Array.isArray(mainPrescriptionAddressIds)) {
    return mainPrescriptionAddressIds;
  }

  return [];
};

export default mergeMainAndOtherPrescriptionAddressIds;
