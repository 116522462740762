import { render, staticRenderFns } from "./Expand-more.vue?vue&type=template&id=22026f4a&functional=true"
import script from "./Expand-more.vue?vue&type=script&lang=js"
export * from "./Expand-more.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../client/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports