import { iconSearch, iconAddPatientOutlined, iconPatientOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.patients-medecins.patients";

const {
  menuRights: { VIEW_MENU_SEARCH_PATIENT },
  patientsRights: { CREATE_PATIENT, ACCESS_PATIENT }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconSearch,
    title: localize(localPath, "link--patientSearch"),
    routerLink: {
      name: "patientSearch"
    },
    rights: [ACCESS_PATIENT, VIEW_MENU_SEARCH_PATIENT]
  },
  {
    icon: iconAddPatientOutlined,
    title: localize(localPath, "link--patientAddSearch"),
    routerLink: {
      name: "patientAddSearch"
    },
    rights: [CREATE_PATIENT]
  },
  {
    icon: iconPatientOutlined,
    title: localize(localPath, "link--patientsList"),
    routerLink: {
      name: "patients",
      alias: "patientsList"
    },
    rights: [ACCESS_PATIENT]
  }
];

const subMenuPatients = {
  title: localize(localPath, "title"),
  slug: "patients",
  links
};

export default subMenuPatients;
