const { SECRETAIRE_FACTURATION, SECRETAIRE_RCO, PHARMACIEN, INTERVENANT } = require("@common/constants/roles");
const features = require("@/services/features");

export default [
  {
    id: "achievedInterventions",
    description: "Interventions with achieved status",
    active: true,
    roles: [SECRETAIRE_FACTURATION]
  },
  {
    id: "achievedInterventionsForPatientsInCommunity",
    description: "Achieved interventions for patients in community",
    active: true,
    roles: [SECRETAIRE_RCO]
  },
  {
    id: "achievedEntityInterventions",
    description: "Achieved interventions for community deliveries",
    active: features.isEnabled("communityEntityInterventions"),
    roles: [SECRETAIRE_FACTURATION, SECRETAIRE_RCO]
  },
  {
    id: "desappareillageInterventions",
    description: "Intenvetions with unpair type",
    active: true,
    roles: [SECRETAIRE_FACTURATION]
  },
  {
    id: "conversationsWithAttachment",
    description: "Conversations with attachment",
    active: true,
    roles: [SECRETAIRE_FACTURATION]
  },
  {
    id: "alerts",
    description: "Alerts list",
    active: true,
    roles: [INTERVENANT]
  },
  {
    id: "riskAnalysis",
    description: "Risk analysis List",
    active: true,
    roles: [PHARMACIEN]
  }
];
