import http from "@/services/http";

export const createReminder = params => http.post(`/reminders`, { params });

export const updateReminder = params => http.put(`/reminders`, { params });

export const fetchReminders = params => http.get(`/reminders`, { params });

export const fetchRemindersByPatientId = patientId => http.get(`/reminders/${patientId}`);

export const fetchLastReminders = patientId => http.get(`/reminders/last/${patientId}`);

export const fetchRemindersCount = query => http.get(`/reminders/count`, { query });
