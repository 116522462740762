import { iconCalendarMonth, iconList, iconInterventionOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.interventions-tours.work";

const {
  interventionsRights: { ACCESS_INTERVENTION },
  productsRights: { ACCESS_PRODUCT },
  interventionsRights: { WRITE_RISK_ANALYSIS },
  menuRights: { VIEW_MENU_MON_TRAVAIL }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconInterventionOutlined,
    title: localize(localPath, "link--productsStock"),
    rights: [ACCESS_PRODUCT],
    routerLink: {
      name: "productsStock"
    }
  },
  {
    icon: iconInterventionOutlined,
    title: localize(localPath, "link--interTour"),
    rights: [ACCESS_INTERVENTION],
    routerLink: {
      name: "interventionsSingleTour"
    }
  },
  {
    icon: iconCalendarMonth,
    title: localize(localPath, "link--interCalendar"),
    rights: [ACCESS_INTERVENTION],
    routerLink: {
      name: "interventionsCalendar"
    }
  },
  {
    icon: iconList,
    title: localize(localPath, "link--interWaiting"),
    rights: [ACCESS_INTERVENTION],
    routerLink: {
      name: "interventionsWaitingStatus"
    }
  },
  {
    icon: iconList,
    title: localize(localPath, "link--interReady"),
    rights: [ACCESS_INTERVENTION],
    routerLink: {
      name: "interventionsReadyStatus"
    }
  },
  {
    icon: iconList,
    title: localize(localPath, "link--riskAnalysisToClose"),
    rights: [WRITE_RISK_ANALYSIS],
    routerLink: {
      name: "interventionsWithRiskAnalysisToClose"
    }
  }
];

const subMenuIntervention = {
  title: localize(localPath, "title"),
  slug: "mon-travail",
  rights: [VIEW_MENU_MON_TRAVAIL],
  links
};

export default subMenuIntervention;
