import { COMMUNITY } from "@common/constants/entityTypes";
import {
  fetchEntityInterventions,
  createEntityIntervention,
  exportEntityInterventions,
  forceCommunityPlanification
} from "@/modules/entity-interventions/common/_api";

const state = {
  entityInterventions: [],
  entityInterventionsLastRequestId: null,
  entityInterventionsFormattedQuery: {},
  entityInterventionsCurrentCount: 0
};

const getters = {
  entityInterventions: state => state.entityInterventions,
  entityInterventionsFormattedQuery: state => state.entityInterventionsFormattedQuery,
  entityInterventionsCurrentCount: state => state.entityInterventionsCurrentCount
};

const mutations = {
  SET_ENTITY_INTERVENTIONS: (state, entityInterventions) => {
    state.entityInterventions = entityInterventions;
  },
  SET_ENTITY_INTERVENTIONS_FORMATTED_QUERY: (state, entityInterventionsFormattedQuery) => {
    state.entityInterventionsFormattedQuery = entityInterventionsFormattedQuery;
  },
  SET_ENTITY_INTERVENTIONS_LAST_REQUEST_ID: (state, requestId) => {
    state.entityInterventionsLastRequestId = requestId;
  },
  SET_ENTITY_INTERVENTIONS_CURRENT_COUNT: (state, entityInterventionsCurrentCount) => {
    state.entityInterventionsCurrentCount = entityInterventionsCurrentCount;
  }
};

const actions = {
  exportEntityInterventions(context, sort) {
    const { entityInterventionsFormattedQuery } = context.state;
    return exportEntityInterventions({ sort, query: { ...entityInterventionsFormattedQuery, entityId: context.state.community._id } });
  },
  fetchEntityInterventions(context, { requestId, query, ...params }) {
    context.dispatch("formatEntityInterventionsQuery", query);
    context.commit("SET_ENTITY_INTERVENTIONS_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { entityInterventionsFormattedQuery, entityInterventionsLastRequestId } = context.state;
      fetchEntityInterventions({ ...params, query: { ...entityInterventionsFormattedQuery, entityId: context.state.community._id } })
        .then(res => {
          if (entityInterventionsLastRequestId !== requestId) {
            return resolve();
          }
          context.commit("SET_ENTITY_INTERVENTIONS", res?.data?.body?.results || []);
          context.commit("SET_ENTITY_INTERVENTIONS_CURRENT_COUNT", res?.data?.body?.count || 0);
          return resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatEntityInterventionsQuery(context, query) {
    const { date, ...entityInterventionsFormattedQuery } = query;
    if (date) {
      entityInterventionsFormattedQuery.dateStart = date.start || undefined;
      entityInterventionsFormattedQuery.dateEnd = date.end || undefined;
    }
    context.commit("SET_ENTITY_INTERVENTIONS_FORMATTED_QUERY", entityInterventionsFormattedQuery);
  },
  createEntityIntervention(context, data) {
    const { technician, plannedDate, locationIndex, ...entityIntervention } = data;
    const { community } = context.state;

    entityIntervention.intervenantExternalId = technician.externalId;
    entityIntervention.intervenantId = technician._id;
    entityIntervention.location = community.locations[locationIndex];
    entityIntervention.plannedDateTimeStart = plannedDate.start || null;
    entityIntervention.plannedDateTimeEnd = plannedDate.end || null;
    entityIntervention.entityId = community._id;
    entityIntervention.entityTypeId = COMMUNITY;
    entityIntervention.channelId = community.channelId;

    return createEntityIntervention(entityIntervention);
  },
  forceCommunityPlanification(context, communityId) {
    return new Promise((resolve, reject) => {
      forceCommunityPlanification(communityId)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
