import { iconInterventionOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuInterventions from "./sub-menus/subMenuInterventions";
import subMenuMyWork from "./sub-menus/subMenuMyWork";
import subMenuEntityInterventions from "./sub-menus/subMenuEntityInterventions";

const localPath = "menus.administration.interventions-tours";

const features = require("../../../services/features");

const menuAppointments = {
  title: localize(localPath, "title"),
  slug: "interventions-tournees",
  icon: iconInterventionOutlined,
  disabled: false,
  items: [subMenuInterventions, subMenuMyWork, ...(features.isCommunityEntityInterventionsEnabled() ? [subMenuEntityInterventions] : [])]
};

export default menuAppointments;
