import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin.reminders";

const PageAddReminder = () => import(/* webpackChunkName: "public" */ "@/modules/reminders/view/PageAddReminder");
const PageAllReminders = () => import(/* webpackChunkName: "public" */ "@/modules/reminders/view/PageAllReminders");

export default [
  {
    path: "reminders/add-reminder",
    alias: "addReminder",
    name: "addReminder",
    component: PageAddReminder,
    meta: {
      title: localize(localPath, "title--addReminder")
    }
  },
  {
    path: "reminders/all-reminders",
    alias: "allReminders",
    name: "allReminders",
    component: PageAllReminders,
    meta: {
      title: localize(localPath, "title--allReminders")
    }
  }
];
