import { editBanner, editNews, deleteBanner, deleteNews, fetchPublication, checkBannerPublication, getNewsPublicationState } from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  editBanner(context, data) {
    return editBanner(data);
  },
  editNews(context, data) {
    return editNews(data);
  },
  deleteBanner(context, data) {
    return deleteBanner(data);
  },
  deleteNews(context, data) {
    return deleteNews(data);
  },
  fetchPublication(context, id) {
    return new Promise((resolve, reject) => {
      fetchPublication(id)
        .then(response => {
          if (!response?.data?.body) {
            return reject();
          }
          resolve(response.data.body);
        })
        .catch(error => reject(error));
    });
  },
  checkBannerPublication(context, data) {
    return checkBannerPublication(data);
  },
  getNewsPublicationState() {
    return getNewsPublicationState();
  }
};

export default { state, getters, mutations, actions, namespaced: true };
