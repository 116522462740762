import http from "@/services/http";

export const getPrescribersGroupsCount = params =>
  new Promise((resolve, reject) => {
    http
      .get("/prescribers-groups/count", {
        params
      })
      .then(res => {
        resolve(res.data.body.count);
      })
      .catch(err => {
        reject(err);
      });
  });
