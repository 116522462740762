import divisions from "../_constants/urlDivisionsParams";

const settings = require(`@/settings/${__PLATFORM__}`);

const getDefaultDivision = () => {
  const { defaultDivisionId } = settings.default;
  if (defaultDivisionId) {
    return divisions.find(division => division.code === defaultDivisionId).params;
  }
  /* Switch to respiratoire for default case */
  return divisions[0].params;
};

export default getDefaultDivision;
