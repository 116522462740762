import http from "@/services/http";

export const addAlertTemplate = alertTemplate => http.post("/alerts/templates", alertTemplate);

export const getCountPatients = typeId =>
  http.get("/alerts/templates/patients/count", {
    params: {
      typeId
    }
  });
