/* eslint-disable no-shadow */

import { searchProducts } from "@/modules/common/_api";

import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  addNotice(context, data) {
    const notice = data;
    return api.addNotice(notice);
  },
  searchMachines(context, input) {
    return searchProducts({ query: { typeId: "machine", input }, skip: 0, limit: 10, source: "local" });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
