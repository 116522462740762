module.exports = {
  planned: "planned",
  waiting: "waiting",
  confirmed: "confirmed",
  impossible: "impossible",
  achieved: "achieved",
  validated: "validated",
  cancelled: "cancelled",
  reported: "reported"
};
