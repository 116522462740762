import { searchUsers, fetchAlertTemplate } from "@/modules/common/_api";
import * as api from "../_api";

const state = {
  template: {}
};

const getters = {
  template: state => state.template
};

const mutations = {
  SET_TEMPLATE: (state, template) => {
    state.template = template;
  }
};

const actions = {
  editTemplate(context, { data, id }) {
    return new Promise((resolve, reject) => {
      api
        .editAlertTemplate(id, data)
        .then(resolve)
        .catch(err => reject(err));
    });
  },
  archiveTemplate(context, id) {
    return api.archiveAlertTemplate(id);
  },
  unarchiveTemplate(context, id) {
    return api.unarchiveAlertTemplate(id);
  },
  fetchAlertTemplate(context, templateId) {
    return new Promise((resolve, reject) => {
      fetchAlertTemplate(templateId)
        .then(res => {
          context.commit("SET_TEMPLATE", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchUsers(context, input) {
    return searchUsers({
      query: { input, active: true },
      skip: 0,
      limit: 10,
      source: "local"
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
