import { iconMedical } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.services.contracts";

const {
  contractsRights: { CREATE_CONTRACT }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconMedical,
    title: localize(localPath, "link--createService"),
    routerLink: {
      name: "contractsAddPatient"
    },
    rights: [CREATE_CONTRACT]
  }
];

const subMenuContracts = {
  title: localize(localPath, "title"),
  slug: "prestations",
  links
};

export default subMenuContracts;
