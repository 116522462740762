import * as icons from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.interventions-tours.communities-delivery";

const {
  entityInterventionsRights: { ACCESS_ENTITY_INTERVENTION },
  menuRights: { VIEW_MENU_ENTITY_INTERVENTIONS, VIEW_MENU_ANOMALY_ENTITY_INTERVENTIONS }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: icons.iconList,
    title: localize(localPath, "link--deliveryList"),
    routerLink: {
      name: "entityInterventions"
    },
    rights: [ACCESS_ENTITY_INTERVENTION]
  },
  {
    icon: icons.iconError,
    title: localize(localPath, "link--deliveryWithAnomaly"),
    routerLink: {
      name: "entityInterventionsWithAnomaly"
    },
    rights: [ACCESS_ENTITY_INTERVENTION, VIEW_MENU_ANOMALY_ENTITY_INTERVENTIONS]
  }
];

const subMenuEntityIntervention = {
  title: localize(localPath, "title"),
  slug: "livraisons-collectivite",
  rights: [VIEW_MENU_ENTITY_INTERVENTIONS],
  links
};

export default subMenuEntityIntervention;
