/* eslint-disable no-shadow */
import * as api from "@/modules/exports/mouvements-stock/_api";

const actions = {
  exportMouvementsStock(context, data) {
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    return api.exportMouvementsStock({
      startDate: data.exportDate.start,
      endDate: data.exportDate.end,
      user: {
        email,
        firstname,
        lastname,
        _id
      }
    });
  }
};

export default { state: {}, getters: {}, mutations: {}, actions, namespaced: true };
