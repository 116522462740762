import { iconAddUserClassicOutlined, iconUserClassicOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.settings.users";

const {
  usersRights: { CREATE_USER, GET_USER }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconAddUserClassicOutlined,
    title: localize(localPath, "link--usersSearch"),
    routerLink: {
      name: "usersSearch",
      alias: "usersSearch"
    },
    rights: [CREATE_USER]
  },
  {
    icon: iconUserClassicOutlined,
    title: localize(localPath, "link--usersList"),
    routerLink: {
      name: "users",
      alias: "usersList"
    },
    rights: [GET_USER]
  }
];

const subMenuUsers = {
  title: localize(localPath, "title"),
  slug: "utilisateurs",
  links
};

export default subMenuUsers;
