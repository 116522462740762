import http from "@/services/http";

/**
 * @param {String} stockId
 * @returns {Object} stocks
 */
export const exportStock = (stockId, params) =>
  http.get(`/stocks/export/${stockId}`, {
    params
  });
