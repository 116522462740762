import { iconClock } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuReminders from "./subMenuReminders";

const localPath = "menus.administration.reminders";

const {
  remindersRights: { ACCESS_REMINDERS }
} = require("@common/services/acm/rights");

const menuReminders = {
  title: localize(localPath, "title"),
  slug: "reminders",
  icon: iconClock,
  disabled: false,
  rights: [ACCESS_REMINDERS],
  items: [subMenuReminders]
};

export default menuReminders;
