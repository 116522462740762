import { searchPatients, searchInsureds } from "@/modules/common/_api";

const state = {
  patients: [],
  count: 0,
  lastRequestId: null,
  formattedQuery: {},
  insureds: [],
  insuredCount: 0
};

const getters = {
  patients: state => state.patients,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery,
  insureds: state => state.insureds,
  insuredCount: state => state.insuredCount
};

const mutations = {
  SET_PATIENTS: (state, patients) => {
    state.patients = patients;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  RESET_STATE: state => {
    state.patients = [];
    state.count = 0;
  },
  SET_INSUREDS: (state, insureds) => {
    state.insureds = insureds;
  },
  SET_INSURED_COUNT: (state, insuredCount) => {
    state.insuredCount = insuredCount;
  }
};

const actions = {
  fetchPatients(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      /* Increase limit by 1 to know if there are more results to fetch */
      const limit = params.limit + 1;
      searchPatients({ ...params, limit, query: { input: formattedQuery.searchString }, source: "remote" })
        .then(res => {
          if (lastRequestId !== requestId) {
            return resolve();
          }
          if (!res?.data?.body) {
            context.commit("SET_COUNT", 0);
            context.commit("SET_PATIENTS", []);
            return resolve();
          }
          /* As we fetch 1 more element, we need to remove it from results */
          const patients = limit < res.data.body.length ? res.data.body.slice(0, res.data.body.length - 1) : res.data.body;
          context.commit("SET_PATIENTS", patients);
          context.commit("SET_COUNT", res.data.body.length);
          return resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchInsureds(context, { query, ...params }) {
    context.dispatch("formatQuery", query);
    return new Promise((resolve, reject) => {
      const { formattedQuery } = context.state;
      const limit = params.limit + 1;
      searchInsureds({ ...params, limit, query: { input: formattedQuery.searchString }, source: "remote" })
        .then(res => {
          if (!res?.data?.body) {
            context.commit("SET_INSURED_COUNT", 0);
            context.commit("SET_INSUREDS", []);
            return resolve();
          }

          const insureds = limit < res.data.body.length ? res.data.body.slice(0, res.data.body.length - 1) : res.data.body;
          context.commit("SET_INSUREDS", insureds);
          context.commit("SET_INSURED_COUNT", res.data.body.length);
          return resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    context.commit("SET_FORMATTED_QUERY", query);
  },
  resetState(context) {
    context.commit("RESET_STATE");
  }
};

export default { state, getters, mutations, actions, namespaced: true };
