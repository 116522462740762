import http from "../../http";

const state = {
  message: "",
  active: false,
  maxTime: null,
  interval: null
};

const getters = {
  message: state => state.message,
  active: state => state.active,
  maxTime: state => state.maxTime
};

const mutations = {
  SET_TOAST: (state, datas) => {
    state.message = datas.message;
    state.maxTime = datas.maxTime;
    state.active = true;
  },
  RESET_TOAST: state => {
    state.message = "";
    state.maxTime = null;
    state.active = false;
  }
};

const actions = {
  getToastExample(context, maxTime = 3) {
    http
      .get("/fakeToaster")
      .then(response => {
        context.dispatch("setToast", {
          message: response.data,
          maxTime
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  setToast(context, datas) {
    context.commit("SET_TOAST", datas);
  },
  resetToast(context) {
    context.commit("RESET_TOAST");
  }
};

export default { state, getters, mutations, actions, namespaced: true };
