import * as api from "../_api";

const state = {
  renewals: []
};

const getters = {
  renewals: state => state.renewals
};

const mutations = {
  SET_RENEWALS: (state, renewals) => {
    state.renewals = renewals;
  }
};

const actions = {
  fetchUnlinkedRenewals(context) {
    return new Promise((resolve, reject) => {
      api
        .fetchUnlinkedRenewals()
        .then(res => {
          context.commit("SET_RENEWALS", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
