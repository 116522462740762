import { updateQuestionnaire, fetchQuestionnaire } from "../_api";

const state = {
  questionnaire: {}
};

const getters = {
  questionnaire: state => state.questionnaire
};

const mutations = {
  SET_QUESTIONNAIRE: (state, questionnaire) => {
    state.questionnaire = questionnaire;
  }
};

const actions = {
  updateQuestionnaire(context, dataToSave) {
    return updateQuestionnaire({ questionnaire: dataToSave });
  },
  fetchQuestionnaire(context, interventionId) {
    return new Promise((resolve, reject) => {
      fetchQuestionnaire(interventionId)
        .then(res => {
          context.commit("SET_QUESTIONNAIRE", res.data?.body || {});
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions };
