import { render, staticRenderFns } from "./AddCommunityOutlined.vue?vue&type=template&id=5f83bc14&functional=true"
import script from "./AddCommunityOutlined.vue?vue&type=script&lang=js"
export * from "./AddCommunityOutlined.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../client/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports