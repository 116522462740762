/* eslint-disable import/no-dynamic-require */
const flags = require(`@common/services/features/flags.${__PLATFORM__}`);
const featureTypes = require("@common/constants/featureTypes");

/*
This file exists both in client and server because we need to have acces to the platform constant (client : __PLATFORM__ | server : process.env.PLATFORM)

We can't mutualized this file because neither of those variable are accessible in the common folder
*/

module.exports = {
  isEnabled(featureName) {
    return flags[featureName];
  },
  isAuthBanEnabled() {
    return this.isEnabled(featureTypes.authBan);
  },
  isFastpayEnabled() {
    return this.isEnabled(featureTypes.fastpay);
  },
  isWoocommerceEnabled() {
    return this.isEnabled(featureTypes.woocommerce);
  },
  isSynchroStocksEnabled() {
    return this.isEnabled(featureTypes.synchroStocks);
  },
  isSynchroPatientEnabled() {
    return this.isEnabled(featureTypes.synchroPatient);
  },
  isOfflinePdfEnabled() {
    return this.isEnabled(featureTypes.offlinePdf);
  },
  isCommunityEntityInterventionsEnabled() {
    return this.isEnabled(featureTypes.communityEntityInterventions);
  },
  isAddProductByScanEnabled() {
    return this.isEnabled(featureTypes.addProductByScan);
  },
  isAddProductManuallyEnabled() {
    return this.isEnabled(featureTypes.addProductManually);
  },
  isStockHandlerEnabled() {
    return this.isEnabled(featureTypes.stockHandler);
  },
  isSupportEnabled() {
    return this.isEnabled(featureTypes.support);
  },
  isOrdoclicEnabled() {
    return this.isEnabled(featureTypes.ordoclic);
  },
  isInterventionsEnabled() {
    return this.isEnabled(featureTypes.interventions);
  },
  isPatientSignatureEnabled() {
    return this.isEnabled(featureTypes.patientSignature);
  },
  isDeliveryNoteEnabled() {
    return this.isEnabled(featureTypes.deliveryNote);
  },
  isPlanificationEnabled() {
    return this.isEnabled(featureTypes.planification);
  },
  isRemindersEnabled() {
    return this.isEnabled(featureTypes.reminders);
  },
  isPatientsOrientedConversationsEnabled() {
    return this.isEnabled(featureTypes.patientsOrientedMessages);
  },
  isRiskAnalysisEnabled() {
    return this.isEnabled(featureTypes.riskAnalysis);
  },
  isSelectMessageTypeOnExtranetEnabled() {
    return this.isEnabled(featureTypes.selectMessageTypeOnExtranet);
  },
  isMessagingEnabled() {
    return this.isEnabled(featureTypes.messaging);
  },
  isBaseDocumentaireEnabled() {
    return this.isEnabled(featureTypes.baseDocumentaire);
  },
  isCommunityTourEnabled() {
    return this.isEnabled(featureTypes.communityTour);
  }
};
