/* eslint-disable import/no-cycle */
import isObject from "lodash/isObject";
import divisions from "@ui/helpers/_constants/urlDivisionsParams";
import { fetchUsers, searchUsers } from "@/modules/common/_api";

import * as api from "../_api";

/* eslint-disable no-shadow */
const state = {
  users: [],
  currentDivision: null,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  currentCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  users: state => state.users,
  currentDivision: state => state.currentDivision,
  count: state => state.count,
  currentCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_USERS: (state, users) => {
    state.users = users;
  },
  SET_CURRENT_DIVISION: (state, division) => {
    const currentDivisionParams = divisions.find(item => item.code === division).params;
    state.currentDivision = {
      code: division,
      params: currentDivisionParams
    };
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  getUsersCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getUsersCount(params)
        .then(res => {
          const { query } = params;
          const { count } = res.data.body;
          context.commit("SET_COUNT", { division: query.division.toLowerCase(), count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchUsers(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivision } = context.state;
      fetchUsers({ ...params, query: { ...formattedQuery, division: currentDivision.code } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_USERS", results);
          context.commit("SET_CURRENT_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { users, ...formattedQuery } = query;
    if (users && users.length) {
      formattedQuery.userIds = users.map(item => item._id);
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  searchUsers(context, input) {
    return searchUsers({
      query: { input },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  setCurrentDivision(context, division) {
    context.commit("SET_CURRENT_DIVISION", division);
  },
  exportUsers(context, data) {
    const { formattedQuery, currentDivisions } = context.state;
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    const { sort, currentCount } = data;
    return api.exportUsers({
      sort,
      query: {
        ...formattedQuery,
        divisions: currentDivisions,
        user: {
          email,
          firstname,
          lastname,
          _id
        },
        currentCount
      }
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
