/* eslint-disable no-shadow */
import moment from "moment";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import { fetchInterventions, fetchInterventionsDesappareillages, fetchAlerts, fetchConversations, fetchPrescribers } from "@/modules/common/_api";
import { fetchEntityInterventions } from "@/modules/entity-interventions/common/_api";

const interventionStatuses = require("@common/constants/interventionStatuses");
const interventionTypes = require("@common/constants/interventionTypes");
const entityInterventionStatuses = require("@common/constants/entityInterventionStatuses");
const getMessageTypesForAttachment = require("@common/helpers/getMessageTypesForAttachment");
const riskAnalysisStatus = require("@common/constants/riskAnalysisStatus");
const { locationTypeIds } = require("@common/constants/locationTypes");
const alertGabaritIds = require("@common/constants/alertGabaritIds");

const state = {
  achievedInterventions: {
    count: 0,
    data: []
  },
  achievedEntityInterventions: {
    count: 0,
    data: []
  },
  achievedInterventionsForPatientsInCommunity: {
    count: 0,
    data: []
  },
  desappareillageInterventions: {
    count: 0,
    data: []
  },
  alertsTl3: {
    count: 0,
    data: []
  },
  alertsWithoutData: {
    count: 0,
    data: []
  },
  riskAnalysis: {
    count: 0,
    data: []
  },
  conversationsWithAttachment: {
    count: 0,
    data: []
  },
  prescribers: {
    count: 0,
    data: []
  },
  conversations: {
    count: 0,
    data: []
  },
  loading: {
    achievedInterventions: true,
    achievedEntityInterventions: true,
    achievedInterventionsForPatientsInCommunity: true,
    desappareillageInterventions: true,
    alertsTl3: true,
    alertsWithoutData: true,
    riskAnalysis: true,
    conversationsWithAttachment: true,
    prescribers: true,
    conversations: true
  },
  errorMessages: {
    achievedInterventions: null,
    achievedEntityInterventions: null,
    achievedInterventionsForPatientsInCommunity: null,
    desappareillageInterventions: null,
    alertsTl3: null,
    alertsWithoutData: null,
    riskAnalysis: null,
    conversationsWithAttachment: null,
    prescribers: null,
    conversations: null
  }
};

const getters = {
  achievedInterventions: state => state.achievedInterventions,
  achievedEntityInterventions: state => state.achievedEntityInterventions,
  achievedInterventionsForPatientsInCommunity: state => state.achievedInterventionsForPatientsInCommunity,
  desappareillageInterventions: state => state.desappareillageInterventions,
  alertsTl3: state => state.alertsTl3,
  alertsWithoutData: state => state.alertsWithoutData,
  riskAnalysis: state => state.riskAnalysis,
  conversationsWithAttachment: state => state.conversationsWithAttachment,
  prescribers: state => state.prescribers,
  conversations: state => state.conversations,
  loading: state => state.loading,
  errorMessages: state => state.errorMessages
};

const mutations = {
  SET_ACHIEVED_INTERVENTIONS: (state, achievedInterventions) => {
    state.achievedInterventions = achievedInterventions;
  },
  SET_ACHIEVED_ENTITY_INTERVENTIONS: (state, achievedEntityInterventions) => {
    state.achievedEntityInterventions = achievedEntityInterventions;
  },
  SET_ENTITY_INTERVENTIONS_INSTALLED_RECOVERED: (state, achievedInterventionsForPatientsInCommunity) => {
    state.achievedInterventionsForPatientsInCommunity = achievedInterventionsForPatientsInCommunity;
  },
  SET_DESAPPARAREILLAGE_INTERVENTIONS: (state, desappareillageInterventions) => {
    state.desappareillageInterventions = desappareillageInterventions;
  },
  SET_ALERTS_TL3: (state, alertsTl3) => {
    state.alertsTl3 = alertsTl3;
  },
  SET_WITHOUT_DATA_ALERTS: (state, alertsWithoutData) => {
    state.alertsWithoutData = alertsWithoutData;
  },
  SET_RISK_ANALYSIS: (state, riskAnalysis) => {
    state.riskAnalysis = riskAnalysis;
  },
  SET_CONVERSATIONS_WITH_ATTACHMENT: (state, conversationsWithAttachment) => {
    state.conversationsWithAttachment = conversationsWithAttachment;
  },
  SET_PRESCRIBERS: (state, prescribers) => {
    state.prescribers = prescribers;
  },
  SET_CONVERSATIONS: (state, conversations) => {
    state.conversations = conversations;
  },
  UPDATE_LOADING: (state, loadingItems) => {
    state.loading = { ...state.loading, ...loadingItems };
  },
  UPDATE_ERROR_MESSAGES: (state, errorMessages) => {
    state.errorMessages = { ...state.errorMessages, ...errorMessages };
  }
};

const actions = {
  fetchAchievedInterventions(context, sort) {
    context.commit("UPDATE_LOADING", { achievedInterventions: true });
    return new Promise((resolve, reject) => {
      fetchInterventions({
        limit: 10,
        query: {
          interventionStatusIds: [interventionStatuses.achieved],
          dateStart: moment().subtract(1, "month").startOf("day").toISOString(),
          dateEnd: moment().add(1, "month").endOf("day").toISOString(),
          poleIds: context.rootGetters["login/$user"].poleIds,
          divisions: context.rootGetters["login/$user"].divisionIds,
          interventionLocationTypes: [locationTypeIds.inPatient, locationTypeIds.poleVisit, locationTypeIds.prescriberVisit]
        },
        sort
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_ACHIEVED_INTERVENTIONS", { count, data });
          resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { achievedInterventions: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { achievedInterventions: false });
        });
    });
  },
  fetchAchievedEntityInterventions(context, sort) {
    context.commit("UPDATE_LOADING", { achievedEntityInterventions: true });
    return new Promise((resolve, reject) => {
      fetchEntityInterventions({
        limit: 10,
        query: {
          entityInterventionStatusIds: [entityInterventionStatuses.achieved],
          dateStart: moment().subtract(1, "month").startOf("day").toISOString(),
          dateEnd: moment().add(1, "month").endOf("day").toISOString(),
          poleIds: context.rootGetters["login/$user"].poleIds,
          divisions: context.rootGetters["login/$user"].divisionIds
        },
        sort
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_ACHIEVED_ENTITY_INTERVENTIONS", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { achievedEntityInterventions: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { achievedEntityInterventions: false });
        });
    });
  },
  fetchAchievedInterventionsForPatientsInCommunity(context, sort) {
    context.commit("UPDATE_LOADING", { achievedInterventionsForPatientsInCommunity: true });
    return new Promise((resolve, reject) => {
      fetchInterventions({
        limit: 10,
        query: {
          dateStart: moment().subtract(1, "month").startOf("day").toISOString(),
          dateEnd: moment().add(1, "month").endOf("day").toISOString(),
          interventionStatusIds: [interventionStatuses.achieved],
          poleIds: context.rootGetters["login/$user"].poleIds,
          divisions: context.rootGetters["login/$user"].divisionIds,
          interventionLocationTypes: [locationTypeIds.inCommunity]
        },
        sort
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];

          context.commit("SET_ENTITY_INTERVENTIONS_INSTALLED_RECOVERED", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", {
            achievedInterventionsForPatientsInCommunity: error?.data?.errorMessage || defaultErrorMessages.fetch
          });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { achievedInterventionsForPatientsInCommunity: false });
        });
    });
  },
  fetchDesappareillageInterventions(context) {
    context.commit("UPDATE_LOADING", { desappareillageInterventions: true });
    return new Promise((resolve, reject) => {
      fetchInterventionsDesappareillages({
        limit: 3,
        query: {
          interventionTypeIds: [interventionTypes.recovery],
          interventionStatusIds: [interventionStatuses.achieved, interventionStatuses.validated],
          poleIds: context.rootGetters["login/$user"].poleIds,
          divisions: context.rootGetters["login/$user"].divisionIds,
          withDeliveries: true
        },
        sort: {
          dateTimeStart: -1,
          _id: -1
        }
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_DESAPPARAREILLAGE_INTERVENTIONS", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { desappareillageInterventions: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { desappareillageInterventions: false });
        });
    });
  },
  fetchAlertsTl3(context, { division, poleIds, intervenantIds, alertStatusIds }) {
    context.commit("UPDATE_LOADING", { alerts: true });
    return new Promise((resolve, reject) => {
      fetchAlerts({
        limit: 5,
        query: {
          division,
          alertTemplateIds: [alertGabaritIds.alertTl3Id],
          poleIds,
          intervenantIds,
          alertStatusIds
        }
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_ALERTS_TL3", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { alertsTl3: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { alertsTl3: false });
        });
    });
  },
  fetchAlertsWithoutData(context, { division, poleIds, intervenantIds, alertStatusIds }) {
    context.commit("UPDATE_LOADING", { alertsWithoutData: true });
    return new Promise((resolve, reject) => {
      fetchAlerts({
        limit: 5,
        query: {
          division,
          alertTemplateIds: [alertGabaritIds.alertWithoutDataId],
          poleIds,
          intervenantIds,
          alertStatusIds
        }
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_WITHOUT_DATA_ALERTS", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { alertsWithoutData: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { alertsWithoutData: false });
        });
    });
  },
  fetchRiskAnalysis(context) {
    context.commit("UPDATE_LOADING", { riskAnalysis: true });
    return new Promise((resolve, reject) => {
      fetchInterventions({
        limit: 10,
        query: {
          poleIds: context.rootGetters["login/$user"].poleIds,
          divisions: context.rootGetters["login/$user"].divisionIds,
          riskAnalysisStatus: [riskAnalysisStatus.toCheck]
        },
        sort: { plannedDateTimeStart: -1 }
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_RISK_ANALYSIS", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { riskAnalysis: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { riskAnalysis: false });
        });
    });
  },
  fetchConversationsWithAttachment(context, conversationTypes) {
    context.commit("UPDATE_LOADING", { conversationsWithAttachment: true });
    return new Promise((resolve, reject) => {
      fetchConversations({
        limit: 3,
        query: {
          attachment: true,
          conversationTypeIds: getMessageTypesForAttachment(conversationTypes)
        },
        sort: {
          updateDate: -1,
          _id: -1
        }
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_CONVERSATIONS_WITH_ATTACHMENT", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { conversationsWithAttachment: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { conversationsWithAttachment: false });
        });
    });
  },
  fetchConversationsByType(context, typeId) {
    return fetchConversations({
      limit: 3,
      query: {
        typeIds: [typeId]
      },
      sort: {
        updateDate: -1,
        _id: -1
      }
    });
  },
  fetchConversations(context) {
    return new Promise((resolve, reject) => {
      fetchConversations({
        limit: 4,
        sort: {
          updateDate: -1,
          _id: -1
        }
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_CONVERSATIONS", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { conversations: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { conversations: false });
        });
    });
  },
  fetchPrescribers(context, poleIds) {
    context.commit("UPDATE_LOADING", { prescribers: true });
    return new Promise((resolve, reject) => {
      fetchPrescribers({
        limit: 10,
        query: {
          division: "R",
          poleIds,
          activeStatus: ["active"]
        },
        sort: {
          updateDate: -1,
          _id: -1
        }
      })
        .then(res => {
          const count = res?.data?.body?.count || 0;
          const data = res?.data?.body?.results || [];
          context.commit("SET_PRESCRIBERS", { count, data });
          return resolve();
        })
        .catch(error => {
          context.commit("UPDATE_ERROR_MESSAGES", { prescribers: error?.data?.errorMessage || defaultErrorMessages.fetch });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { prescribers: false });
        });
    });
  },
  resetErrorMessages(context) {
    context.commit("UPDATE_ERROR_MESSAGES", {
      achievedInterventions: null,
      achievedEntityInterventions: null,
      desappareillageInterventions: null,
      alertsTl3: null,
      alertsWithoutDatas: null
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
