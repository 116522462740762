module.exports = {
  waiting: "waiting",
  delivered: "delivered",
  toBeRemoved: "toBeRemoved",
  removed: "removed",
  archived: "archived",
  achieved: "achieved",
  validated: "validated",
  impossible: "impossible"
};
