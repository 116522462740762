import Vue from "vue";
import VueRouter from "vue-router";

import alerts from "./routes/admin/alerts";
import app from "./routes/app";
import communities from "./routes/admin/communities";
import contracts from "./routes/admin/contracts";
import conversations from "./routes/admin/conversations";
import dashboard from "./routes/admin/dashboard";
import documents from "./routes/admin/documents";
import contacts from "./routes/admin/contacts";
import entityInterventions from "./routes/admin/entity-interventions";
import exports from "./routes/admin/exports";
import footer from "./routes/footer";
import interventions from "./routes/admin/interventions";
import login from "./routes/admin/login";
import noticesManager from "./routes/admin/notices-manager";
import patients from "./routes/admin/patients";
import prescribers from "./routes/admin/prescribers";
import products from "./routes/admin/products";
import publications from "./routes/admin/publications";
import reminders from "./routes/admin/reminders";
import renewals from "./routes/admin/renewals";
import reports from "./routes/admin/reports";
import supportLogs from "./routes/admin/support-logs";
import supportTickets from "./routes/admin/support-tickets";
import users from "./routes/admin/users";

import i18n from "./middleware/i18n";
import auth from "./middleware/auth";
import authorization from "./middleware/authorization";
import middlewarePipeline from "./middleware/middleware-pipeline";

Vue.use(VueRouter);

const routes = [
  ...alerts,
  ...communities,
  ...contracts,
  ...conversations,
  ...documents,
  ...contacts,
  ...entityInterventions,
  ...exports,
  ...interventions,
  ...login,
  ...noticesManager,
  ...patients,
  ...prescribers,
  ...products,
  ...publications,
  ...reminders,
  ...renewals,
  ...reports,
  ...supportLogs,
  ...supportTickets,
  ...users,
  ...dashboard,
  ...footer,
  ...app
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:locale([a-zA-Z]{2})?",
      component: {
        render: c => c("router-view")
      },
      children: routes
    }
  ]
});

router.beforeEach((to, from, next) => {
  const middleware = [i18n, auth, authorization];
  const context = {
    to,
    from,
    next
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
