/* eslint-disable no-shadow */
import { settings } from "@/bootstrap";

import * as api from "../_api";

const state = {
  communities: []
};

const getters = {
  communities: state => state.communities
};

const mutations = {
  SET_COMMUNITIES: (state, communities) => {
    state.communities = communities;
  },
  SET_TAXONOMIES: (state, taxonomies) => {
    state.taxonomies = taxonomies;
  }
};

const actions = {
  checkCommunityEmailAvailability(context, params) {
    return api.checkCommunityEmailAvailability(params);
  },
  addCommunity(context, data) {
    const { delegates, technicians, ...community } = data;

    /*
     * Need to map delegates and technicians because they are composed
     * of array of objects (this format is returned by input-search) but we need a different format (cf. json schema)
     */

    if (delegates) {
      community.delegates = delegates.map(delegate => {
        let id = delegate._id;
        if (delegate.delegate) {
          id = delegate.delegate._id;
        }
        return { id, divisionId: delegate.divisionId, date: delegate.date };
      });
    }

    community.siren = community.siret && community.siret.length > 9 ? community.siret.substring(0, 9) : "";

    community.divisionIds = [settings.default.defaultDivisionId];

    return api.addCommunity(community);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
