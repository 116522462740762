import i18n from "@/i18n";

const i18nKey = "router.admin.reports";

const AddReport = () => import(/* webpackChunkName: "patient-add" */ "@/modules/reports/add");
const ViewReports = () => import(/* webpackChunkName: "patient-add" */ "@/modules/reports/view");

const {
  reportsRights: { ACCESS_VIEW_REPORT, CREATE_REPORT }
} = require("@common/services/acm/rights");

export default [
  {
    path: "create-tech-report",
    name: "addReport",
    component: AddReport,
    meta: {
      title: i18n.t(`${i18nKey}.title--addReport`),
      rights: [CREATE_REPORT]
    }
  },
  {
    path: "tech-reports",
    name: "viewReports",
    component: ViewReports,
    meta: {
      title: i18n.t(`${i18nKey}.title--viewReports`),
      rights: [ACCESS_VIEW_REPORT]
    }
  }
];
