import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import defaultSuccessMessages from "@/helpers/_constants/defaultSuccessMessages";

const state = {
  feedbackMessages: {
    signatureError: null,
    signatureSuccess: null
  }
};

const actions = {
  uploadSignatureAndSignBL(context, { signatureFile, signatureDataURL }) {
    return new Promise((resolve, reject) => {
      const { intervention } = context.state;
      context.commit("UPDATE_LOADING", { submit: true });
      context.dispatch("updateFeedbackMessages", { success: null, error: null });
      context
        .dispatch("interventionsCommon/uploadSignaturePatientAndSignBL", { intervention, signatureFile, signatureDataURL }, { root: true })
        .then(() => {
          context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.uploadSignatureAndSignBL });
          context.dispatch("fetchInterventionView", intervention._id);
          resolve();
        })
        .catch(error => {
          context.dispatch("updateFeedbackMessages", {
            signatureError: defaultErrorMessages.signatureError
          });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });
    });
  },
  uploadSignature(context, { signatureFile, signatureDataURL }) {
    return new Promise((resolve, reject) => {
      const { intervention } = context.state;
      context.commit("UPDATE_LOADING", { submit: true });
      context.dispatch("updateFeedbackMessages", { success: null, error: null });
      context
        .dispatch("interventionsCommon/uploadSignaturePatient", { intervention, signatureFile, signatureDataURL }, { root: true })
        .then(() => {
          context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.uploadSignature });
          context.dispatch("fetchInterventionView", intervention._id);
          resolve();
        })
        .catch(error => {
          context.dispatch("updateFeedbackMessages", {
            signatureError: defaultErrorMessages.signatureError
          });
          reject(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });
    });
  }
};

export default { state, actions };
