/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import divisions from "@ui/helpers/_constants/urlDivisionsParams";
import uniq from "lodash/uniq";
import { fetchPatients, searchPrescribers, searchDelegates, fetchRenewalFile, getPatientsCount } from "@/modules/common/_api";

import * as api from "../_api";

const state = {
  patients: [],
  currentDivision: null,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  currentCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  patients: state => state.patients,
  currentDivision: state => state.currentDivision,
  count: state => state.count,
  currentCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_PATIENTS: (state, patients) => {
    state.patients = patients;
  },
  SET_CURRENT_DIVISION: (state, division) => {
    const currentDivisionParams = divisions.find(item => item.code === division).params;
    state.currentDivision = {
      code: division,
      params: currentDivisionParams
    };
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  exportPatients(context, data) {
    const { formattedQuery, currentDivision } = context.state;
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    const { sort, currentCount } = data;
    return api.exportPatients({
      sort,
      query: {
        ...formattedQuery,
        division: currentDivision.code,
        user: {
          email,
          firstname,
          lastname,
          _id
        },
        currentCount
      }
    });
  },
  exportHsatPatients(context, data) {
    const { formattedQuery, currentDivision } = context.state;
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    const { sort, currentCount } = data;
    return api.exportHsatPatients({
      sort,
      query: {
        ...formattedQuery,
        division: currentDivision.code,
        user: {
          email,
          firstname,
          lastname,
          _id
        },
        currentCount
      }
    });
  },
  exportPatientsPrescribers(context, { sort, canSign }) {
    const { formattedQuery, currentDivision } = context.state;
    return api.exportPatientsPrescribers({ sort, query: { ...formattedQuery, division: currentDivision.code }, canSign });
  },

  getPatientsCount(context, { query }) {
    return new Promise((resolve, reject) => {
      getPatientsCount(query)
        .then(res => {
          context.commit("SET_COUNT", { division: query.division.toLowerCase(), count: res.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPatients(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivision } = context.state;
      fetchPatients({ ...params, query: { ...formattedQuery, division: currentDivision.code } })
        .then(res => {
          if (!res || !res.data || !res.data.body || lastRequestId !== requestId) {
            return resolve();
          }
          const { results, count } = res.data.body;
          context.commit("SET_PATIENTS", results);
          context.commit("SET_CURRENT_COUNT", count);
          return resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({
      query: { division: context.state.currentDivision.code, input, activeStatus: ["active"] },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  searchDelegates(context, input) {
    return searchDelegates({ query: { input }, skip: 0, limit: 10 });
  },
  setCurrentDivision(context, division) {
    context.commit("SET_CURRENT_DIVISION", division);
  },
  formatQuery(context, query) {
    const { prescribers, delegates, locationCodes, installationDate, interventionDate, depDate, prescrEndDate, ...formattedQuery } = query;
    if (prescribers && prescribers.length) {
      formattedQuery.prescriptionAddressIds = prescribers.flatMap(item => item.prescriptionAddressIds);
    }
    if (delegates && delegates.length) {
      formattedQuery.delegateIdsForPrescriptionAddresses = delegates.map(delegate => delegate._id);
    }
    if (locationCodes && locationCodes.length) {
      formattedQuery.locationCodes = uniq(locationCodes.map(item => item.zipCode || item.departmentCode));
    }
    if (installationDate) {
      formattedQuery.installationDateStart = installationDate.start || undefined;
      formattedQuery.installationDateEnd = installationDate.end || undefined;
    }
    if (interventionDate) {
      formattedQuery.interventionDateStart = interventionDate.start || undefined;
      formattedQuery.interventionDateEnd = interventionDate.end || undefined;
    }
    if (depDate) {
      formattedQuery.depDateStart = depDate.start || undefined;
      formattedQuery.depDateEnd = depDate.end || undefined;
    }
    if (prescrEndDate) {
      formattedQuery.prescrEndDateStart = prescrEndDate.start || undefined;
      formattedQuery.prescrEndDateEnd = prescrEndDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  fetchRenewalFile(context, documentId) {
    return new Promise((resolve, reject) => {
      fetchRenewalFile(documentId)
        .then(res => {
          if (!res || !res.data) {
            return reject();
          }
          return resolve({ encodedFile: res.data.file, originalname: res.data.fileName, filename: res.data.fileName, mimetype: "application/pdf" });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkInitialRenewal(context, contractId) {
    return api.checkInitialRenewal(contractId);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
