<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M19.6446785,9.87452674 C19.8457901,10.36562 19.9631915,10.8862805 19.9926304,11.4149038 L20,11.6797789 L20,11.6797789 C20,15.1422324 18.6322807,18.0819205 15.8968422,20.4988432 C15.3695019,20.9647745 14.6361014,21.1192935 13.9656014,20.9057298 L12.6069822,20.4729905 C12.2121008,20.3472152 11.7878992,20.3472152 11.3930178,20.4729905 L10.0344238,20.9057218 C9.36392223,21.1193119 8.63051184,20.9647911 8.10316666,20.498851 C5.46204789,18.165269 4.09595197,15.3443518 4.00487891,12.0360994 L4,11.6797789 L4,11.6797789 C4,9.04893883 6.1327173,6.91622153 8.76355742,6.91622153 C9.38268104,6.91622153 9.99586743,7.03691287 10.5688205,7.27151655 L12,7.85761041 L13.4311795,7.27151655 C15.8657918,6.27452978 18.6476652,7.43992528 19.6446785,9.87452674 Z M14.5,3 L14.0727953,4.03083671 C13.9712571,4.27584644 13.7765479,4.47048532 13.5315016,4.57193503 L12.5,4.99897881 L12.9272047,3.9681421 C13.0287429,3.72313237 13.2234521,3.52849348 13.4684984,3.42704377 L14.5,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
