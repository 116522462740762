import i18n from "@/i18n";

const i18nKey = "router.admin.exports";

const ExportMouvementsStock = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/mouvements-stock");
const ExportDeliveredPairableDevices = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/delivered-pairable-devices");
const ExportConsommations = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/consommations");
const GetExport = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/get-export");

const {
  menuRights: { VIEW_MENU_EXPORT_MOUVEMENTS_STOCK, VIEW_MENU_EXPORT_DELIVERED_PAIRABLE_DEVICES, VIEW_MENU_EXPORT_CONSOMMATIONS }
} = require("@common/services/acm/rights");

export default [
  {
    path: "export-stock-movements",
    alias: "export-mouvements-stock",
    name: "exportMouvementsStock",
    component: ExportMouvementsStock,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--exportMouvementsStock`),
      rights: [VIEW_MENU_EXPORT_MOUVEMENTS_STOCK]
    }
  },
  {
    path: "export-pairings",
    alias: "export-appairages",
    name: "exportDeliveredPairableDevices",
    component: ExportDeliveredPairableDevices,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--exportDeliveredPairableDevices`),
      rights: [VIEW_MENU_EXPORT_DELIVERED_PAIRABLE_DEVICES]
    }
  },
  {
    path: "export-consumptions",
    alias: "export-consommations",
    name: "exportConsommations",
    component: ExportConsommations,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--exportConsommations`),
      rights: [VIEW_MENU_EXPORT_CONSOMMATIONS]
    }
  },
  {
    path: "exports/:id",
    name: "getExport",
    component: GetExport,
    meta: {
      title: i18n.t(`${i18nKey}.title--getExport`),
      private: true
    }
  }
];
