import i18n from "@/i18n";

const i18nKey = "router.admin.contacts";

const ContactsAddForm = () => import(/* webpackChunkName: "contacts" */ "@/modules/contacts/add/_components/ContactsAddForm");
const ContactsSearch = () => import(/* webpackChunkName: "contacts" */ "@/modules/contacts/add/_components/ContactsSearch");
const ContactsGlobalInformations = () => import(/* webpackChunkName: "contacts" */ "@ui/pages/contacts/view/_components/ContactsGlobalInformations");

const ContactsList = () => import(/* webpackChunkName: "contacts" */ "@/modules/contacts/list");
const ContactsAdd = () => import(/* webpackChunkName: "contacts" */ "@/modules/contacts/add");
const ContactsEdit = () => import(/* webpackChunkName: "contacts" */ "@/modules/contacts/edit");
const ContactsView = () => import(/* webpackChunkName: "contacts" */ "@/modules/contacts/view");

const {
  contactsRights: { CREATE_CONTACT, ACCESS_CONTACTS, EDIT_CONTACT }
} = require("@common/services/acm/rights");

export default [
  {
    path: "contact/add",
    component: ContactsAdd,
    children: [
      {
        path: "",
        name: "contactsAdd",
        component: ContactsAddForm,
        meta: {
          title: i18n.t(`${i18nKey}.title--contactAdd`),
          rights: [CREATE_CONTACT]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "contactsSearch") {
            next({ name: "contactsSearch" });
          } else {
            next();
          }
        }
      },
      {
        path: "search",
        name: "contactsSearch",
        component: ContactsSearch,
        meta: {
          title: i18n.t(`${i18nKey}.title--contactAddSearch`),
          rights: [CREATE_CONTACT]
        }
      }
    ]
  },
  {
    path: "contact/:id",
    component: ContactsView,
    children: [
      {
        path: "",
        name: "contactsView",
        meta: {
          title: i18n.t(`${i18nKey}.title--contactsView`),
          rights: [ACCESS_CONTACTS]
        },
        component: ContactsGlobalInformations
      },
      {
        path: "edit",
        name: "contactsEdit",
        meta: {
          title: i18n.t(`${i18nKey}.title--contactsEdit`),
          rights: [EDIT_CONTACT]
        },
        component: ContactsEdit
      }
    ]
  },
  {
    path: "contacts",
    name: "contactsList",
    component: ContactsList,
    meta: {
      title: i18n.t(`${i18nKey}.title--contacts`),
      rights: [ACCESS_CONTACTS]
    }
  }
];
