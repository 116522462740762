import * as icons from "@ui/components/icons";
import i18n from "@/i18n";

export default [
  {
    title: i18n.t("menus.prescriber.link--dashboard"),
    slug: "dashboard",
    icon: icons.iconHome,
    routerLink: { name: "dashboard" }
  },
  {
    title: i18n.t("menus.prescriber.link--oald"),
    slug: "oald",
    icon: icons.iconAddPatientOutlined,
    routerLink: { name: "oaldNewPatientContract" }
  },
  {
    title: i18n.t("menus.prescriber.link--patientsList"),
    slug: "patients",
    icon: icons.iconPatientOutlined,
    routerLink: { name: "patientsList" },
    children: ["patientView", "patientViewInformations", "patientViewRespiratoire"]
  },
  {
    title: i18n.t("menus.prescriber.link--conversationsList"),
    slug: "conversations",
    icon: icons.iconDiscussion,
    routerLink: { name: "conversationsList" },
    notificationName: "countUnreadConversation"
  }
];
