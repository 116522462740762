import formatString from "./formatString";

/**
 * Return a HTTP-like response with the taxonomy's terms
 * that matches a given query
 *
 * @param {Array} taxonomy Formatted following the label/value format
 * @param {String} query
 * @returns {Object} A HTTP-like response
 */
const searchThroughTaxonomy = (taxonomy, query) => {
  const formattedQuery = formatString(query);
  const results = taxonomy.filter(
    term => (term.searchString && term.searchString.includes(formattedQuery)) || formatString(term.label).includes(formattedQuery)
  );
  return { data: { body: results } };
};

export default searchThroughTaxonomy;
