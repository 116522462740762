import { fetchCommunity } from "@/modules/common/_api";

import * as api from "../_api";

/* eslint-disable no-shadow */

const state = {};

const getters = {};

const mutations = {};

const actions = {
  fetchCommunity(context, communityId) {
    return new Promise((resolve, reject) => {
      fetchCommunity(communityId)
        .then(res => {
          resolve(res.data.body);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkCommunitiesGroupEmailAvailability(context, params) {
    return api.checkCommunitiesGroupEmailAvailability(params);
  },
  editCommunitiesGroup(context, data) {
    const { delegates, technicians, communities, ownerFirstname, ownerLastname, ownerEmail, owner, ...communitiesGroup } = data;

    if (delegates) {
      communitiesGroup.delegates = delegates.map(delegate => {
        let id = delegate._id;
        if (delegate.delegate) {
          id = delegate.delegate._id;
        }

        return { id, divisionId: delegate.divisionId, date: delegate.date };
      });
    }

    if (technicians) {
      communitiesGroup.technicians = technicians.map(technician => {
        let id = technician._id;
        if (technician.technician) {
          id = technician.technician._id;
        }

        return { id, divisionId: technician.divisionId, date: technician.date };
      });
    }

    communitiesGroup.communityIds = communities.map(item => item._id);

    communitiesGroup.owner = {
      firstname: ownerFirstname || owner.firstname || undefined,
      lastname: ownerLastname || owner.lastname || undefined,
      email: ownerEmail || owner.email || undefined
    };
    communitiesGroup.active = true;

    return new Promise((resolve, reject) => {
      api
        .editCommunitiesGroup(communitiesGroup._id, communitiesGroup)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
