/* eslint-disable import/no-cycle */
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import defaultSuccessMessages from "@/helpers/_constants/defaultSuccessMessages";
import { fetchUsers, fetchStockContents } from "@/modules/common/_api";
import * as api from "../_api";

const state = {
  anomalyUsers: [],
  stockContent: null
};

const getters = {
  anomalyUsers: state => state.anomalyUsers,
  stockContent: state => state.stockContent
};

const mutations = {
  UPDATE_ANOMALY_USERS: (state, anomalyUsers) => {
    state.anomalyUsers = anomalyUsers;
  },
  SET_STOCK_CONTENT: (state, stockContent) => {
    state.stockContent = stockContent;
  }
};

const actions = {
  fetchAnomalyUsers(context) {
    return new Promise((resolve, reject) => {
      const userIds = context.state.intervention.anomaly.history.map(item => item.resolvedBy).filter(userId => userId);
      if (!userIds.length) {
        return resolve();
      }
      fetchUsers({ query: { ids: userIds } })
        .then(res => {
          const results = res.data && res.data.body ? res.data.body.results : [];
          context.commit("UPDATE_ANOMALY_USERS", results);
          resolve();
        })
        .catch(reject);
    });
  },
  updateAnomalyStatus(context) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { intervention: true });
      const userId = context.rootState.login.user._id;
      api
        .updateAnomalyStatus(context.state.intervention._id, { userId })
        .then(res => resolve(res))
        .catch(err => reject(err))
        .finally(() => {
          context.commit("UPDATE_LOADING", { intervention: false });
        });
    });
  },
  triggerStockMovement(context) {
    return new Promise(resolve => {
      context.commit("UPDATE_LOADING", { intervention: true });
      context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null });
      const { _id: interventionId } = context.state.intervention;
      Promise.allSettled([
        api.createLocationsOrder({ interventionId }),
        api.createSalesOrder({ interventionId }),
        api.createRecup({ interventionId })
      ]).then(([locationsOrderResult, salesOrderResult, recupResult]) => {
        context.commit("UPDATE_LOADING", { intervention: false });
        if (locationsOrderResult.status === "rejected" || salesOrderResult.status === "rejected" || recupResult.status === "rejected") {
          context.commit("SET_FEEDBACK_MESSAGES", {
            error: defaultErrorMessages.stockMovment
          });
          return resolve();
        }
        context.dispatch("updateAnomalyStatus").finally(() => {
          context.commit("SET_FEEDBACK_MESSAGES", { success: defaultSuccessMessages.stockMovment });
          resolve();
        });
      });
    });
  },
  syncAnomalyDeliveriesStock(context) {
    context.commit("UPDATE_LOADING", { deliveries: true });
    context.commit("SET_FEEDBACK_MESSAGES", { success: null, error: null });
    return new Promise((resolve, reject) => {
      Promise.all([
        api.syncAnomalyMachinesStock(context.state.intervention._id),
        api.syncAnomalyAgaProductsStock(context.state.intervention._id),
        context.dispatch("fetchStockContent")
      ])
        .then(([res]) => {
          context.dispatch("updateFeedbackMessages", { success: res.data.successMessage });
          resolve();
        })
        .catch(([err]) => {
          context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { deliveries: false });
        });
    });
  },
  fetchStockContent(context) {
    return new Promise((resolve, reject) => {
      const { intervention } = context.state;

      if (!intervention.stockId) {
        return resolve(null);
      }

      fetchStockContents(intervention.stockId, {})
        .then(res => {
          context.commit("SET_STOCK_CONTENT", res.data.body || []);
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions };
