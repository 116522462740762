import { iconPatientOutlined } from "@ui/components/icons";
import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import menuDashboard from "./dashboard/menuDashboard";
import menuCustomersMedpro from "./customers/menuCustomersMedpro";
import menuAppointments from "./appointments/menuAppointments";
import menuPrestations from "./prestations/menuPrestations";
import menuProducts from "./products/menuProducts";
import menuAlerts from "./alerts/menuAlerts";
import menuReminders from "./reminders/menuReminders";
import menuSettings from "./settings/menuSettings";

const localPath = "menus.administration";

const {
  patientsRights: { ACCESS_PATIENT }
} = require("@common/services/acm/rights");

const menu = [menuDashboard, menuCustomersMedpro, menuAppointments, menuReminders, menuPrestations, menuProducts, menuAlerts, menuSettings];

// Cas particulier medpro pour la liste patients hsat qui est un sous menu de patients-medecins/patients
// Utilisable uniquement dans ce cas précis. Romain GADRAT

const entry = menu.find(item => item.slug === "patients-medecins");
const patientsEntry = entry.items.find(item => item.slug === "patients");

patientsEntry.links.push({
  icon: iconPatientOutlined,
  title: localize(localPath, "patients-medecins.patients.link--patientsListHsat"),
  routerLink: {
    name: "patientsListHsat"
  },
  rights: [ACCESS_PATIENT]
});

export default menu;
