export default [
  {
    id: "prescribers",
    description: "Prescribers updating for demo",
    active: true
  },
  {
    id: "conversations",
    description: "Simple conversations for demo",
    active: true
  }
];
