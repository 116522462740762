import eachSeries from "async/eachSeries";
import defaultSuccessMessages from "@/helpers/_constants/defaultSuccessMessages";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import { fetchFile } from "@/modules/common/_api";

const fileTypes = require("@common/constants/fileTypes");

const actions = {
  getSignatureFiles(context, interventions) {
    return new Promise(resolve => {
      eachSeries(
        interventions,
        (intervention, nextIntervention) => {
          const signature =
            intervention.files && intervention.files.length
              ? intervention.files.find(file => file.contentTypeId === fileTypes.patientSignature)
              : null;
          if (!signature) {
            return nextIntervention();
          }
          const signatureIndex = intervention.files.indexOf(signature);
          return fetchFile(signature)
            .then(({ data }) => {
              Object.assign(intervention.files[signatureIndex], {
                preview: `data:image/jpeg;base64,${data.body}`
              });
            })
            .finally(nextIntervention);
        },
        () => {
          return resolve(interventions);
        }
      );
    });
  },
  uploadSignatureAndSignBL(context, { intervention, signatureFile, signatureDataURL }) {
    return new Promise((resolve, reject) => {
      context.dispatch("updateFeedbackMessages", { signatureSuccess: null, signatureError: null });
      context
        .dispatch("interventionsCommon/uploadSignaturePatientAndSignBL", { intervention, signatureFile, signatureDataURL }, { root: true })
        .then(() => {
          context.dispatch("updateFeedbackMessages", { signatureSuccess: defaultSuccessMessages.uploadSignatureAndSignBL });
          resolve();
        })
        .catch(error => {
          context.dispatch("updateFeedbackMessages", {
            signatureError: defaultErrorMessages.uploadSignatureAndSignBL
          });
          reject(error);
        });
    });
  },
  uploadSignature(context, { intervention, signatureFile, signatureDataURL }) {
    return new Promise((resolve, reject) => {
      context.dispatch("updateFeedbackMessages", { signatureSuccess: null, signatureError: null });
      context
        .dispatch("interventionsCommon/uploadSignaturePatient", { intervention, signatureFile, signatureDataURL }, { root: true })
        .then(() => {
          context.dispatch("updateFeedbackMessages", { signatureSuccess: defaultSuccessMessages.uploadSignature });
          resolve();
        })
        .catch(error => {
          context.dispatch("updateFeedbackMessages", {
            signatureError: defaultErrorMessages.uploadSignature
          });
          reject(error);
        });
    });
  }
};

export default { actions };
