import http from "../../../services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>}
 */
export default (stockId, params) =>
  http.get(`/stocks/${stockId}`, {
    params,
    timeout: 300000
  });
