import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const {
  messagesRights: { CREATE_MESSAGE, ACCESS_MESSAGE }
} = require("@common/services/acm/rights");

const features = require("../../../services/features");

const localPath = "router.admin.conversations";

const ConversationsList = () =>
  features.isPatientsOrientedConversationsEnabled()
    ? import(/* webpackChunkName: "conversations" */ "@/modules/conversations/list/AdminPatientsOrientedConversationsList")
    : import(/* webpackChunkName: "conversations" */ "@/modules/conversations/list/AdminPrescribersOrientedConversationList");

const ConversationsAdd = () =>
  features.isPatientsOrientedConversationsEnabled()
    ? import(/* webpackChunkName: "conversations" */ "@/modules/conversations/add/AdminPatientsOrientedConversationsAdd")
    : import(/* webpackChunkName: "conversations" */ "@/modules/conversations/add/AdminPrescribersOrientedConversationsAdd");

const ConversationsView = () =>
  features.isPatientsOrientedConversationsEnabled()
    ? import(/* webpackChunkName: "conversations" */ "@/modules/conversations/view/AdminPatientsOrientedConversationsView")
    : import(/* webpackChunkName: "conversations" */ "@/modules/conversations/view/AdminPrescribersOrientedConversationsView");

export default [
  {
    path: "messages",
    name: "conversationsList",
    component: ConversationsList,
    meta: {
      title: localize(localPath, `title--conversations`),
      rights: [ACCESS_MESSAGE]
    }
  },
  {
    path: "messages/add",
    name: "conversationsAdd",
    component: ConversationsAdd,
    meta: {
      title: localize(localPath, `title--conversations`),
      rights: [CREATE_MESSAGE]
    }
  },
  {
    path: "messages/:id",
    name: "conversationsView",
    component: ConversationsView,
    meta: {
      title: localize(localPath, `title--conversations`),
      rights: [ACCESS_MESSAGE]
    }
  }
];
