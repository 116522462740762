import http from "@/services/http";

/**
 * @returns {Promise<Array>} Promise containing an array of alerts reminders filtered by current user's ID
 */
export const fetchAlertsReminders = params => http.get("/alerts/reminders", { params });

/**
 * @returns {Promise<Array>} Promise containing an array of alerts reminders filtered by current user's ID
 */
export const exportReminders = params => http.get("/alerts/reminders/export", { params });
