import http from "@/services/http";

export const exportInterventions = params =>
  http.get("/interventions/export", {
    params,
    timeout: 40000
  });

// Use POST method and send params into body to avoid error 414
export const getInterventionsCount = params => http.post("/interventions/count", params);
