/**
 * Format the stock to recover the deliveries
 * @param {Array} stock
 * @returns {Array}
 */
const formatCompleteStock = (stock = []) => {
  return stock.reduce((acc, item) => {
    if (!item.details?.length) {
      acc.push({
        product: item.product,
        productId: item.productId,
        quantity: item.available
      });
      return acc;
    }
    const currentEntries =
      item.details?.map(detail => ({
        product: item.product,
        productId: item.productId,
        ...detail
      })) || [];
    acc.push(...currentEntries);
    return acc;
  }, []);
};

export default formatCompleteStock;
