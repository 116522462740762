import moment from "moment";
import downloadIcal from "@/helpers/_functions/downloadIcal";
import { getTourIcal } from "../_api";

const state = {
  isCreatingIcal: false
};

const getters = {
  isCreatingIcal: state => state.isCreatingIcal
};

const mutations = {
  SET_CREATING_ICAL: (state, creatingIcal) => {
    state.isCreatingIcal = creatingIcal;
  }
};

const actions = {
  getTourIcal(context, params) {
    context.commit("SET_CREATING_ICAL", true);
    return new Promise((resolve, reject) => {
      getTourIcal(params)
        .then(res => {
          resolve(res.data.body);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit("SET_CREATING_ICAL", false);
        });
    });
  },
  downloadIcal(context) {
    const { technician, start } = context.state.query;

    /* Prepare query */
    const query = {
      intervenantIds: technician && technician._id ? [technician._id] : undefined,
      userIds: technician ? [technician._id] : undefined,
      plannedDateTimeStart: start ? moment(start).startOf("month") : undefined,
      plannedDateTimeEd: start ? moment(start).endOf("month") : undefined,
      startDateTime: start ? moment(start).startOf("month") : undefined,
      endDateTime: start ? moment(start).endOf("month") : undefined
    };

    context
      .dispatch("getTourIcal", {
        query
      })
      .then(({ ical }) => {
        const fileName = `tournee-${moment(start).format("MM-DD")}`;
        downloadIcal(ical, fileName);
      })
      .catch(error => {
        context.dispatch("updateFeedbackMessages", { error: error.data.errorMessage });
      });
  }
};

export default { state, getters, mutations, actions };
