/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import divisions from "@ui/helpers/_constants/urlDivisionsParams";
import { fetchProducts, searchProviders, searchProducts } from "@/modules/common/_api";

import * as api from "../_api";

const state = {
  products: [],
  currentDivision: null,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  currentCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  products: state => state.products,
  currentDivision: state => state.currentDivision,
  currentCount: state => state.currentCount,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_PRODUCTS: (state, products) => {
    state.products = products;
  },
  SET_CURRENT_DIVISION: (state, division) => {
    const currentDivisionParams = divisions.find(item => item.code === division).params;
    state.currentDivision = {
      code: division,
      params: currentDivisionParams
    };
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  exportProducts(context, sort) {
    const { formattedQuery, currentDivision } = context.state;
    return api.exportProducts({ sort, query: { ...formattedQuery, division: currentDivision.code } });
  },
  fetchProducts(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivision } = context.state;
      fetchProducts({ ...params, query: { ...formattedQuery, division: currentDivision.code } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_PRODUCTS", results);
          context.commit("SET_CURRENT_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { products, providers, ...formattedQuery } = query;
    if (products && products.length) {
      formattedQuery.productIds = products.map(item => item._id);
    }
    if (providers && providers.length) {
      formattedQuery.providerIds = providers.map(item => item._id);
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  setCurrentDivision(context, divisions) {
    context.commit("SET_CURRENT_DIVISION", divisions);
  },
  getProductsCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getProductsCount(params)
        .then(res => {
          context.commit("SET_COUNT", { division: params.query.division.toLowerCase(), count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchProducts(context, input) {
    return searchProducts({ query: { division: context.state.currentDivision.code, input }, skip: 0, limit: 10, source: "local" });
  },
  searchProviders(context, input) {
    return searchProviders({ query: { input } });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
