/* eslint-disable no-shadow */
import { settings } from "@/bootstrap";
import { searchPrescribers } from "@/modules/common/_api";

import mergeMainAndOtherPrescriptionAddressIds from "@/helpers/_functions/mergeMainAndOtherPrescriptionAddressIds";

import * as api from "../_api";

const state = {
  prescribers: []
};

const getters = {
  prescribers: state => state.prescribers
};

const mutations = {
  SET_PRESCRIBERS: (state, prescribers) => {
    state.prescribers = prescribers;
  }
};

const actions = {
  addPrescriber(context, data) {
    const { delegates, technicians, prescriptionAddresses, ...prescriber } = data;
    /*
     * Need to map prescriptionAddressIds, delegates and technicians because they are composed
     * of array of objects (this format is returned by input-search) but we need a different format (cf. json schema)
     */
    prescriber.delegates = delegates.map(({ delegate, ...item }) => ({
      id: delegate._id,
      email: delegate.email,
      firstname: delegate.firstname,
      lastname: delegate.lastname,
      ...item
    }));
    prescriber.email = prescriber.email.toLowerCase();
    prescriber.technicians = technicians.map(({ technician, ...item }) => ({ id: technician._id, ...item }));
    prescriber.prescriptionAddressIds = prescriptionAddresses.map(address => address._id);
    prescriber.active = true;
    return new Promise((resolve, reject) => {
      api
        .addPrescriber(prescriber)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addPrescriberMedpro(context, data) {
    const { mainPrescriptionAddressId, otherPrescriptionAddressIds, ...prescriber } = data;
    prescriber.email = prescriber?.email?.toLowerCase() || "";
    prescriber.prescriptionAddressIds = mergeMainAndOtherPrescriptionAddressIds(mainPrescriptionAddressId, otherPrescriptionAddressIds);
    prescriber.active = true;
    prescriber.divisionIds = [settings.default.defaultDivisionId];

    return new Promise((resolve, reject) => {
      api
        .addPrescriber(prescriber)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchPrescribers(context, input) {
    return new Promise((resolve, reject) => {
      searchPrescribers({ query: { input }, skip: 0, limit: 40, source: "local" })
        .then(res => {
          context.commit("SET_PRESCRIBERS", res.data.body);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkPrescriberEmailAvailability(context, params) {
    return api.checkPrescriberEmailAvailability(params);
  },
  checkPrescriberRppsAvailability(context, params) {
    return api.checkPrescriberRppsAvailability(params);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
