<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M12,21 C7.17298675,21 5.12095785,17 6.34752667,14 C7.5740955,11 10.0345594,11 12,3 C13.9654406,11 16.4259045,11 17.6524733,14 C18.8790421,17 16.8270132,21 12,21 Z M12,18 C14.4002186,18 15,16 15,15"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
