<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M13.0522189,6.34613467 C15.9979093,5.13982127 19.3637734,6.54986492 20.5700869,9.49555531 C20.8539723,10.1887736 21,10.9306845 21,11.6797789 C21,15.4333577 19.5060851,18.6442825 16.5589679,21.248236 C15.7679582,21.9471323 14.6678588,22.1789091 13.6621103,21.8585641 L12.3034911,21.4258248 C12.1060504,21.3629371 11.8939496,21.3629371 11.6965254,21.4258195 L10.3379224,21.8585796 C9.33216292,22.1789281 8.23205187,21.9471479 7.44103736,21.2482406 C4.49391806,18.6442894 3,15.4333618 3,11.6797789 C3,8.49665408 5.58043255,5.91622153 8.76355742,5.91622153 C9.5126519,5.91622153 10.2545628,6.06224926 10.9477811,6.34613467 L12.00001,6.77701049 L13.0522189,6.34613467 Z M13.9140182,5.49588364 L12.8825166,5.92292741 C12.0593663,6.26371335 11.2351064,5.43915572 11.5761896,4.61612852 L12.0033943,3.58529182 C12.2064707,3.09527236 12.595889,2.70599459 13.0859818,2.50309517 L14.1174834,2.07605139 C14.9406337,1.73526546 15.7648936,2.55982308 15.4238104,3.38285028 L14.9966057,4.41368699 C14.7935293,4.90370645 14.404111,5.29298422 13.9140182,5.49588364 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
