import http from "@/services/http";

/**
 * @param {Object} data
 * @returns {Object} response
 */
export const createEntityIntervention = data => http.post(`/entity-interventions/`, data);

/**
 * @param {String} id
 * @param {Object} data
 * @returns {Object} response
 */
export const updateEntityIntervention = (id, data) => http.put(`/entity-interventions/${id}`, data);

/**
 * @param {String} id
 * @param {Object} data
 * @returns {Object} response
 */
export const achieveEntityIntervention = (id, data) => http.put(`/entity-interventions/achieve/${id}`, data);

/**
 * @param {String} id
 * @returns {Object} response
 */
export const validateEntityIntervention = id => http.put(`/entity-interventions/validate/${id}`);

/**
 * @param {Object} params
 * @returns {Object} response
 */
export const fetchEntityInterventions = params => http.get("/entity-interventions", { params });

/**
 * @param {Object} params
 * @returns {Object} response
 */
export const getEntityInterventionsCount = params => http.get("/entity-interventions/count", { params });

/**
 * @param {Object} id
 * @returns {Object} response
 */
export const fetchEntityIntervention = id => http.get(`/entity-interventions/${id}`);

/**
 * @param {Object} data
 * @returns {Object} response
 */
export const exportEntityInterventions = params => http.get(`/entity-interventions/export`, { params });

/**
 * @param {String} id
 * @returns {Object} response
 */
export const deleteEntityIntervention = id => http.put(`/entity-interventions/delete/${id}`);

/**
 * @param {Object} data
 * @returns {Object} response
 */
export const createEntityDeliveries = data => http.post("/entity-deliveries", data);

/**
 * @param {Object} params
 * @returns {Object} response
 */
export const fetchEntityDeliveries = params => http.get("/entity-deliveries", { params });

/**
 * @param {Object} data
 * @returns {Object} response
 */
export const updateEntityDeliveries = (id, data) => http.put(`/entity-deliveries/${id}`, data);

/**
 * @param {String} id
 * @returns {Object} response
 */
export const archiveEntityDelivery = id => http.put(`/entity-deliveries/archive/${id}`);

/**
 * @param {Object} entityInterventionId
 * @returns {Object} response
 */
export const createEntitySalesOrder = entityInterventionId => http.post(`/entity-deliveries/create-sales`, entityInterventionId);

/**
 * @param {Object} entityInterventionId
 * @returns {Object} response
 */
export const createEntityStockMovement = entityInterventionId => http.post(`/entity-deliveries/create-stock-movement`, entityInterventionId);

/**
 * @param {Object} entityInterventionId
 * @returns {Object} response
 */
export const createEntityRecup = entityInterventionId => http.post(`/entity-deliveries/create-recup`, entityInterventionId);

/**
 * @param {Object} entityDeliveryId
 * @returns {Object} response
 */
export const cancelEntityDeliveryRemoval = entityDeliveryId => http.put(`/entity-deliveries/cancel-removal/${entityDeliveryId}`);

/**
 * @param {Object} communityId
 * @return {Object} response
 */
export const forceCommunityPlanification = communityId => http.get(`/entity-interventions/${communityId}/force-planification`);

/**
 * @param {Object} entityInterventionId
 * @return {Object} response
 */
export const deleteEntityInterventionAndReplanned = entityInterventionId =>
  http.put(`/entity-interventions/${entityInterventionId}/delete-and-replanned`);
