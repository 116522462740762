import { fetchPublications } from "../_api";

const state = {
  items: [],
  count: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  items: state => state.items,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ITEMS: (state, items) => {
    state.items = items;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  fetchPublications(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    return new Promise((resolve, reject) => {
      const { formattedQuery } = context.state;
      fetchPublications({ ...params, query: { ...formattedQuery } })
        .then(res => {
          const { results, count } = res?.data?.body || {};
          context.commit("SET_ITEMS", results);
          context.commit("SET_COUNT", count);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { creationDate, ...formattedQuery } = query;

    if (creationDate) {
      formattedQuery.creationDateStart = creationDate.start || undefined;
      formattedQuery.creationDateEnd = creationDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
