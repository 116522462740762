import { iconAddPrescriberOutlined, iconUserDoctorOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.patients-medecins.medecins";

const {
  prescribersRights: { CREATE_PRESCRIBER, ACCESS_PRESCRIBER }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconAddPrescriberOutlined,
    title: localize(localPath, "link--prescriberAddSearch"),
    routerLink: {
      name: "prescriberAddSearch"
    },
    rights: [CREATE_PRESCRIBER]
  },
  {
    icon: iconUserDoctorOutlined,
    title: localize(localPath, "link--prescribersList"),
    routerLink: {
      name: "prescribers",
      alias: "prescribersList"
    },
    rights: [ACCESS_PRESCRIBER]
  }
];

const subMenuPrescribersMedpro = {
  title: localize(localPath, "title"),
  slug: "medecins",
  links
};

export default subMenuPrescribersMedpro;
