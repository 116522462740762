/* eslint-disable import/no-extraneous-dependencies */
import get from "lodash/get";
import { fetchFile, fetchPrescriber } from "@/modules/common/_api";
import b64ToFile from "@/helpers/_functions/b64ToFile";
import * as api from "../_api";

const state = {
  prescriber: {}
};

const getters = {
  prescriber: state => state.prescriber,
  loading: state => state.loading
};

const mutations = {
  SET_SIGNATURE: (state, signature) => {
    state.prescriber.signature = signature;
  },
  SET_PRESCRIBER: (state, prescriber) => {
    state.prescriber = prescriber;
  }
};

const actions = {
  fetchPrescriber(context, id) {
    return new Promise((resolve, reject) => {
      fetchPrescriber(id)
        .then(res => {
          context.commit("SET_PRESCRIBER", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchSignature(context) {
    return new Promise((resolve, reject) => {
      const rpps = get(context.state.prescriber, "rpps");
      const ordoclicId = get(context.state.prescriber, "renewalInfos.ordoclicId");
      if (!rpps || !ordoclicId) {
        resolve();
        return;
      }
      api
        .fetchSignature({ rpps, ordoclicId })
        .then(res => {
          const signatureUrl = res.data && res.data.content ? `data:image/png;base64,${res.data.content}` : null;
          if (!signatureUrl) {
            return resolve();
          }
          b64ToFile({ url: signatureUrl, filename: "signature.png", type: "image/png" })
            .then(file => {
              context.commit("SET_SIGNATURE", file);
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
