/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */

import { fetchAvailableUserExternalIds } from "@/modules/common/_api";
import * as api from "../_api";

const state = {
  availableUserExternalIds: []
};

const getters = {
  availableUserExternalIds: state => state.availableUserExternalIds
};

const mutations = {
  SET_AVAILABLE_USER_EXTERNAL_IDS: (state, availableUserExternalIds) => {
    state.availableUserExternalIds = availableUserExternalIds;
  }
};

const actions = {
  editUserInternal(context, { id, externalId, ...data }) {
    return new Promise((resolve, reject) => {
      api
        .editUserInternal(id, { ...data, externalId: externalId || null })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkUserEmailAvailability(context, params) {
    return api.checkUserEmailAvailability(params);
  },
  fetchAvailableUserExternalIds(context) {
    return new Promise((resolve, reject) => {
      fetchAvailableUserExternalIds()
        .then(result => {
          const userExternalIds = result.data.body
            ? result.data.body.userExternalIds.map(externalId => ({ label: externalId, value: externalId }))
            : [];
          context.commit("SET_AVAILABLE_USER_EXTERNAL_IDS", userExternalIds);
          resolve();
        })
        .catch(err => reject(err));
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
