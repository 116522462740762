import { iconMedicalDevice } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.product.products";

const {
  productsRights: { ACCESS_PRODUCT }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconMedicalDevice,
    title: localize(localPath, "link--productsList"),
    routerLink: {
      name: "products",
      alias: "productsList"
    },
    rights: [ACCESS_PRODUCT]
  }
];

const subMenuProducts = {
  title: localize(localPath, "title"),
  slug: "all-products",
  links
};

export default subMenuProducts;
