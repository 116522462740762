import * as api from "../_api";

const state = {
  alertsSummaries: [],
  alertsReminders: [],
  filters: {}
};

const getters = {
  alertsSummaries: state => state.alertsSummaries,
  alertsReminders: state => state.alertsReminders,
  filters: state => state.filters
};

const mutations = {
  SET_ALERTS_SUMMARIES: (state, summaries) => {
    state.alertsSummaries = summaries;
  },
  SET_ALERTS_REMINDERS: (state, reminders) => {
    state.alertsReminders = reminders;
  },
  SET_FILTERS: (state, filters) => {
    state.filters = filters;
  }
};

const actions = {
  fetchDashboardView(context, { query, userId }) {
    return new Promise((resolve, reject) => {
      Promise.all([api.fetchAlertsSummaries({ query }), api.fetchAlertsReminders({ query: { ...query, userId }, limit: 5 })])
        .then(([summaries, reminders]) => {
          context.commit("SET_ALERTS_SUMMARIES", summaries.data.body);
          context.commit("SET_ALERTS_REMINDERS", reminders.data.body.results);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetData(context) {
    context.commit("SET_ALERTS_SUMMARIES", []);
    context.commit("SET_ALERTS_REMINDERS", []);
    context.commit("SET_FILTERS", {});
  },
  updateFilters(context, filters) {
    context.commit("SET_FILTERS", filters);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
