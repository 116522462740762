import documents from "@common/constants/documentsBase";
import { fetchFile } from "@/modules/common/_api";

const state = {
  documents
};

const getters = {
  documents: state => state.documents
};

const mutations = {};

const actions = {
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
