import http from "../../../services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>}
 */
export default params =>
  http.get(`/allStocksContent`, {
    params
  });
