import { iconAlert } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuAlerts from "./subMenuAlerts";

const localPath = "menus.administration.alert";

const menuAlerts = {
  title: localize(localPath, "title"),
  slug: "alerts",
  icon: iconAlert,
  disabled: false,
  items: [subMenuAlerts]
};

export default menuAlerts;
