import moment from "moment";

import { INTERVENANT } from "@common/constants/roles";
import { fetchUsers } from "@/modules/common/_api";

import * as api from "../_api";

const state = {
  technicians: [],
  report: {},
  previousReport: {},
  formattedQuery: {}
};

const getters = {
  technicians: state => state.technicians,
  report: state => state.report,
  previousReport: state => state.previousReport,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_TECHNICIANS: (state, technicians) => {
    state.technicians = technicians;
  },
  SET_REPORT: (state, report) => {
    state.report = report;
  },
  SET_PREVIOUS_REPORT: (state, previousReport) => {
    state.previousReport = previousReport;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  fetchTechnicians(context, poleIds) {
    return new Promise((resolve, reject) => {
      fetchUsers({ query: { poleIds, roleIds: [INTERVENANT.id], active: true }, limit: 0 })
        .then(res => {
          const { results } = res.data.body;
          context.commit("SET_TECHNICIANS", results);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchReport(context, { query }) {
    context.dispatch("formatQuery", query);
    const formattedQuery = { ...query };
    return new Promise((resolve, reject) => {
      if (!query.month || !query.poleId) {
        context.commit("SET_REPORT", {});
        context.commit("SET_PREVIOUS_REPORT", {});
        resolve();
        return;
      }

      api
        .fetchReport(formattedQuery)
        .then(report => {
          const month = moment(query.month).subtract(1, "months").startOf("month").toISOString();
          context.commit("SET_REPORT", report?.data?.body);
          api
            .fetchReport({ ...formattedQuery, month })
            .then(previousReport => {
              context.commit("SET_PREVIOUS_REPORT", previousReport?.data?.body);
              resolve();
            })
            .catch(err => {
              // Previous report can be null
              if (err?.data?.error?.response?.status === 418) {
                context.commit("SET_PREVIOUS_REPORT", {});
                resolve();
              }

              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  exportReport(context, { query }) {
    const formattedQuery = { ...query };
    return new Promise((resolve, reject) => {
      api
        .exportReport(formattedQuery)
        .then(res => {
          resolve(res?.data?.body);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { ...formattedQuery } = query;
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  resetReport(context) {
    context.commit("SET_REPORT", {});
    context.commit("SET_PREVIOUS_REPORT", {});
  }
};

export default { state, getters, mutations, actions, namespaced: true };
