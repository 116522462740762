import i18n from "@/i18n";

const i18nKey = "router.admin.login";

const CredentialsPage = () => import(/* webpackChunkName: "public" */ "@/modules/credentials/index");
const login = () => import(/* webpackChunkName: "public" */ "@/modules/login/index.admin");

export default [
  {
    path: "register",
    component: CredentialsPage,
    children: [
      {
        path: "user/:token",
        alias: "utilisateur/:token",
        name: "registerUsers",
        component: CredentialsPage,
        meta: {
          title: i18n.t(`${i18nKey}.title--register`),
          public: true
        }
      }
    ]
  },
  {
    path: "reset-password",
    component: CredentialsPage,
    children: [
      {
        path: "user/:token",
        alias: "utilisateur/:token",
        name: "resetPasswordUsers",
        component: CredentialsPage,
        meta: {
          title: i18n.t(`${i18nKey}.title--resetPassword`),
          public: true
        }
      }
    ]
  },
  {
    path: "sign-in",
    alias: "connexion",
    name: "login",
    component: login,
    meta: {
      title: i18n.t(`${i18nKey}.title--signIn`),
      public: true
    }
  }
];
