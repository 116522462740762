import http from "@/services/http";

export const editPrescriberInternals = (id, data) => http.put(`/prescribers/internals/${id}`, data);

export const editPrescriberGlobals = (id, data) => http.put(`/prescribers/globals/${id}`, data);

export const editPrescriberProtocols = (id, data) => http.put(`/prescribers/protocols/${id}`, data);

export const editPrescriberDap = (id, data) => http.put(`/prescribers/dap/${id}`, data);

export const searchPrescriptionAddresses = ({ query, skip, limit, source }) =>
  http.get("/prescription-addresses/search", {
    params: { query, skip, limit, source }
  });

export const createOrdoclicPrescriberAccount = (id, data) => http.post(`/prescribers/dap/ordoclic/create/${id}`, data);

export const saveSignature = data => http.post(`/prescribers/dap/ordoclic/save`, data);

export const checkPrescriberEmailAvailability = params =>
  http.get("/prescribers/email-availability", {
    params: { email: params.dataToCheck, id: params.idToCheck }
  });

export const checkPrescriberRppsAvailability = params =>
  http.get("/prescribers/rpps-availability", {
    params: { rpps: params.dataToCheck, id: params.idToCheck }
  });
