import http from "@/services/http";

export const getTechniciansCount = params =>
  http.get("/users/technicians/count", {
    params
  });

export const updateInterventionPlanning = (id, data) => http.put(`/interventions/planning/${id}`, data);

export const updateEntityInterventionPlanning = (id, data) => http.put(`/entity-interventions/planning/${id}`, data);
