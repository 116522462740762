import http from "@/services/http";

export const getProductsCount = params =>
  http.get("/products/count", {
    params
  });

export const searchProducts = params =>
  http.get("/products/search", {
    params
  });

export const exportProducts = params =>
  http.get("/products/export", {
    params
  });
