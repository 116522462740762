import { iconCalendarMonth, iconList, iconError } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.interventions-tours.interventions";

const {
  planManagementRights: { ACCESS_PLAN_MANAGEMENT },
  interventionsRights: { ACCESS_INTERVENTION },
  menuRights: { VIEW_MENU_ANOMALY_INTERVENTIONS, VIEW_MENU_INTERVENTIONS_TOURNEES }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconCalendarMonth,
    title: localize(localPath, "link--interventionsPlanning"),
    routerLink: {
      name: "interventionsPlanning"
    },
    rights: [ACCESS_PLAN_MANAGEMENT]
  },
  {
    icon: iconList,
    title: localize(localPath, "link--interventionsList"),
    routerLink: {
      name: "interventions"
    },
    rights: [ACCESS_INTERVENTION]
  },
  {
    icon: iconList,
    title: localize("menus.administration.interventions-tours.work.link--interWaiting"),
    rights: [ACCESS_INTERVENTION],
    routerLink: {
      name: "interventionsWaitingStatus"
    }
  },
  {
    icon: iconList,
    title: localize("menus.administration.interventions-tours.work.link--interReady"),
    rights: [ACCESS_INTERVENTION],
    routerLink: {
      name: "interventionsReadyStatus"
    }
  },
  {
    icon: iconError,
    title: localize(localPath, "link--interventionsWithAnomaly"),
    routerLink: {
      name: "interventionsWithAnomaly"
    },
    rights: [ACCESS_INTERVENTION, VIEW_MENU_ANOMALY_INTERVENTIONS]
  }
];

const subMenuIntervention = {
  title: localize(localPath, "title"),
  slug: "interventions",
  rights: [VIEW_MENU_INTERVENTIONS_TOURNEES],
  links
};

export default subMenuIntervention;
