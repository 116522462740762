import { searchUsers, searchPatients } from "@/modules/common/_api";

import * as api from "../_api";

const state = {
  count: 0,
  alerts: [],
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  alerts: state => state.alerts,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ALERTS: (state, alerts) => {
    state.alerts = alerts;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  fetchAlertsReminders(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      api
        .fetchAlertsReminders({ ...params, query: { division: "R", ...formattedQuery } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { count, results } = res.data.body;
          context.commit("SET_COUNT", count);
          context.commit("SET_ALERTS", results);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { patients, users, creationDate, expirationDate, ...formattedQuery } = query;
    if (users && users.length) {
      formattedQuery.userIds = users.map(item => item._id);
    }
    if (patients && patients.length) {
      formattedQuery.patientIds = patients.map(item => item._id);
    }
    if (creationDate && creationDate.start) {
      formattedQuery.creationDateStart = creationDate.start;
    }
    if (creationDate && creationDate.end) {
      formattedQuery.creationDateEnd = creationDate.end;
    }
    if (expirationDate && expirationDate.start) {
      formattedQuery.expirationDateStart = expirationDate.start;
    }
    if (expirationDate && expirationDate.end) {
      formattedQuery.expirationDateEnd = expirationDate.end;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  searchUsers(context, input) {
    return searchUsers({
      query: { input, active: true },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  searchPatients(context, input) {
    const query = { input };

    if (context.rootGetters["login/$user"].divisionIds && context.rootGetters["login/$user"].divisionIds.length) {
      query.divisions = context.rootGetters["login/$user"].divisionIds;
    }
    return searchPatients({ query, skip: 0, limit: 10, source: "local" });
  },
  exportReminders(context, { query, ...params }) {
    context.dispatch("formatQuery", query);
    const { formattedQuery } = context.state;
    return api.exportReminders({ ...params, query: { division: "R", ...formattedQuery } });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
