import { editContact } from "../_api";

const state = {
  contact: {}
};

const getters = {};

const mutations = {};

const actions = {
  editContact(context, data) {
    return editContact(data);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
