import * as api from "../_api";
import b64ToFile from "@/helpers/_functions/b64ToFile";

const fileTypes = require("@common/constants/fileTypes");

const state = {
  errorMessageSignature: null,
  successMessageSignature: null
};

const actions = {
  uploadSignatureFile(context, { id, signatureDataURL }) {
    return new Promise((resolve, reject) => {
      /* Transforms the signature before sending it */
      const filename = "signature.png";
      b64ToFile({
        filename,
        url: signatureDataURL,
        type: "image/png"
      })
        .then(file => {
          const formdata = new FormData();
          formdata.append("files", file);
          formdata.append(
            "filesAdditionalFields",
            JSON.stringify([{ originalname: filename, contentTypeId: fileTypes.patientSignature, autoGenerated: true }])
          );
          api
            .editInterventionOffline(id, formdata)
            .then(resp => resolve(resp))
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    });
  },
  updateSignatureFile(context, { intervention, signatureFile }) {
    return new Promise((resolve, reject) => {
      const data = {
        /* Remove previous patient's signature */
        files: intervention.files
          ? [
              ...intervention.files.filter(item => item.contentTypeId !== fileTypes.patientSignature),
              { ...signatureFile, contentTypeId: fileTypes.patientSignature, autoGenerated: true }
            ]
          : [{ ...signatureFile, contentTypeId: fileTypes.patientSignature, autoGenerated: true }]
      };
      api
        .editIntervention(intervention._id, data)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  signBl(context, { id, signatureDataURL, signatureFile }) {
    return new Promise((resolve, reject) => {
      if (signatureFile) {
        context.dispatch("common/fetchFile", signatureFile, { root: true }).then(file => {
          const signature = `data:image/png;base64,${file.body}`;
          api
            .signBl(id, { signature })
            .then(() => resolve())
            .catch(error => reject(error));
        });
      } else {
        api
          .signBl(id, { signature: signatureDataURL })
          .then(() => resolve())
          .catch(error => reject(error));
      }
    });
  },
  uploadSignaturePatientAndSignBL(context, { intervention, signatureFile, signatureDataURL }) {
    return new Promise((resolve, reject) => {
      const promises = [];
      if (signatureFile) {
        promises.push(context.dispatch("updateSignatureFile", { intervention, signatureFile }));
      }
      if (signatureDataURL) {
        promises.push(context.dispatch("uploadSignatureFile", { id: intervention._id, signatureDataURL }));
      }
      Promise.all(promises)
        .then(() => {
          context
            .dispatch("signBl", { id: intervention._id, signatureDataURL, signatureFile })
            .then(() => resolve())
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    });
  },
  uploadSignaturePatient(context, { intervention, signatureFile, signatureDataURL }) {
    return new Promise((resolve, reject) => {
      const promises = [];
      if (signatureFile) {
        promises.push(context.dispatch("updateSignatureFile", { intervention, signatureFile }));
      }
      if (signatureDataURL) {
        promises.push(context.dispatch("uploadSignatureFile", { id: intervention._id, signatureDataURL }));
      }

      Promise.all(promises)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  }
};

export default { state, actions, namespaced: true };
