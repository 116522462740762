/* eslint-disable no-shadow */
import * as api from "../_api";

const state = {
  communitiesGroup: {},
  poles: []
};

const getters = {
  communitiesGroup: state => state.communitiesGroup
};

const mutations = {
  SET_COMMUNITIES_GROUP: (state, communitiesGroup) => {
    state.communitiesGroup = communitiesGroup;
  },
  UPDATE_LOADING: (state, loadingItems) => {
    state.loading = { ...state.loading, ...loadingItems };
  },
  SET_POLES: (state, poles) => {
    state.poles = poles;
  }
};

const actions = {
  fetchCommunitiesGroup(context, communitiesGroupId) {
    return new Promise((resolve, reject) => {
      api
        .fetchCommunitiesGroup(communitiesGroupId)
        .then(res => {
          context
            .dispatch("bindCommunitiesGroup", res.data.body)
            .then(resolve)
            .catch(err => reject(err));
        })
        .catch(err => {
          context.commit("UPDATE_LOADING", {
            communitiesGroup: false
          });
          reject(err);
        });
    });
  },
  bindCommunitiesGroup(context, data) {
    return new Promise(resolve => {
      const communitiesGroup = data;
      context.commit("SET_COMMUNITIES_GROUP", communitiesGroup);
      resolve();
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
