import { iconDashboard, iconAlertAdd, iconAlert, iconMailOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.alert.alerts";

const {
  alertsRights: { ACCESS_ALERT },
  alertsTemplatesRights: { CREATE_ALERT_TEMPLATE, ACCESS_ALERT_TEMPLATE }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconDashboard,
    title: localize(localPath, "link--alertsDashboard"),
    routerLink: {
      name: "alertsDashboard"
    },
    rights: [ACCESS_ALERT]
  },
  {
    icon: iconAlertAdd,
    title: localize(localPath, "link--alertTemplatesAdd"),
    routerLink: {
      name: "alertTemplatesAdd"
    },
    rights: [CREATE_ALERT_TEMPLATE]
  },
  {
    icon: iconAlert,
    title: localize(localPath, "link--alertTemplatesList"),
    routerLink: {
      name: "alertTemplatesList"
    },
    rights: [ACCESS_ALERT_TEMPLATE]
  },
  {
    icon: iconMailOutlined,
    title: localize(localPath, "link--alertsReminders"),
    routerLink: {
      name: "alertsReminders"
    },
    rights: [ACCESS_ALERT]
  }
];

const subMenuAlerts = {
  title: localize(localPath, "title"),
  slug: "alerts-sub",
  links
};

export default subMenuAlerts;
