import i18n from "@/i18n";

const i18nKey = "router.admin.renewals";

const RenewalsUnlinked = () => import(/* webpackChunkName: "renewals" */ "@/modules/renewals-unlinked");

const {
  contractsRights: { DELETE_RENOU }
} = require("@common/services/acm/rights");

export default [
  {
    path: "renewals/unlinked",
    name: "renewalsUnlinked",
    component: RenewalsUnlinked,
    meta: {
      title: i18n.t(`${i18nKey}.title--renewals`),
      rights: [DELETE_RENOU]
    }
  }
];
