import http from "@/services/http";

export const addPrescriber = prescriber => http.post("/prescribers", prescriber);

export const searchPrescriptionAddresses = ({ query, skip, limit, source }) =>
  http.get("/prescription-addresses/search", {
    params: { query, skip, limit, source }
  });

export const checkPrescriberEmailAvailability = params =>
  http.get("/prescribers/email-availability", {
    params: { email: params.dataToCheck, id: params.idToCheck }
  });

export const checkPrescriberRppsAvailability = params =>
  http.get("/prescribers/rpps-availability", {
    params: { rpps: params.dataToCheck, id: params.idToCheck }
  });
