import isEmpty from "lodash/isEmpty";

import {
  searchProducts,
  searchInStocks,
  fetchAgaProductInformations,
  fetchEquipementInformations,
  fetchEquipementInformationsFromScan
} from "@/modules/common/_api";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";

const state = {
  stockId: null,
  poleStockId: null,
  loadingFromSearchInStocks: false,
  stocksSearchResults: [],
  feedbackMessagesFromSearchInStocks: {
    error: null
  }
};

const getters = {
  loadingFromSearchInStocks: state => state.loadingFromSearchInStocks,
  stocksSearchResults: state => state.stocksSearchResults,
  feedbackMessagesFromSearchInStocks: state => state.feedbackMessagesFromSearchInStocks
};

const mutations = {
  SET_SEARCH_IN_STOCKS_STOCK_ID: (state, stockId) => {
    state.stockId = stockId;
  },
  SET_SEARCH_IN_STOCKS_POLE_STOCK_ID: (state, poleStockId) => {
    state.poleStockId = poleStockId;
  },
  SET_SEARCH_IN_STOCKS_RESULTS: (state, stocksSearchResults) => {
    state.stocksSearchResults = stocksSearchResults;
  },
  SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES: (states, { key, value }) => {
    state.feedbackMessagesFromSearchInStocks[key] = value;
  },
  UPDATE_SEARCH_IN_STOCKS_LOADING: (state, loadingFromSearchInStocks) => {
    state.loadingFromSearchInStocks = loadingFromSearchInStocks;
  },
  RESET_SEARCH_IN_STOCKS_STATE: state => {
    state.stockId = null;
    state.poleStockId = null;
    state.loadingFromSearchInStocks = false;
    state.stocksSearchResults = [];
    state.feedbackMessagesFromSearchInStocks = {
      error: null
    };
  }
};

const actions = {
  searchInStocksByEquipement(context, query) {
    context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", true);
    context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: null });
    context.commit("SET_SEARCH_IN_STOCKS_RESULTS", []);

    fetchEquipementInformations(query)
      .then(resGetEquipement => {
        const equipement = resGetEquipement.data.body || null;
        if (!equipement || !equipement.productId) {
          context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          return;
        }
        searchProducts({ query: { cips: [equipement.productId, equipement.productId.toUpperCase()] }, source: "local" })
          .then(resGetProduct => {
            const product = resGetProduct.data && resGetProduct.data.body && resGetProduct.data.body.length ? resGetProduct.data.body[0] : null;
            if (!product) {
              context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
              return;
            }
            context.commit("SET_SEARCH_IN_STOCKS_RESULTS", [{ ...equipement, product }]);
          })
          .catch(() => {
            context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
          })
          .finally(() => {
            context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          });
      })
      .catch(() => {
        context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
        context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
      });
  },
  searchInStocksByEquipementFromScan(context, query) {
    context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", true);
    context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: null });
    context.commit("SET_SEARCH_IN_STOCKS_RESULTS", []);

    fetchEquipementInformationsFromScan(query)
      .then(resGetEquipement => {
        const equipement = resGetEquipement.data.body || null;
        if (!equipement || !equipement.productId) {
          context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          return;
        }
        searchProducts({ query: { cips: [equipement.productId, equipement.productId.toUpperCase()] }, source: "local" })
          .then(resGetProduct => {
            const product = resGetProduct.data && resGetProduct.data.body && resGetProduct.data.body.length ? resGetProduct.data.body[0] : null;
            if (!product) {
              context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
              return;
            }
            context.commit("SET_SEARCH_IN_STOCKS_RESULTS", [{ ...equipement, product }]);
          })
          .catch(() => {
            context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
          })
          .finally(() => {
            context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          });
      })
      .catch(() => {
        context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
        context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
      });
  },
  searchInStocksByReference(context, query) {
    context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", true);
    context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: null });
    context.commit("SET_SEARCH_IN_STOCKS_RESULTS", []);

    const { stockId, poleStockId } = context.state;

    if (!stockId) {
      context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.searchInStocks });
      context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
      context.commit("SET_SEARCH_IN_STOCKS_RESULTS", []);
      return;
    }

    const stockIds = [stockId];

    if (poleStockId && !stockIds.includes(poleStockId)) {
      stockIds.push(poleStockId);
    }

    searchInStocks({ productIds: [query.productId], stockIds })
      .then(response => {
        const results = response.data && response.data.body && response.data.body.length ? response.data.body : [];
        const resultsFilteredOnStockId = results.filter(result => result.stockId === stockId);

        if (!isEmpty(resultsFilteredOnStockId)) {
          context.commit("SET_SEARCH_IN_STOCKS_RESULTS", resultsFilteredOnStockId);
        } else {
          context.commit("SET_SEARCH_IN_STOCKS_RESULTS", results);
        }
      })
      .catch(err => {
        context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: err.data.errorMessage });
      })
      .finally(() => {
        context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
      });
  },
  searchInStocksByEan(context, query) {
    context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", true);
    context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: null });
    context.commit("SET_SEARCH_IN_STOCKS_RESULTS", []);

    const { stockId, poleStockId } = context.state;

    if (!stockId) {
      context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.searchInStocks });
      context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
      context.commit("SET_SEARCH_IN_STOCKS_RESULTS", []);
      return;
    }

    const stockIds = [stockId];

    if (poleStockId && !stockIds.includes(poleStockId)) {
      stockIds.push(poleStockId);
    }

    searchProducts({ query: { eans: [query.ean] }, source: "local" })
      .then(resGetProduct => {
        const product =
          resGetProduct && resGetProduct.data && resGetProduct.data.body && resGetProduct.data.body.length ? resGetProduct.data.body[0] : null;

        if (!product) {
          context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          return;
        }
        searchInStocks({ productIds: [product._id], stockIds })
          .then(response => {
            const results = response.data && response.data.body && response.data.body.length ? response.data.body : [];
            const resultsFilteredOnStockId = results.filter(result => result.stockId === stockId);

            if (!isEmpty(resultsFilteredOnStockId)) {
              context.commit("SET_SEARCH_IN_STOCKS_RESULTS", resultsFilteredOnStockId);
            } else {
              context.commit("SET_SEARCH_IN_STOCKS_RESULTS", results);
            }
          })
          .catch(() => {
            context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
          })
          .finally(() => {
            context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          });
      })
      .catch(() => {
        context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
        context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
      });
  },
  searchInStocksByAga(context, query) {
    context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", true);
    context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: null });
    context.commit("SET_SEARCH_IN_STOCKS_RESULTS", []);

    fetchAgaProductInformations(query)
      .then(resGetAgaProduct => {
        const agaProduct = resGetAgaProduct.data.body || null;
        if (!agaProduct || !agaProduct.productId) {
          context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          return;
        }

        searchProducts({ query: { cips: [agaProduct.productId, agaProduct.productId.toUpperCase()] }, source: "local" })
          .then(resGetProduct => {
            const product = resGetProduct.data && resGetProduct.data.body && resGetProduct.data.body.length ? resGetProduct.data.body[0] : null;
            if (!product) {
              context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
              return;
            }
            context.commit("SET_SEARCH_IN_STOCKS_RESULTS", [{ ...agaProduct, product }]);
          })
          .catch(() => {
            context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
          })
          .finally(() => {
            context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
          });
      })
      .catch(() => {
        context.commit("SET_SEARCH_IN_STOCKS_FEEDBACK_MESSAGES", { key: "error", value: defaultErrorMessages.search });
        context.commit("UPDATE_SEARCH_IN_STOCKS_LOADING", false);
      });
  },
  setSearchInStocksStockId(context, stockId) {
    context.commit("SET_SEARCH_IN_STOCKS_STOCK_ID", stockId);
  },
  setSearchInStocksPoleStockId(context, poleStockId) {
    context.commit("SET_SEARCH_IN_STOCKS_POLE_STOCK_ID", poleStockId);
  },
  resetSearchInStocksState(context) {
    context.commit("RESET_SEARCH_IN_STOCKS_STATE");
  }
};

export default { state, getters, mutations, actions };
