/* eslint-disable no-shadow */
import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  searchContacts(context, input) {
    return api.searchContacts({ query: { input, active: true }, skip: 0, limit: 10, source: "local" });
  },
  addContact(context, data) {
    delete data.file;
    return api.addContact({ ...data, active: true });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
