import LogoAppSmall from "@ui/components/icons/LogoAppSmall";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration";

const menuDashboard = {
  title: localize(localPath, "link--dashboard"),
  slug: "dashboard",
  icon: LogoAppSmall,
  routerLink: { name: "dashboard" },
  disabled: false,
  isHome: true
};

export default menuDashboard;
