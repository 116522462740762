import i18n from "@/i18n";

const i18nKey = "router.admin.entity-interventions";

// View & Edit
const EntityInterventionGeneralView = () =>
  import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/view/_components/EntityInterventionGeneralView");

const EntityInterventionDispositifsMedicauxView = () =>
  import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/view/_components/EntityInterventionDispositifsMedicaux");

const EntityInterventionConsommablesView = () =>
  import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/view/_components/EntityInterventionConsommables");

const EntityInterventionAnomalyView = () =>
  import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/view/_components/EntityInterventionAnomaly");

const EntityInterventionView = () => import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/view");

const EntityInterventionPatientsView = () =>
  import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/view/_components/EntityInterventionPatients");

// List
const EntityInterventions = () => import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/list");
const EntityInterventionsWithAnomaly = () =>
  import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/list/_components/WithAnomaly");
const EntityInterventionsValidatedStatus = () =>
  import(/* webpackChunkName: "entity-intervention" */ "@/modules/entity-interventions/list/_components/ValidatedStatus");

const {
  entityInterventionsRights: { ACCESS_ENTITY_INTERVENTION },
  menuRights: { VIEW_MENU_ANOMALY_ENTITY_INTERVENTIONS }
} = require("@common/services/acm/rights");

export default [
  {
    path: "establishment-deliveries",
    alias: "livraisons-collectivite",
    name: "entityInterventions",
    component: EntityInterventions,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--entityInterventions`),
      rights: [ACCESS_ENTITY_INTERVENTION]
    }
  },
  {
    path: "establishment-deliveries/in-anomaly",
    alias: "livraisons-collectivite/en-anomalie",
    name: "entityInterventionsWithAnomaly",
    meta: {
      title: i18n.t(`${i18nKey}.title--entityInterventionsWithAnomaly`),
      rights: [ACCESS_ENTITY_INTERVENTION, VIEW_MENU_ANOMALY_ENTITY_INTERVENTIONS]
    },
    component: EntityInterventionsWithAnomaly
  },
  {
    path: "establishment-deliveries/to-be-validated-and-checked",
    alias: "livraisons-collectivite/a-valider-et-controler",
    name: "entityInterventionsValidatedStatus",
    meta: {
      title: i18n.t(`${i18nKey}.title--entityInterventionsValidatedStatus`),
      rights: [ACCESS_ENTITY_INTERVENTION]
    },
    component: EntityInterventionsValidatedStatus
  },
  {
    path: "establishment-delivery/:id",
    alias: "livraison-collectivite/:id",
    component: EntityInterventionView,
    children: [
      {
        path: "",
        name: "entityInterventionView",
        meta: {
          title: i18n.t(`${i18nKey}.title--entityInterventionView`),
          rights: [ACCESS_ENTITY_INTERVENTION]
        },
        component: EntityInterventionGeneralView
      },
      {
        path: "medical-devices",
        alias: "dispositifs-medicaux",
        name: "entityInterventionMachines",
        meta: {
          title: i18n.t(`${i18nKey}.title--entityInterventionView`),
          rights: [ACCESS_ENTITY_INTERVENTION]
        },
        component: EntityInterventionDispositifsMedicauxView
      },
      {
        path: "consumables",
        alias: "consommables",
        name: "entityInterventionConsommables",
        meta: {
          title: i18n.t(`${i18nKey}.title--entityInterventionView`),
          rights: [ACCESS_ENTITY_INTERVENTION]
        },
        component: EntityInterventionConsommablesView
      },
      {
        path: "anomaly",
        alias: "anomalie",
        name: "entityInterventionAnomaly",
        meta: {
          title: i18n.t(`${i18nKey}.title--entityInterventionView`),
          rights: [ACCESS_ENTITY_INTERVENTION]
        },
        component: EntityInterventionAnomalyView
      },
      {
        path: "patients",
        name: "entityInterventionPatients",
        meta: {
          title: i18n.t(`${i18nKey}.title--entityInterventionView`),
          rights: [ACCESS_ENTITY_INTERVENTION]
        },
        component: EntityInterventionPatientsView
      }
    ]
  }
];
