import { settings } from "@/bootstrap";

import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  checkCommunitiesGroupEmailAvailability(context, params) {
    return api.checkCommunitiesGroupEmailAvailability(params);
  },
  addCommunitiesGroup(context, data) {
    const { delegates, technicians, communities, ownerFirstname, ownerLastname, ownerEmail, ...communitiesGroup } = data;
    communitiesGroup.active = true;
    communitiesGroup.divisionIds = [settings.default.defaultDivisionId];
    communitiesGroup.delegates = delegates.map(({ delegate, ...item }) => ({ id: delegate._id, ...item }));
    communitiesGroup.technicians = technicians.map(({ technician, ...item }) => ({ id: technician._id, ...item }));
    communitiesGroup.communityIds = communities.map(community => community._id);
    communitiesGroup.owner = {
      firstname: ownerFirstname || undefined,
      lastname: ownerLastname || undefined,
      email: ownerEmail?.toLowerCase() || undefined
    };

    return new Promise((resolve, reject) => {
      api
        .addCommunitiesGroup(communitiesGroup)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
