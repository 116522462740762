import moment from "moment";
import { displayUserResource } from "@ui/helpers/_functions/displaySpecificResource";
import { fetchUsers, uploadPrivateFile } from "@/modules/common/_api";

import { fetchSupportTicket, editSupportTicket, createPlatformIssue } from "@/modules/support-tickets/view/_api";

const { SUPER_ADMINISTRATEUR, SUPPORT } = require("@common/constants/roles");

const state = {
  loading: true,
  supportTicket: {},
  grantedSupportRoles: []
};

const getters = {
  loading: state => state.loading,
  supportTicket: state => state.supportTicket,
  grantedSupportRoles: state => state.grantedSupportRoles
};

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_SUPPORT_TICKET: (state, supportTicket) => {
    state.supportTicket = supportTicket;
  },
  SET_GRANTED_SUPPORT_ROLES: (state, grantedSupportRoles) => {
    state.grantedSupportRoles = grantedSupportRoles;
  }
};

const actions = {
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  fetchSupportTicket(context, id) {
    return new Promise((resolve, reject) => {
      fetchSupportTicket(id)
        .then(res => {
          context.commit("SET_SUPPORT_TICKET", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchGrantedSupportRoles(context) {
    return new Promise((resolve, reject) => {
      fetchUsers({ query: { roleIds: [SUPER_ADMINISTRATEUR.id, SUPPORT.id], active: true } })
        .then(res => {
          const { results } = res.data.body;
          context.commit(
            "SET_GRANTED_SUPPORT_ROLES",
            results.map(item => ({ ...item, label: displayUserResource(item), value: item._id }))
          );
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editSupportTicket(context, { id, data }) {
    return new Promise((resolve, reject) => {
      editSupportTicket(id, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addComment(context, { id, data }) {
    return new Promise((resolve, reject) => {
      const { supportTicket } = context.state;
      const { user } = context.rootState.login;
      const existingComments = supportTicket.comments || [];
      const newComment = { ...data, date: moment().toISOString(), userId: user._id };
      editSupportTicket(id, { ...supportTicket, comments: [...existingComments, newComment] })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetData(context) {
    context.commit("SET_SUPPORT_TICKET", {});
  },
  createPlatformIssue({ dispatch }, ticket) {
    dispatch("setLoading", true);
    createPlatformIssue(ticket).then(() => {
      dispatch("fetchSupportTicket", ticket._id).finally(() => {
        dispatch("setLoading", false);
      });
    });
  },
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
