import { getLocalUser } from "@/services/auth";

export default function authorization({ next, to }) {
  getLocalUser().then(user => {
    const isRoutePrivate = !to.matched.some(record => record.meta.public);
    const { rights } = to.meta;
    const permissionRouteIsDefined = rights?.length || false;
    const userIsLoggedIn = user?.isLoggedIn || false;

    if (isRoutePrivate && userIsLoggedIn && permissionRouteIsDefined) {
      const isAuthorized = rights.every(right => user?.rights.includes(right));
      if (!isAuthorized) {
        return next({ name: "Forbidden" });
      }
    }
    return next();
  });
}
