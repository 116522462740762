import {
  createIntervention,
  uploadPrivateFile,
  uploadPublicFile,
  archiveUser,
  banUser,
  archivePatient,
  banPatient,
  archivePrescriber,
  banPrescriber,
  archivePrescribersGroup,
  banPrescribersGroup,
  synchronizePatient,
  synchronizeToBufferStock,
  synchronizeCommunity,
  synchronizePrescriptionAddresses,
  createRiskAnalysis,
  closeRiskAnalysis,
  getTelemonitoringReport
} from "@/modules/common/_api";

import bindNewInterventionData from "@/helpers/_functions/intervention/bindNewInterventionData";

import searchInStocks from "./searchInStocks";
import prescriptionAddress from "./prescriptionAddress";
import equipementInformations from "./equipementInformations";
import fetchStore from "./fetch";
import searchStore from "./searchs";
import feedbackMessagesStore from "./feedbackMessages";

const state = {
  taxonomies: {},
  ...equipementInformations.state,
  ...searchInStocks.state,
  ...feedbackMessagesStore.state,
  ...prescriptionAddress.state
};

const getters = {
  ...equipementInformations.getters,
  ...searchInStocks.getters,
  ...feedbackMessagesStore.getters,
  ...prescriptionAddress.getters,
  taxonomies: state => state.taxonomies,
  getCurrentUserPoles: (state, get, rootState, rootGetters) => {
    const currentUser = rootGetters["login/$user"];

    if (!currentUser) {
      return state.taxonomies.poles;
    }
    return state.taxonomies.poles.filter(pole => currentUser.poleIds.includes(pole.poleId));
  }
};

const mutations = {
  ...equipementInformations.mutations,
  ...searchInStocks.mutations,
  ...feedbackMessagesStore.mutations,
  ...prescriptionAddress.mutations,
  SET_TAXONOMIES: (state, taxonomy) => {
    Object.assign(state.taxonomies, taxonomy);
  }
};

const actions = {
  ...fetchStore.actions,
  ...searchStore.actions,
  ...equipementInformations.actions,
  ...searchInStocks.actions,
  ...feedbackMessagesStore.actions,
  ...prescriptionAddress.actions,
  createIntervention(context, data) {
    return new Promise((resolve, reject) => {
      bindNewInterventionData(data)
        .then(intervention => {
          if (!intervention) {
            return reject();
          }

          return createIntervention(intervention)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTelemonitoringReport(context, options) {
    return new Promise((resolve, reject) => {
      getTelemonitoringReport(options)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  uploadFile(context, { file, fieldname, isPrivate = true }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return isPrivate ? uploadPrivateFile(formdata) : uploadPublicFile(formdata);
  },
  archiveUser(context, params) {
    return archiveUser(params);
  },
  banUser(context, params) {
    return banUser(params);
  },
  archivePatient(context, params) {
    return archivePatient(params);
  },
  banPatient(context, params) {
    return banPatient(params);
  },
  archivePrescriber(context, params) {
    return archivePrescriber(params);
  },
  banPrescriber(context, params) {
    return banPrescriber(params);
  },
  archivePrescribersGroup(context, params) {
    return archivePrescribersGroup(params);
  },
  banPrescribersGroup(context, params) {
    return banPrescribersGroup(params);
  },
  synchronizePatient(context, patient) {
    return synchronizePatient(patient);
  },
  synchronizeToBufferStock(context, intervention) {
    return synchronizeToBufferStock(intervention._id);
  },
  synchronizeCommunity(context, communityId) {
    return synchronizeCommunity(communityId);
  },
  synchronizePrescriptionAddresses(context, rpps) {
    return synchronizePrescriptionAddresses(rpps);
  },
  createRiskAnalysis(context, { interventionId, riskAnalysis }) {
    return createRiskAnalysis(interventionId, riskAnalysis);
  },
  closeRiskAnalysis(context, { interventionId, riskAnalysis }) {
    return closeRiskAnalysis(interventionId, riskAnalysis);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
