import commonStore from "./index.common";
import { createConversation } from "@/modules/common/_api";
import formatNewConversationData from "@/helpers/_functions/conversations/formatNewConversationData";

const state = {
  ...commonStore.state
};

const getters = {
  ...commonStore.getters
};

const mutations = {
  ...commonStore.mutations
};

const actions = {
  ...commonStore.actions,
  addConversation(context, data) {
    return new Promise((resolve, reject) => {
      const { conversation, message } = formatNewConversationData(data);
      createConversation(conversation, message)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
