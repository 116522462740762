import http from "@/services/http";

export const updateAnomalyStatus = (entityInterventionId, data) => http.put(`/entity-interventions/${entityInterventionId}/anomaly`, data);

export const syncAnomalyMachinesStock = entityInterventionId => http.post(`/entity-deliveries/sync-anomaly-stock/machines`, { entityInterventionId });

export const syncAnomalyAgaProductsStock = entityInterventionId =>
  http.post(`/entity-deliveries/sync-anomaly-stock/aga-products`, { entityInterventionId });

export const fetchInterventionsLinkedByEntityInterventionId = entityInterventionId =>
  http.get(`/entity-interventions/${entityInterventionId}/interventions`);
