import commonSettings from "@common/settings/medpro";
import administrationMenu from "./menus/administration.medpro";
import prescriberMenu from "./menus/prescriber.medpro";
import administrationFooter from "./footer/administration.medpro";
import prescriberFooter from "./footer/prescriber.medpro";
import extranetFooter from "./footer/extranet.bastide";
import administrationDashboard from "./dashboard/administration.medpro";
import supportedLocales from "./supported-locales/supported-locales.medpro";

const sentryDsn = "";

const titleApp = "MedPro";
const name = "MedPro";
const longName = "MedPro";
const extranetAddresseeName = "Agence MedPro";

const headOffice = "Unit 101-9670 188 Street, Surrey, British Columbia V4N 3M2";

export default {
  ...commonSettings,
  administrationMenu,
  prescriberMenu,
  administrationFooter,
  prescriberFooter,
  extranetFooter,
  sentryDsn,
  titleApp,
  name,
  longName,
  headOffice,
  administrationDashboard,
  supportedLocales,
  extranetAddresseeName
};
