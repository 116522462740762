/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */

import divisions from "@ui/helpers/_constants/urlDivisionsParams";

import { fetchPrescribersGroups, searchPrescribers } from "../../common/_api";

import * as api from "../_api";

const state = {
  prescribersGroups: [],
  currentDivision: null,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  currentCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  allPrescribersGroups: state => state.prescribersGroups,
  currentDivision: state => state.currentDivision,
  count: state => state.count,
  currentCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_PRESCRIBERS_GROUPS: (state, prescribersGroups) => {
    state.prescribersGroups = prescribersGroups;
  },
  SET_CURRENT_DIVISION: (state, division) => {
    const currentDivisionParams = divisions.find(item => item.code === division).params;
    state.currentDivision = {
      code: division,
      params: currentDivisionParams
    };
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  getPrescribersGroupsCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getPrescribersGroupsCount(params)
        .then(count => {
          context.commit("SET_COUNT", { division: params.query.division.toLowerCase(), count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribersGroups(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivision } = context.state;
      fetchPrescribersGroups({ ...params, query: { ...formattedQuery, division: currentDivision.code } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_PRESCRIBERS_GROUPS", results);
          context.commit("SET_CURRENT_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { prescribers, ...formattedQuery } = query;
    if (prescribers && prescribers.length) {
      formattedQuery.prescriberIds = prescribers.map(item => item._id);
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  },
  setCurrentDivision(context, division) {
    context.commit("SET_CURRENT_DIVISION", division);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
