import { fetchStocks, fetchGasOxygenLifecycles } from "@/modules/common/_api";
import { exportGasOxygenLifecyclesHistory, searchGasOxygenLifecyclesAgaNumbers, searchGasOxygenLifecyclesBatchNumbers } from "../_api";

const state = {
  items: [],
  count: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  items: state => state.items,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ITEMS: (state, items) => {
    state.items = items;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  exportGasOxygenLifecyclesHistory(context, params) {
    const { formattedQuery } = context.state;
    return exportGasOxygenLifecyclesHistory({
      ...params,
      query: formattedQuery
    });
  },
  fetchGasOxygenLifecycles(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      fetchGasOxygenLifecycles({ ...params, query: { ...formattedQuery } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_ITEMS", results);
          context.commit("SET_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { agaNumbers, batchNumbers, products, date, ...formattedQuery } = query;
    if (agaNumbers && agaNumbers.length) {
      formattedQuery.gasOxygenLifecycleAgaNumbers = agaNumbers.map(item => item.agaNumber);
    }
    if (batchNumbers && batchNumbers.length) {
      formattedQuery.gasOxygenLifecycleBatchNumbers = batchNumbers.map(item => item.batchNumber);
    }
    if (products && products.length) {
      formattedQuery.products = products;
    }
    if (date) {
      formattedQuery.dateStart = date.start || undefined;
      formattedQuery.dateEnd = date.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  searchGasOxygenLifecyclesAgaNumbers(context, agaNumberInput) {
    return searchGasOxygenLifecyclesAgaNumbers({ query: { agaNumberInput }, skip: 0, limit: 10, source: "local" });
  },
  searchGasOxygenLifecyclesBatchNumbers(context, batchNumberInput) {
    return searchGasOxygenLifecyclesBatchNumbers({ query: { batchNumberInput }, skip: 0, limit: 10, source: "local" });
  },
  searchStocks(context, input) {
    /* Only search for pole & camion stocks */
    return fetchStocks({ query: { input, stockTypeIds: ["PR", "SC"] }, skip: 0, limit: 10 });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
