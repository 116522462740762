import supportTicketStatuses from "@common/constants/supportTicketStatuses";
import { searchPrescribers, searchUsers } from "@/modules/common/_api";
import { getSupportTicketsCount, fetchSupportTickets } from "../_api";

const { SUPPORT } = require("@common/constants/roles");

const state = {
  supportTickets: [],
  count: 0,
  unreadCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  supportTickets: state => state.supportTickets,
  count: state => state.count,
  unreadCount: state => state.unreadCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ITEMS: (state, supportTickets) => {
    state.supportTickets = supportTickets;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_UNREAD_COUNT: (state, unreadCount) => {
    state.unreadCount = unreadCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  fetchSupportTickets(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      fetchSupportTickets({ ...params, query: { ...formattedQuery } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_ITEMS", results);
          context.commit("SET_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getSupportTicketsCount(context, params) {
    return new Promise((resolve, reject) => {
      getSupportTicketsCount(params)
        .then(res => {
          context.commit("SET_COUNT", { count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCountUnreadSupportTickets(context) {
    getSupportTicketsCount({ query: { supportTicketStatusIds: [supportTicketStatuses.todo] } })
      .then(res => {
        context.commit("SET_UNREAD_COUNT", res?.data?.body?.count);
      })
      .catch(() => {});
  },
  formatQuery(context, query) {
    const { users, prescribers, creationDate, ...formattedQuery } = query;
    if (users && users.length) {
      formattedQuery.userIds = users.map(item => item._id);
    }
    if (prescribers && prescribers.length) {
      formattedQuery.prescriberIds = prescribers.map(item => item._id);
    }
    if (creationDate) {
      formattedQuery.creationDateStart = creationDate.start || undefined;
      formattedQuery.creationDateEnd = creationDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  searchUsers(context, input) {
    return searchUsers({
      query: { input, active: true, roleIds: [SUPPORT.id] },
      skip: 0,
      limit: 10
    });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input }, skip: 0, limit: 10, source: "local" });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
