import { fetchContact, archiveContact } from "../_api";

const state = {
  contact: {}
};

const getters = {
  contact: state => state.contact
};

const mutations = {
  SET_CONTACT: (state, contact) => {
    state.contact = contact;
  }
};

const actions = {
  fetchContact(context, idContact) {
    return new Promise((resolve, reject) => {
      fetchContact(idContact)
        .then(res => {
          context.commit("SET_CONTACT", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  archiveContact(context, data) {
    return archiveContact(data);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
