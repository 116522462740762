import { fetchUser, fetchStockContents } from "@/modules/common/_api";
import { exportStock } from "@/modules/products/stock/_api";
import searchThroughTaxonomy from "@/helpers/_functions/searchThroughTaxonomy";
import * as api from "../_api";

const state = {
  user: {},
  loading: false,
  stockCamion: null,
  stockTaxonomie: null,
  successMessage: null,
  lastRequestId: null
};

const getters = {
  user: state => state.user,
  loading: state => state.loading,
  stockCamion: state => state.stockCamion,
  successMessage: state => state.successMessage
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_STOCK_CAMION: (state, stockCamion) => {
    state.stockCamion = stockCamion;
  },
  SET_STOCK_TAXONOMIE: (state, stockTaxonomie) => {
    state.stockTaxonomie = stockTaxonomie;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_SUCCESS_MESSAGE: (state, successMessage) => {
    state.successMessage = successMessage;
  }
};

const actions = {
  fetchUser(context, idUser) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      fetchUser(idUser)
        .then(res => {
          context.commit("SET_USER", res.data.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },
  searchProducts(context, query) {
    const { stockTaxonomie } = context.state;
    return new Promise(resolve => {
      resolve(searchThroughTaxonomy(stockTaxonomie, query));
    });
  },
  updateStockTaxonomie(context) {
    const { stockCamion } = context.state;
    const stockTaxonomie = stockCamion ? stockCamion.map(item => ({ ...item.product, value: item.productId })) : null;
    context.commit("SET_STOCK_TAXONOMIE", stockTaxonomie);
  },
  fetchStockCamion(context, { requestId, ...params }) {
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { user } = context.state;
      if (!user.stockId) {
        return resolve(null);
      }
      fetchStockContents(user.stockId, params)
        .then(res => {
          const { lastRequestId } = context.state;
          if (lastRequestId !== requestId) {
            return resolve();
          }
          context.commit("SET_STOCK_CAMION", res.data.body || []);
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  exportStockCamion(context, params) {
    return exportStock(context.state.user.stockId, params);
  },
  resetData(context) {
    context.commit("SET_USER", {});
    context.commit("SET_STOCK_CAMION", null);
    context.commit("SET_STOCK_TAXONOMIE", null);
    context.commit("SET_SUCCESS_MESSAGE", null);
  },
  updateSuccessMessage(context, successMessage) {
    context.commit("SET_SUCCESS_MESSAGE", successMessage);
  },
  checkPlanningSlotAvailability(context, { startDate, endDate, userId, interId }) {
    return api.checkPlanningSlotAvailability({ query: { startDate, endDate, userId, interId } });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
