import { fetchAlertTemplates, searchPatients, searchPrescribers } from "@/modules/common/_api";

const state = {
  alertTemplates: [],
  count: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  alertTemplates: state => state.alertTemplates,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ALERT_TEMPLATES: (state, alertTemplates) => {
    state.alertTemplates = alertTemplates;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_TAXONOMIES: (state, taxonomies) => {
    state.taxonomies = taxonomies;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  fetchAlertTemplates(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      fetchAlertTemplates({ ...params, query: formattedQuery })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { count, results } = res.data.body;
          context.commit("SET_COUNT", count);
          context.commit("SET_ALERT_TEMPLATES", results);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { prescribers, patients, ...formattedQuery } = query;
    if (prescribers && prescribers.length) {
      formattedQuery.prescriptionAddressIds = prescribers.map(item => item.prescriptionAddressIds).flat();
    }
    if (patients && patients.length) {
      formattedQuery.contractIds = patients.map(patient => (patient.contracts ? patient.contracts.map(item => item.id) : [])).flat();
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  searchPatients(context, input) {
    const query = { input };

    if (context.rootGetters["login/$user"].divisionIds && context.rootGetters["login/$user"].divisionIds.length) {
      query.divisions = context.rootGetters["login/$user"].divisionIds;
    }

    return searchPatients({ query, skip: 0, limit: 10, source: "local" });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
