<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      transform="rotate(180 12 12)"
      d="M12,2 C17.1528261,2 20.2157365,6.37302926 18.5780964,10.3784468 C18.2402127,11.2048586 17.8309944,11.8427321 17.1850709,12.6384879 C17.0482824,12.8070066 16.5478159,13.4063444 16.5675804,13.3824849 C16.3214437,13.6796197 16.1323116,13.917257 15.9466312,14.1665761 C15.4706074,14.8057491 15.0614778,15.4695835 14.6682019,16.2759754 C14.0403723,17.5633075 13.4789078,19.1717257 12.9711216,21.2385852 C12.7217023,22.2538049 11.2782977,22.2538049 11.0288784,21.2385852 C10.5210922,19.1717257 9.95962774,17.5633075 9.33179809,16.2759754 C8.93852216,15.4695835 8.52939263,14.8057491 8.05336876,14.1665761 C7.86768839,13.917257 7.67855626,13.6796197 7.43241965,13.3824849 C7.45218411,13.4063444 6.9517176,12.8070066 6.81492915,12.6384879 C6.16900558,11.8427321 5.75978727,11.2048586 5.42190363,10.3784468 C3.78426354,6.37302926 6.84717387,2 12,2 Z M12,5 C9.38351835,5 8,6.92166997 8,9 C8,9.55228475 8.44771525,10 9,10 C9.55228475,10 10,9.55228475 10,9 C10,7.9114103 10.6561756,7 12,7 C12.5522847,7 13,6.55228475 13,6 C13,5.44771525 12.5522847,5 12,5 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
