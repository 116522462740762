import { iconUserClassicOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuPatients from "./sub-menus/subMenuPatients";
import subMenuPrescribersMedpro from "./sub-menus/subMenuPrescribersMedpro";
import subMenuCommunities from "./sub-menus/subMenuCommunities";

const localPath = "menus.administration.patients-medecins";

const menuCustomers = {
  title: localize(localPath, "title"),
  slug: "patients-medecins",
  icon: iconUserClassicOutlined,
  disabled: false,
  items: [subMenuPatients, subMenuPrescribersMedpro, subMenuCommunities]
};

export default menuCustomers;
