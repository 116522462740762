import http from "@/services/http";

export const editBanner = data => http.put(`/publications/banner`, data);

export const editNews = data => http.put(`/publications/news`, data);

export const fetchPublication = id => http.get(`/publications/${id}`);

export const deleteNews = id => http.delete(`/publications/news/${id}`);

export const deleteBanner = id => http.delete(`/publications/banner/${id}`);

export const checkBannerPublication = data => http.get(`/publications/banner/check-publication`, { params: data });

export const getNewsPublicationState = () => http.get("/publications/news/get-publication-state");
