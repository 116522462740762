import i18n from "@/i18n";

const i18nKey = "router.admin.publications";

const PublicationsBannerAdd = () => import(/* webpackChunkName: "publication" */ "@/modules/publications/add/_components/PublicationsBannerAdd");
const PublicationsNewsAdd = () => import(/* webpackChunkName: "publication" */ "@/modules/publications/add/_components/PublicationsNewsAdd");
const PublicationsBannerEdit = () => import(/* webpackChunkName: "publication" */ "@/modules/publications/edit/_components/PublicationsBannerEdit");
const PublicationsNewsEdit = () => import(/* webpackChunkName: "publication" */ "@/modules/publications/edit/_components/PublicationsNewsEdit");
const PrescriberContentList = () => import(/* webpackChunkName: "publication" */ "@/modules/publications/list");

const {
  publicationsRights: { ADD_PRESCRIBER_BANNER, ADD_PRESCRIBER_NEWS, ACCESS_PUBLICATIONS }
} = require("@common/services/acm/rights");

export default [
  {
    path: "publications/add/banner",
    name: "publicationsBannerAdd",
    component: PublicationsBannerAdd,
    meta: {
      title: i18n.t(`${i18nKey}.title--publicationsBannerAdd`),
      rights: [ADD_PRESCRIBER_BANNER]
    }
  },
  {
    path: "publications/add/news",
    name: "publicationsNewsAdd",
    component: PublicationsNewsAdd,
    meta: {
      title: i18n.t(`${i18nKey}.title--publicationsNewsAdd`),
      rights: [ADD_PRESCRIBER_NEWS]
    }
  },
  {
    path: "publications/edit/banner/:id",
    name: "publicationsBannerEdit",
    component: PublicationsBannerEdit,
    meta: {
      title: i18n.t(`${i18nKey}.title--publicationsBannerAdd`),
      rights: [ADD_PRESCRIBER_BANNER]
    }
  },
  {
    path: "publications/edit/news/:id",
    name: "publicationsNewsEdit",
    component: PublicationsNewsEdit,
    meta: {
      title: i18n.t(`${i18nKey}.title--publicationsNewsAdd`),
      rights: [ADD_PRESCRIBER_NEWS]
    }
  },
  {
    path: "publications",
    name: "publicationsList",
    component: PrescriberContentList,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--publicationsList`),
      rights: [ACCESS_PUBLICATIONS]
    }
  }
];
