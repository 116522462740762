import { componentNameResolver } from "@common/helpers/settings/overrideResolver";
import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const settings = require(`@/settings/${__PLATFORM__}`);

export default [
  {
    path: "",
    name: "dashboard",
    component: () => {
      const component = componentNameResolver("IndexDashboardAdmin", settings.default.overridedModules);
      return import(`@/modules/dashboard/admin/${component}`);
    },
    props: true,
    meta: {
      title: localize("router.admin.dashboard.title--dashboard")
    }
  }
];
