import eachSeries from "async/eachSeries";
import uniqBy from "lodash/uniqBy";
import bindQueryToFetch from "@/mixins/list/_functions/bindQueryToFetch";
import {
  fetchUsers,
  fetchPatients,
  fetchPrescribers,
  fetchZones,
  fetchLocations,
  fetchCommunities,
  fetchPrescribersGroups,
  fetchProducts,
  fetchProviders,
  fetchStocks,
  fetchGasOxygenLifecycles
} from "@/modules/common/_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  bindQueryToUrl(context, urlQuery) {
    return new Promise((resolve, reject) => {
      if (!urlQuery || !Object.entries(urlQuery).length) return resolve({});

      const formattedQuery = {};

      return eachSeries(
        Object.entries(urlQuery),
        ([key, value], nextQuery) => {
          if (!bindQueryToFetch[key]) {
            formattedQuery[key] = value;
            return nextQuery();
          }
          return context
            .dispatch(bindQueryToFetch[key], { key, value })
            .then(result => {
              Object.assign(formattedQuery, result);
            })
            .catch(err => {
              console.error(`Error while fetching ${key} - ${err}`);
            })
            .finally(() => nextQuery());
        },
        errLoopKeys => {
          if (errLoopKeys) return reject(new Error(errLoopKeys));
          return resolve(formattedQuery);
        }
      );
    });
  },
  fetchUsers(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ users: [] });
      const ids = typeof value === "string" ? [value] : value;
      fetchUsers({ query: { ids } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ users: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchDelegates(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ users: [] });
      const delegateIds = typeof value === "string" ? [value] : value;
      fetchUsers({ query: { ids: delegateIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ delegates: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribers(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ prescribers: [] });
      const ids = typeof value === "string" ? [value] : value;
      fetchPrescribers({ query: { ids } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ prescribers: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribersFromPrescriptionAddresses(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ prescribers: [] });
      const prescriptionAddresses = typeof value === "string" ? [value] : value;
      fetchPrescribers({ query: { prescriptionAddresses } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ prescribers: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPatientsFromContracts(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ patients: [] });
      const contractIds = typeof value === "string" ? [value] : value;
      fetchPatients({ query: { contractIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ patients: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPatients(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ patients: [] });
      const patientIds = typeof value === "string" ? [value] : value;
      fetchPatients({ query: { patientIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ patients: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchZones(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ zones: [] });
      const zoneIds = typeof value === "string" ? [value] : value;
      fetchZones({ query: { zoneIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ zones: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTechniciansFromZones(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ technicians: [] });
      const zoneIds = typeof value === "string" ? [value] : value;
      fetchZones({ query: { zoneIds } })
        .then(res => {
          const { results } = res.data.body;
          const technicians = results.map(item => ({ ...item.mainTech, zoneId: item._id }));
          resolve({ technicians });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchLocations(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ locationCodes: [] });
        return;
      }

      const locationCodes = typeof value === "string" ? [value] : value;

      fetchLocations({ query: { locationCodes } })
        .then(res => {
          const results = res.data.body;
          resolve({ locationCodes: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCommunities(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ communities: [] });
      const communityIds = typeof value === "string" ? [value] : value;
      fetchCommunities({ query: { communityIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ communities: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribersGroups(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ prescribersGroups: [] });
      const prescribersGroupIds = typeof value === "string" ? [value] : value;
      fetchPrescribersGroups({ query: { prescribersGroupIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ prescribersGroups: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProducts(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ products: [] });
      const productIds = typeof value === "string" ? [value] : value;
      fetchProducts({ query: { productIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ products: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProviders(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ prescribersGroups: [] });
      const providerIds = typeof value === "string" ? [value] : value;
      fetchProviders({ query: { providerIds } })
        .then(res => {
          const { results } = res.data.body;
          resolve({ providers: results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchStocks(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ stocks: [] });
      const stockIds = typeof value === "string" ? [value] : value;
      fetchStocks({ query: { stockIds } })
        .then(res => {
          const { body } = res.data;
          resolve({ stocks: body });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchGasOxygenLifecyclesByAgaNumbers(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ agaNumbers: [] });
      const agaNumbers = typeof value === "string" ? [value] : value;
      fetchGasOxygenLifecycles({ query: { gasOxygenLifecycleAgaNumbers: agaNumbers } })
        .then(res => {
          const { results } = res.data.body;
          const agaNumbers = uniqBy(results, "agaNumber");
          resolve({ agaNumbers });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchGasOxygenLifecyclesByBatchNumbers(context, { key, value }) {
    return new Promise((resolve, reject) => {
      if (!value) resolve({ batchNumbers: [] });
      const batchNumbers = typeof value === "string" ? [value] : value;
      fetchGasOxygenLifecycles({ query: { gasOxygenLifecycleBatchNumbers: batchNumbers } })
        .then(res => {
          const { results } = res.data.body;
          const batchNumbers = uniqBy(results, "batchNumber");
          resolve({ batchNumbers });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  mapArray(context, { key, value }) {
    return typeof value === "string" ? { [key]: [value] } : { [key]: value };
  },
  bindBoolean(context, { key, value }) {
    return value === "all" ? { [key]: value } : { [key]: value === "true" || value === true };
  },
  mapBooleanArray(context, { key, value }) {
    return typeof value === "string"
      ? { [key]: [value === "true" || value === true] }
      : {
          [key]: value.map(bool => {
            return bool === "true" || bool === true;
          })
        };
  }
};

export default { state, getters, mutations, actions, namespaced: true };
