import { iconPrescription } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuContracts from "./subMenuContracts";

const localPath = "menus.administration.services";

const menuPrestations = {
  title: localize(localPath, "title"),
  slug: "prestation-ordonnances",
  icon: iconPrescription,
  disabled: false,
  items: [subMenuContracts]
};

export default menuPrestations;
