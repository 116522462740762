import http from "@/services/http";

export const editCommunityInternals = (id, data) => http.put(`/communities/internals/${id}`, data);

export const editCommunityGlobals = (id, data) => http.put(`/communities/globals/${id}`, data);

export const editCommunityTourComment = (id, data) => http.put(`/communities/tour-comment/${id}`, data);

export const editCommunityRealStock = (id, data) => http.put(`/communities/${id}/real-stock`, data);

export const checkCommunityEmailAvailability = params =>
  http.get("/communities/email-availability", {
    params: { email: params.dataToCheck, id: params.idToCheck }
  });
