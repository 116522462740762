import { componentNameResolver } from "@common/helpers/settings/overrideResolver";

import getDefaultDivision from "@ui/helpers/_functions/getDefaultDivision";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin";
const settings = require(`@common/settings/${__PLATFORM__}`);

// Community View
const CommunityViewEntityInterventions = () =>
  import(/* webpackChunkName: "community" */ "@/modules/community/view/_components/CommunityEntityInterventions");

// Community Add
const CommunityAddSearch = () => import(/* webpackChunkName: "community" */ "@/modules/community/add/_components/CommunityAddSearch");

/* ========= COMMUNITIES GROUP ========= */
const CommunitiesGroups = () => import(/* webpackChunkName: "community" */ "@/modules/communities-groups");
const CommunitiesGroupAdd = () => import(/* webpackChunkName: "community" */ "@/modules/communities-group/add");
const CommunitiesGroupEdit = () => import(/* webpackChunkName: "community" */ "@/modules/communities-group/edit");
const CommunitiesGroupSearch = () => import(/* webpackChunkName: "community" */ "@/modules/communities-group/add/_components/CommunitiesGroupSearch");
const CommunitiesGroupAddForm = () => import(/* webpackChunkName: "community" */ "@/modules/communities-group/add/_components/CommunitiesGroupAdd");
const CommunitiesGroupView = () => import(/* webpackChunkName: "community" */ "@/modules/communities-group/view");
const CommunitiesGroupGlobalInformations = () =>
  import(/* webpackChunkName: "community" */ "@ui/pages/communities-group/view/_components/CommunitiesGroupGlobalInformations");

const {
  communitiesRights: { CREATE_COMMUNITY, ACCESS_COMMUNITY, EDIT_COMMUNITY }
} = require("@common/services/acm/rights");

export default [
  {
    path: "establishments",
    alias: "collectivites",
    name: "communities",
    redirect: () => `establishments/${getDefaultDivision()}`
  },
  {
    path: "establishments/:division",
    alias: "collectivites/:division",
    name: "communitiesList",
    component: () => {
      const component = componentNameResolver("IndexCommunitiesList", settings.overridedModules);
      return import(`@/modules/communities/${component}`);
    },
    props: true,
    meta: {
      title: localize(localPath, "communities.title--communitiesList"),
      rights: [ACCESS_COMMUNITY]
    }
  },
  {
    path: "establishment/add",
    alias: "collectivite/creation",
    component: () => {
      const component = componentNameResolver("CommunityAdd", settings.overridedModules);
      return import(`@/modules/community/add/${component}`);
    },
    children: [
      {
        path: "",
        name: "communityAdd",
        component: () => {
          const component = componentNameResolver("CommunityAddForm", settings.overridedModules);
          return import(`@/modules/community/add/_components/${component}`);
        },
        meta: {
          title: localize(localPath, "communities.title--communityAdd"),
          rights: [CREATE_COMMUNITY]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "communityAddSearch") {
            next({ name: "communityAddSearch" });
          } else {
            next();
          }
        }
      },
      {
        path: "search",
        alias: "recherche",
        name: "communityAddSearch",
        component: CommunityAddSearch,
        meta: {
          title: localize(localPath, "communities.title--communityAddSearch"),
          rights: [CREATE_COMMUNITY]
        }
      }
    ]
  },
  {
    path: "establishment/:id",
    alias: "collectivite/:id",
    component: () => {
      const component = componentNameResolver("IndexCommunityPage", settings.overridedModules);
      return import(`@/modules/community/view/${component}`);
    },
    children: [
      {
        path: "",
        name: "communityView",
        meta: {
          title: localize(localPath, "communities.title--communityView"),
          rights: [ACCESS_COMMUNITY]
        },
        component: () => {
          const component = componentNameResolver("IndexCommunityViewInternalInformations", settings.overridedModules);
          return import(`@/modules/community/view/_components/internal-informations/${component}`);
        }
      },
      {
        path: "internal-info/edit",
        alias: "informations-internes/edition",
        name: "communityEditInternalInformations",
        meta: {
          title: localize(localPath, "communities.title--communityView"),
          rights: [EDIT_COMMUNITY]
        },
        component: () => {
          const component = componentNameResolver("IndexCommunityEditInternalInformations", settings.overridedModules);
          return import(`@/modules/community/edit/_components/internal-informations/${component}`);
        }
      },
      {
        path: "info",
        alias: "informations",
        name: "communityViewGlobalInformations",
        meta: {
          title: localize(localPath, "communities.title--communityView"),
          rights: [ACCESS_COMMUNITY]
        },
        component: () => {
          const component = componentNameResolver("IndexCommunityViewGlobalInformations", settings.overridedModules);
          return import(`@/modules/community/view/_components/global-informations/${component}`);
        }
      },
      {
        path: "info/edit",
        alias: "informations/edition",
        name: "communityEditGlobalInformations",
        meta: {
          title: localize(localPath, "communities.title--communityView"),
          rights: [EDIT_COMMUNITY]
        },
        component: () => {
          const component = componentNameResolver("IndexCommunityEditGlobalInformations", settings.overridedModules);
          return import(`@/modules/community/edit/_components/global-informations/${component}`);
        }
      },
      {
        path: "patients",
        name: "communityViewPatientsList",
        meta: {
          title: localize(localPath, "communities.title--communityView"),
          rights: [ACCESS_COMMUNITY]
        },
        component: () => {
          const component = componentNameResolver("IndexCommunityPatientsList", settings.overridedModules);
          return import(`@/modules/community/view/_components/patients-list/${component}`);
        }
      },
      {
        path: "stock",
        name: "communityViewStock",
        meta: {
          title: localize(localPath, "communities.title--communityView"),
          rights: [ACCESS_COMMUNITY]
        },
        component: () => {
          const component = componentNameResolver("IndexCommunityStock", settings.overridedModules);
          return import(`@/modules/community/view/_components/stocks/${component}`);
        }
      },
      {
        path: "deliveries",
        alias: "livraisons",
        name: "communityViewEntityInterventions",
        meta: {
          title: localize(localPath, "communities.title--communityView"),
          rights: [ACCESS_COMMUNITY]
        },
        component: CommunityViewEntityInterventions
      }
    ]
  },
  {
    path: "establishments-groups",
    name: "communitiesGroups",
    redirect: () => `establishments-groups/${getDefaultDivision()}`
  },
  {
    path: "establishments-groups/:division",
    name: "communitiesGroupsList",
    component: CommunitiesGroups,
    props: true,
    meta: {
      title: localize(localPath, "communities-groups.title--communitiesGroupList"),
      rights: [ACCESS_COMMUNITY]
    }
  },
  {
    path: "establishments-groups/add",
    component: CommunitiesGroupAdd,
    children: [
      {
        path: "",
        name: "communitiesGroupAdd",
        component: CommunitiesGroupAddForm,
        meta: {
          title: localize(localPath, "communities-groups.title--communitiesGroupAdd"),
          rights: [CREATE_COMMUNITY]
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== "communitiesGroupAddSearch") {
            next({ name: "communitiesGroupAddSearch" });
          } else {
            next();
          }
        }
      },
      {
        path: "search",
        name: "communitiesGroupAddSearch",
        component: CommunitiesGroupSearch,
        meta: {
          title: localize(localPath, "communities-groups.title--communitiesGroupAddSearch"),
          rights: [CREATE_COMMUNITY]
        }
      }
    ]
  },
  {
    path: "establishments-group/:id",
    component: CommunitiesGroupView,
    children: [
      {
        path: "",
        name: "communitiesGroupView",
        meta: {
          title: localize(localPath, "communities-groups.title--communitiesGroupView"),
          rights: [ACCESS_COMMUNITY]
        },
        component: CommunitiesGroupGlobalInformations
      },
      {
        path: "edit",
        alias: "edition",
        name: "communitiesGroupEdit",
        meta: {
          title: localize(localPath, "communities-groups.title--communitiesGroupView"),
          rights: [EDIT_COMMUNITY]
        },
        component: CommunitiesGroupEdit
      }
    ]
  }
];
