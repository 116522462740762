import http from "@/services/http";

/**
 * @returns {Promise<Array>} Promise containing an array of alerts counts by statuses
 */
export const fetchAlertsSummaries = params => http.get("/alerts/summaries", { params });

/**
 * @returns {Promise<Array>} Promise containing an array of alerts reminders filtered by current user's ID
 */
export const fetchAlertsReminders = params => http.get("/alerts/reminders", { params });
