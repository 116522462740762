import i18n from "@/i18n";

const i18nKey = "router.admin.support-tickets";

const SupportTicketsList = () => import(/* webpackChunkName: "support-tickets" */ "@/modules/support-tickets/list");
const SupportTicketsView = () => import(/* webpackChunkName: "support-tickets" */ "@/modules/support-tickets/view");

const {
  supportTicketsRights: { ACCESS_SUPPORT_TICKETS }
} = require("@common/services/acm/rights");

export default [
  {
    path: "tickets",
    name: "supportTicketsList",
    component: SupportTicketsList,
    meta: {
      title: i18n.t(`${i18nKey}.title--supportTicketsList`),
      rights: [ACCESS_SUPPORT_TICKETS]
    }
  },
  {
    path: "tickets/:id",
    name: "supportTicketsView",
    component: SupportTicketsView,
    meta: {
      title: i18n.t(`${i18nKey}.title--supportTicketView`),
      rights: [ACCESS_SUPPORT_TICKETS]
    }
  }
];
