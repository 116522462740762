import { getSapLogsCount, fetchSapLogs, fetchSapLogById } from "../_api";

const state = {
  sapLogs: [],
  sapLog: {},
  count: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  sapLogs: state => state.sapLogs,
  sapLog: state => state.sapLog,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_SAP_LOGS: (state, sapLogs) => {
    state.sapLogs = sapLogs;
  },
  SET_SAP_LOG: (state, sapLog) => {
    state.sapLog = sapLog;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  fetchSapLogs(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      fetchSapLogs({ ...params, query: { ...formattedQuery } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_SAP_LOGS", results);
          context.commit("SET_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchSapLogById(context, id) {
    return new Promise((resolve, reject) => {
      fetchSapLogById(id)
        .then(res => {
          context.commit("SET_SAP_LOG", res.data.body);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getSapLogsCount(context, params) {
    return new Promise((resolve, reject) => {
      getSapLogsCount(params)
        .then(res => {
          context.commit("SET_COUNT", { count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { creationDate, ...formattedQuery } = query;
    if (creationDate) {
      formattedQuery.creationDateStart = creationDate.start || undefined;
      formattedQuery.creationDateEnd = creationDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
