import divisions from "@ui/helpers/_constants/urlDivisionsParams";
import { fetchCommunities } from "@/modules/common/_api";

import * as api from "../_api";

const state = {
  communities: [],
  currentDivision: null,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  currentCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  allCommunities: state => state.communities,
  currentDivision: state => state.currentDivision,
  count: state => state.count,
  currentCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_COMMUNITIES: (state, communities) => {
    state.communities = communities;
  },
  SET_CURRENT_DIVISION: (state, division) => {
    const currentDivisionParams = divisions.find(item => item.code === division).params;
    state.currentDivision = {
      code: division,
      params: currentDivisionParams
    };
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  exportCommunities(context, data) {
    const { formattedQuery, currentDivision } = context.state;
    const { sort, currentCount } = data;
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    return api.exportCommunities({
      sort,
      query: {
        ...formattedQuery,
        division: currentDivision.code,
        user: {
          email,
          firstname,
          lastname,
          _id
        },
        currentCount
      }
    });
  },

  getCommunitiesCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getCommunitiesCount(params)
        .then(res => {
          context.commit("SET_COUNT", { division: params.query.division.toLowerCase(), count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchAllCommunities(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;

      if (formattedQuery.poleIds && formattedQuery.poleIds.length) {
        formattedQuery.division = "R";
      }

      fetchCommunities({ ...params, query: formattedQuery })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_COMMUNITIES", results);
          context.commit("SET_CURRENT_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { communities, delegates, locationCodes, ...formattedQuery } = query;
    if (communities && communities.length) {
      formattedQuery.communityIds = communities.map(item => item._id);
    }
    if (delegates && delegates.length) {
      formattedQuery.delegateIds = delegates.map(item => item._id);
    }
    if (locationCodes && locationCodes.length) {
      formattedQuery.locationCodes = locationCodes.map(item => item.zipCode || item.departmentCode);
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  setCurrentDivision(context, division) {
    context.commit("SET_CURRENT_DIVISION", division);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
