import http from "@/services/http";

export const getUsersCount = params =>
  new Promise((resolve, reject) => {
    /* resolve(0); */
    http
      .get("/users/count", {
        params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

/**
 * @param {Object} params
 * @returns {Array<Object>} roles
 */
export const searchRoles = params => http.get("/roles/search", { params });

/**
 *
 * @param {Object} params
 * @returns {Buffer} file
 */
export const exportUsers = params =>
  http.get("/users/export", {
    params
  });
