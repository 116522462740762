/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */

import { uploadPublicFile, fetchFile } from "@/modules/common/_api";
import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  editProductSheet(context, { id, cip, ...data }) {
    const { thumbnail, ...product } = data;
    product.files = [thumbnail].filter(file => file);
    product.thumbnail = thumbnail || null;
    return new Promise((resolve, reject) => {
      api
        .editProductSheet(id, product)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editProductTechnicalNews(context, { id, ...data }) {
    const { image, thumbnail, manufacturerDocumentation, internalDocumentation, ...product } = data;
    product.files = [image, thumbnail, manufacturerDocumentation, internalDocumentation].filter(file => file);
    product.image = image || null;
    product.thumbnail = thumbnail || null;
    product.manufacturerDocumentation = manufacturerDocumentation || null;
    product.internalDocumentation = internalDocumentation || null;
    return new Promise((resolve, reject) => {
      api
        .editProductTechnicalNews(id, product)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editProductAald(context, { id, cip, ...data }) {
    return new Promise((resolve, reject) => {
      api
        .editProductAald(id, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPublicFile(formdata);
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
