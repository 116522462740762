import { fetchEntityInterventions, getEntityInterventionsCount, exportEntityInterventions } from "../../common/_api";

const entityTypes = require("@common/constants/entityTypes");

/* eslint-disable no-shadow */

const state = {
  entityInterventions: [],
  currentDivisions: ["R"],
  currentCount: 0,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  entityInterventions: state => state.entityInterventions,
  currentDivisions: state => state.currentDivisions,
  currentCount: state => state.currentCount,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ENTITY_INTERVENTIONS: (state, entityInterventions) => {
    state.entityInterventions = entityInterventions;
  },
  SET_CURRENT_DIVISIONS: (state, divisions) => {
    state.currentDivisions = divisions;
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  exportEntityInterventions(context, { query, ...params }) {
    const { formattedQuery, currentDivisions } = context.state;
    return exportEntityInterventions({ ...params, query: { ...formattedQuery, divisions: currentDivisions, entityTypeId: entityTypes.COMMUNITY } });
  },
  fetchEntityInterventions(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivisions } = context.state;
      fetchEntityInterventions({ ...params, query: { ...formattedQuery, divisions: currentDivisions, entityTypeId: entityTypes.COMMUNITY } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          context.commit("SET_ENTITY_INTERVENTIONS", res?.data?.body?.results || []);
          context.commit("SET_CURRENT_COUNT", res?.data?.body?.count || 0);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getEntityInterventionsCount(context, params) {
    return new Promise((resolve, reject) => {
      getEntityInterventionsCount(params)
        .then(res => {
          context.commit("SET_COUNT", { division: params.query.divisionId.toLowerCase(), count: res.data.body.count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { communities, locationCodes, plannedDate, dateTime, ...formattedQuery } = query;
    if (communities?.length) {
      formattedQuery.communityIds = communities.map(item => item._id);
    }
    if (locationCodes?.length) {
      formattedQuery.locationCodes = locationCodes.map(item => item.zipCode || item.departmentCode);
    }
    if (plannedDate) {
      formattedQuery.plannedDateStart = plannedDate.start || undefined;
      formattedQuery.plannedDateEnd = plannedDate.end || undefined;
    }
    if (dateTime) {
      formattedQuery.dateTimeStart = dateTime.start || undefined;
      formattedQuery.dateTimeEnd = dateTime.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  setCurrentDivisions(context, divisions) {
    context.commit("SET_CURRENT_DIVISIONS", divisions);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
