export default {
  userIds: "fetchUsers",
  delegateIds: "fetchDelegates",
  delegateIdsForPrescriptionAddresses: "fetchDelegates",
  patientIds: "fetchPatients",
  prescriberIds: "fetchPrescribers",
  prescribersGroupIds: "fetchPrescribersGroups",
  prescriptionAddressIds: "fetchPrescribersFromPrescriptionAddresses",
  contractIds: "fetchPatientsFromContracts",
  communityIds: "fetchCommunities",
  productIds: "fetchProducts",
  providerIds: "fetchProviders",
  locationCodes: "fetchLocations",
  zoneIds: "fetchZones",
  patientZoneIds: "fetchTechniciansFromZones",
  stocks: "fetchStocks",
  hasStockId: "bindBoolean",
  gasOxygenLifecycleAgaNumbers: "fetchGasOxygenLifecyclesByAgaNumbers",
  gasOxygenLifecycleBatchNumbers: "fetchGasOxygenLifecyclesByBatchNumbers",
  poleIds: "mapArray",
  channelIds: "mapArray",
  roleIds: "mapArray",
  alertStatusIds: "mapArray",
  alertReminderIds: "mapArray",
  alertTemplateIds: "mapArray",
  alertRetrievalValues: "mapArray",
  alertActionTypes: "mapArray",
  activeStatus: "mapArray",
  contractStatus: "mapArray",
  patientStatus: "mapArray",
  stockStatus: "mapArray",
  tobaccoStatus: "mapBooleanArray",
  alertTemplateTypeIds: "mapArray",
  alertTemplateMonitoringTypeIds: "mapArray",
  conversationTypeIds: "mapArray",
  prescriberSpecialityIds: "mapArray",
  productTypeIds: "mapArray",
  productSubtypeIds: "mapArray",
  treatmentTypeIds: "mapArray",
  interventionStatusIds: "mapArray",
  entityInterventionStatusIds: "mapArray",
  interventionLocationTypes: "mapArray",
  reimbursementModeIds: "mapArray",
  interventionTypeIds: "mapArray",
  deliveryPreferenceIds: "mapArray",
  interventionPlanification: "mapArray",
  interventionCompliances: "mapArray",
  treatmentSubtypeIds: "mapArray",
  administrativeStatus: "mapArray",
  forfaitIds: "mapArray",
  compliances: "mapArray",
  renouTypes: "mapArray",
  renouStates: "mapArray",
  supportTicketStatusIds: "mapArray",
  assignatedUserIds: "mapArray",
  stockMouvementTypes: "mapArray",
  prescriberPrescriptionAddressIds: "mapArray",
  intervenantIds: "mapArray",
  intervenantZoneIds: "mapArray",
  intervenantExternalIds: "mapArray",
  intervenantThatCanRealiseInterventions: "mapArray",
  interventionAnomalyStatusIds: "mapArray",
  entityInterventionAnomalyStatusIds: "mapArray",
  entityInterventionPlanification: "mapArray",
  riskAnalysisStatus: "mapArray",
  active: "bindBoolean",
  attachment: "bindBoolean",
  contactTypes: "mapArray",
  typeIds: "mapArray",
  pathologyIds: "mapArray",
  deliveryPreferences: "mapArray",
  pumpIds: "mapArray",
  hasSensor: "mapBooleanArray",
  mainIntervenantIds: "mapArray",
  reminderStatuses: "mapArray"
};
