const formatInterventionLocation = require("@common/helpers/interventions/formatInterventionLocation");

/**
 * @param {Object} filteredLocations
 * @returns {Array} - formattedLocations
 * @description
 * This function formats the locations of an intervention.
 * It returns an array of objects with label and value properties.
 */

const getInterventionLocationOptions = (typedLocations, platform) => {
  const formattedLocations = [];
  typedLocations.forEach((location, index) => {
    if (location) {
      formattedLocations.push({
        label: formatInterventionLocation(location, platform),
        value: index
      });
    }
  });

  return formattedLocations;
};

module.exports = getInterventionLocationOptions;
