import * as api from "../_api";

const state = {
  feedbackMessages: {
    cautionOfflinePdf: null
  }
};

const actions = {
  updateInterventionByPdf(context, data) {
    return api.updateByPdf(context.state.intervention._id, data);
  }
};

export default { state, actions };
