import { addBanner, addNews, checkBannerPublication, getNewsPublicationState } from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  addBanner(context, data) {
    return addBanner(data);
  },
  addNews(context, data) {
    return addNews(data);
  },
  checkBannerPublication(context, data) {
    return checkBannerPublication(data);
  },
  getNewsPublicationState() {
    return getNewsPublicationState();
  }
};

export default { state, getters, mutations, actions, namespaced: true };
