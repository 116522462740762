import i18n from "@/i18n";

const i18nKey = "router.admin.support-logs";

const SupportLogs = () => import(/* webpackChunkName: "support-logs" */ "@/modules/support-logs/index");
const SapLogView = () => import(/* webpackChunkName: "sap-logs" */ "@/modules/support-logs/sapLogView");

export default [
  {
    path: "logs",
    name: "supportLogs",
    component: SupportLogs,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--supportLogsList`)
    }
  },
  {
    path: "logs/:id",
    name: "sapLogView",
    component: SapLogView,
    meta: {
      title: i18n.t(`${i18nKey}.title--supportLogView`)
    }
  }
];
