/* eslint-disable no-shadow */
import { logout, createResourceLoginAccount } from "@/modules/common/_api";
import { removeAppUser, clearUserBrowserDatas } from "@/services/auth";
import { EventBus } from "@/services/event-bus";
import router from "@/router";

import { loginState, loginGetters, loginMutations, loginActions } from "./index";

const state = loginState;

const getters = loginGetters;

const mutations = loginMutations;

const actions = {
  ...loginActions,
  logoutUser(context) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          removeAppUser().then(() => {
            EventBus.$emit("logout");
            context.dispatch("forceUpdate");
            context.dispatch("unsetUser");
            router
              .push({ name: "login" })
              .catch(() => {})
              .finally(() => {
                resolve();
              });
          });
        })
        .catch(err => {
          clearUserBrowserDatas();
          reject(err);
        });
    });
  },
  createResourceLoginAccount(context, data) {
    return createResourceLoginAccount(data);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
