const Notices = () => import(/* webpackChunkName: "notices" */ "@/modules/noticesManager/list");
const NoticesAdd = () => import(/* webpackChunkName: "noticeAdd" */ "@/modules/noticesManager/add");

const {
  noticesManagerRights: { ACCESS_NOTICES_MANAGER, CREATE_NOTICE }
} = require("@common/services/acm/rights");

export default [
  {
    path: "notices",
    name: "noticesManagerList",
    component: Notices,
    props: true,
    meta: {
      title: "Notices",
      rights: [ACCESS_NOTICES_MANAGER]
    }
  },
  {
    path: "notices/create",
    name: "noticesManagerAdd",
    component: NoticesAdd,
    meta: {
      title: "Ajout d'une notice",
      rights: [CREATE_NOTICE]
    }
  }
];
