import i18n from "@/i18n";

const i18nKey = "router.admin.alerts";

const AlertTemplatesAdd = () => import(/* webpackChunkName: "alerts" */ "@/modules/alertTemplates/add");
const AlertTemplatesList = () => import(/* webpackChunkName: "alerts" */ "@/modules/alertTemplates/list");
const AlertTemplatesEdit = () => import(/* webpackChunkName: "alerts" */ "@/modules/alertTemplates/edit");
const AlertsList = () => import(/* webpackChunkName: "alerts" */ "@/modules/alerts/list");
const AlertsDashboard = () => import(/* webpackChunkName: "alerts" */ "@/modules/alerts/dashboard");
const AlertsReminders = () => import(/* webpackChunkName: "alerts" */ "@/modules/alerts/reminders");

const {
  alertsTemplatesRights: { CREATE_ALERT_TEMPLATE, ACCESS_ALERT_TEMPLATE, EDIT_ALERT_TEMPLATE },
  alertsRights: { ACCESS_ALERT }
} = require("@common/services/acm/rights");

export default [
  {
    path: "alerts/dashboard",
    alias: "alertes/tableau-de-bord",
    name: "alertsDashboard",
    component: AlertsDashboard,
    meta: {
      title: i18n.t(`${i18nKey}.title--alertsDashboard`),
      rights: [ACCESS_ALERT]
    }
  },
  {
    path: "templates",
    alias: "gabarits",
    name: "alertTemplatesList",
    component: AlertTemplatesList,
    meta: {
      title: i18n.t(`${i18nKey}.title--alertTemplatesList`),
      rights: [ACCESS_ALERT_TEMPLATE]
    }
  },
  {
    path: "templates/create",
    alias: "gabarits/creation",
    name: "alertTemplatesAdd",
    component: AlertTemplatesAdd,
    meta: {
      title: i18n.t(`${i18nKey}.title--alertTemplatesAdd`),
      rights: [CREATE_ALERT_TEMPLATE]
    }
  },
  {
    path: "templates/:id/alerts",
    alias: "gabarits/:id/alertes",
    name: "alertsList",
    component: AlertsList,
    meta: {
      title: i18n.t(`${i18nKey}.title--alertsList`),
      rights: [ACCESS_ALERT_TEMPLATE]
    }
  },
  {
    path: "templates/:id/edit",
    alias: "gabarits/:id/edition",
    name: "alertTemplatesEdit",
    component: AlertTemplatesEdit,
    meta: {
      title: i18n.t(`${i18nKey}.title--alertTemplatesEdit`),
      rights: [EDIT_ALERT_TEMPLATE]
    }
  },
  {
    path: "reminders",
    alias: "relances",
    name: "alertsReminders",
    component: AlertsReminders,
    meta: {
      title: i18n.t(`${i18nKey}.title--alertsReminders`),
      rights: [ACCESS_ALERT]
    }
  }
];
