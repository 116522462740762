import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  addNewHsatUpdateStatus(context, data) {
    return new Promise((resolve, reject) => {
      api
        .addNewHsatUpdateStatus(data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
