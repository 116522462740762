import { searchPrescribers } from "@/modules/common/_api";
import * as api from "../_api";

/* eslint-disable no-shadow */

const state = {};

const getters = {};

const mutations = {};

const actions = {
  fetchPrescriber(context, idPrescriber) {
    return new Promise((resolve, reject) => {
      api
        .fetchPrescriber(idPrescriber)
        .then(res => {
          resolve(res.data.body);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkPrescribersGroupEmailAvailability(context, params) {
    return api.checkPrescribersGroupEmailAvailability(params);
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  },
  editPrescribersGroup(context, data) {
    const { delegates, technicians, prescribers, ownerFirstname, ownerLastname, ownerEmail, ...prescribersGroup } = data;
    prescribersGroup.delegates = delegates.map(({ delegate, ...item }) => ({ id: delegate._id, ...item }));
    prescribersGroup.technicians = technicians.map(({ technician, ...item }) => ({ id: technician._id, ...item }));
    prescribersGroup.prescriberIds = prescribers.map(item => item._id);
    prescribersGroup.owner = {
      firstname: ownerFirstname || undefined,
      lastname: ownerLastname || undefined,
      email: ownerEmail || undefined
    };
    prescribersGroup.active = true;

    return new Promise((resolve, reject) => {
      api
        .editPrescribersGroup(prescribersGroup._id, prescribersGroup)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
