import http from "@/services/http";

/**
 * @param {String} productId
 * @returns {Promise} Object
 */
export const fetchProduct = productId => http.get(`/products/${productId}`);

/**
 * @param {String} providerId
 * @returns {Promise} Object
 */
export const fetchProvider = providerId => http.get(`/providers/${providerId}`);
