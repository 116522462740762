/* eslint-disable no-param-reassign */
import eachSeries from "async/eachSeries";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import defaultSuccessMessages from "@/helpers/_constants/defaultSuccessMessages";
import { fetchDeliveries } from "@/modules/common/_api";
import {
  createEntityDeliveries,
  fetchEntityDeliveries,
  updateEntityDeliveries,
  archiveEntityDelivery,
  cancelEntityDeliveryRemoval
} from "@/modules/entity-interventions/common/_api";

const deliveryStatuses = require("@common/constants/deliveryStatuses");
const entityDeliveryStatuses = require("@common/constants/entityDeliveryStatuses");

const state = {
  entityDeliveries: [],
  entityPatientsDeliveries: [],
  feedbackMessages: {
    caution: null,
    errorEquipement: null
  },
  loading: {
    fetchEntityDeliveries: false
  }
};

const getters = {
  entityDeliveries: state => state.entityDeliveries,
  entityPatientsDeliveries: state => state.entityPatientsDeliveries
};

const mutations = {
  SET_ENTITY_DELIVERIES: (state, entityDeliveries) => {
    state.entityDeliveries = entityDeliveries;
  },
  SET_PATIENTS_DELIVERIES: (state, entityPatientsDeliveries) => {
    state.entityPatientsDeliveries = entityPatientsDeliveries;
  }
};

const actions = {
  fetchEntityDeliveries(context, forceRequest = false) {
    const { entityIntervention, entityDeliveries } = context.state;

    if (entityDeliveries?.length && !forceRequest) {
      return;
    }

    const query = {
      entityId: entityIntervention.entityId,
      entityTypeId: entityIntervention.entityTypeId,
      statusIds: [
        entityDeliveryStatuses.toBeRemoved,
        entityDeliveryStatuses.waiting,
        entityDeliveryStatuses.delivered,
        entityDeliveryStatuses.removed
      ]
    };

    context.commit("UPDATE_LOADING", { fetchEntityDeliveries: true });

    fetchEntityDeliveries({ query })
      .then(res => {
        context.commit("SET_ENTITY_DELIVERIES", res?.data?.body?.results || []);
      })
      .catch(err => {
        if (err?.data?.errorMessage) {
          context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
        }
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { fetchEntityDeliveries: false });
      });
  },
  fetchEntityPatientsPendingDeliveries(context, forceRequest = false) {
    const { entityIntervention, entityPatientsDeliveries } = context.state;

    if (entityPatientsDeliveries?.length && !forceRequest) {
      return;
    }

    const query = {
      removalEntityInterventionId: entityIntervention._id,
      statusIds: [deliveryStatuses.toBeRemoved, deliveryStatuses.waiting],
      isRecoverableDelivery: true
    };

    context.commit("UPDATE_LOADING", { fetchEntityDeliveries: true });

    fetchDeliveries({ query, withPatientName: true })
      .then(res => {
        const deliveries = res.data.body.results || [];

        context.commit("SET_PATIENTS_DELIVERIES", deliveries || []);
      })
      .catch(err => {
        if (err?.data?.errorMessage) {
          context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
        }
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { fetchEntityDeliveries: false });
      });
  },
  createDeliveries(context, items) {
    context.commit("UPDATE_LOADING", { submit: true });
    context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });

    const { community, entityIntervention } = context.state;
    const { entityId, entityTypeId } = entityIntervention;
    const entityDeliveriesToCreate = [];

    if (!community?.stock?._id) {
      context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.createDeliveries });
      context.commit("UPDATE_LOADING", { submit: false });
    }

    items.forEach(item => {
      const entityDelivery = {
        productId: item.productId,
        quantity: item.quantity,
        fromStockId: item.stockId,
        toStockId: community?.stock?._id,
        entityId,
        entityTypeId,
        deliveryEntityInterventionId: entityIntervention._id,
        show: item.product.showOnTour,
        statusId: entityDeliveryStatuses.waiting
      };

      if (item.serialNumber) {
        entityDelivery.serialNumber = item.serialNumber;
      }

      if (item.batchNumber) {
        entityDelivery.batchNumber = item.batchNumber;
      }

      if (item.fabricantId) {
        entityDelivery.fabricantId = item.fabricantId;
      }

      if (item.agaNumber) {
        entityDelivery.agaNumber = item.agaNumber;
      }

      entityDeliveriesToCreate.push(entityDelivery);
    });

    eachSeries(
      entityDeliveriesToCreate,
      (entityDelivery, nextEntityDelivery) => {
        createEntityDeliveries(entityDelivery)
          .then(() => nextEntityDelivery())
          .catch(err => nextEntityDelivery(err));
      },
      errLoopEntityDeliveries => {
        context.commit("UPDATE_LOADING", { submit: false });

        if (errLoopEntityDeliveries?.data?.errorMessage) {
          context.dispatch("updateFeedbackMessages", { error: errLoopEntityDeliveries.data.errorMessage });
        }

        context.dispatch("updateFeedbackMessages", { success: defaultSuccessMessages.createEntityDelivery });
        context.dispatch("fetchEntityDeliveries", true);
      }
    );
  },
  removeDelivery(context, entityDelivery) {
    context.commit("UPDATE_LOADING", { submit: true });
    context.dispatch("updateFeedbackMessages", { error: null, success: null, caution: null });

    /* If entityDelivery status is waiting, we have to update entityDelivery's status to archived */
    if (entityDelivery.statusId === entityDeliveryStatuses.waiting) {
      archiveEntityDelivery(entityDelivery._id)
        .then(() => {
          context.dispatch("fetchEntityDeliveries", true);
        })
        .catch(err => {
          if (err?.data?.errorMessage) {
            context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
          }
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { submit: false });
        });

      return;
    }

    /* Else, we have to create new entityDelivery with removalEntityInterventionId */
    const { community, entityIntervention, technician } = context.state;
    const { entityId, entityTypeId } = entityIntervention;

    if (!community?.stock?._id) {
      context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.removeDelivery });
      context.commit("UPDATE_LOADING", { submit: false });
    }

    const newEntityDelivery = {
      posteId: entityDelivery.posteId,
      productId: entityDelivery.productId,
      quantity: entityDelivery.quantity,
      fromStockId: community?.stock?._id,
      toStockId: technician?.stockId,
      entityId,
      entityTypeId,
      show: entityDelivery.product.showOnTour,
      statusId: entityDeliveryStatuses.toBeRemoved,
      removalEntityInterventionId: entityIntervention._id
    };

    if (entityDelivery.serialNumber) {
      newEntityDelivery.serialNumber = entityDelivery.serialNumber;
    }

    if (entityDelivery.batchNumber) {
      newEntityDelivery.batchNumber = entityDelivery.batchNumber;
    }

    if (entityDelivery.fabricantId) {
      newEntityDelivery.fabricantId = entityDelivery.fabricantId;
    }

    if (entityDelivery.agaNumber) {
      newEntityDelivery.agaNumber = entityDelivery.agaNumber;
    }

    createEntityDeliveries(newEntityDelivery)
      .then(() => {
        context.dispatch("fetchEntityDeliveries", true);
      })
      .catch(err => {
        if (err?.data?.errorMessage) {
          context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
        }
      })
      .finally(() => {
        context.commit("UPDATE_LOADING", { submit: false });
      });
  },
  updateEntityDelivery(context, data) {
    context.commit("UPDATE_LOADING", { entityIntervention: true });
    context.dispatch("updateFeedbackMessages", { error: null, caution: null, success: null });

    const { entityDeliveryId, ...update } = data;
    const entityDelivery = context.state.entityDeliveries.find(item => item._id === entityDeliveryId);
    const { product, ...updatedEntityIntervention } = entityDelivery;

    context
      .dispatch("common/checkSerialNumberValidity", data.serialNumber, { root: true })
      .then(equipement => {
        /* If the serial number is known, SAP should return the associated product ID and optionally the fabricant ID */
        if (equipement && equipement.productId) {
          update.fabricantId = equipement.fabricantId || null;
        } else {
          /* Else, the serial number is unknown so we invalid it */
          update.serialNumber = null;
          update.fabricantId = null;

          context.dispatch("updateFeedbackMessages", {
            errorForm: defaultErrorMessages.unknownSAP(data.serialNumber)
          });
        }

        updateEntityDeliveries(entityDeliveryId, { ...updatedEntityIntervention, ...update })
          .then(() => {
            context.dispatch("fetchEntityDeliveries", true);
          })
          .catch(err => {
            if (err?.data?.errorMessage) {
              context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
            }
          })
          .finally(() => {
            context.commit("UPDATE_LOADING", { submit: false });
          });
      })
      .catch(() => {
        context.dispatch("updateFeedbackMessages", { error: defaultErrorMessages.updateEntityDelivery });
        context.commit("UPDATE_LOADING", { entityIntervention: false });
      });
  },
  cancelEntityDeliveryRemoval(context, entityDelivery) {
    cancelEntityDeliveryRemoval(entityDelivery._id)
      .then(() => {
        context.dispatch("fetchEntityDeliveries", true);
      })
      .catch(errCancelRemovalDelivery => {
        if (errCancelRemovalDelivery?.data?.errorMessage) {
          context.dispatch("updateFeedbackMessages", { errorEquipement: errCancelRemovalDelivery.data.errorMessage });
        }
      });
  }
};

export default { state, getters, mutations, actions };
