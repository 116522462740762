import { iconAddCommunityOutlined, iconCommunityOutlined, iconAddCommunitiesGroup, iconCommunitiesGroup } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "menus.administration.patients-medecins.communities";

const {
  communitiesRights: { CREATE_COMMUNITY, ACCESS_COMMUNITY },
  communitiesGroupsRights: { CREATE_COMMUNITIES_GROUP, ACCESS_COMMUNITIES_GROUP }
} = require("@common/services/acm/rights");

const links = [
  {
    icon: iconAddCommunityOutlined,
    title: localize(localPath, "link--communityAddSearch"),
    routerLink: {
      name: "communityAddSearch"
    },
    rights: [CREATE_COMMUNITY]
  },
  {
    icon: iconCommunityOutlined,
    title: localize(localPath, "link--communitiesList"),
    routerLink: {
      name: "communities",
      alias: "communitiesList"
    },
    rights: [ACCESS_COMMUNITY]
  },
  {
    icon: iconAddCommunitiesGroup,
    title: localize(localPath, "link--communitiesGroupAddSearch"),
    routerLink: {
      name: "communitiesGroupAddSearch"
    },
    rights: [CREATE_COMMUNITIES_GROUP]
  },
  {
    icon: iconCommunitiesGroup,
    title: localize(localPath, "link--communitiesGroupsList"),
    routerLink: {
      name: "communitiesGroups"
    },
    rights: [ACCESS_COMMUNITIES_GROUP]
  }
];

const subMenuCommunities = {
  title: localize(localPath, "title"),
  slug: "collectivites",
  links
};

export default subMenuCommunities;
