import http from "@/services/http";

export const exportPrescribers = params =>
  http.get("/prescribers/export", {
    params
  });

export const getPrescribersCount = params =>
  new Promise((resolve, reject) => {
    http
      .get("/prescribers/count", {
        params
      })
      .then(res => {
        resolve(res.data.body.count);
      })
      .catch(err => {
        reject(err);
      });
  });
