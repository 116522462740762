import FileSaver from "file-saver";

/**
 * Download the ical file
 *
 * @param {String} ical The ical string.
 * @param {String} fileName The ical file name
 */
const downloadIcal = (ical, fileName) => {
  const blob = new Blob([ical], {
    type: "text/calendar"
  });

  FileSaver.saveAs(blob, `${fileName}.ics`);
};

export default downloadIcal;
