import { localize } from "../_functions/i18n/dictionaryPathResolver";

const localPath = "constants.default-success-messages";

export default {
  add: localize(localPath, `add`),
  ban: localize(localPath, `ban`),
  unban: localize(localPath, `unban`),
  archive: localize(localPath, `archive`),
  create: localize(localPath, `create`),
  unarchive: localize(localPath, `unarchive`),
  update: localize(localPath, `update`),
  delete: localize(localPath, `delete`),
  fetch: localize(localPath, `fetch`),
  stockMovment: localize(localPath, `stockMovment`),
  emailExport: localize(localPath, `emailExport`),
  riskAnalysis: localize(localPath, `riskAnalysis`),
  closeRiskAnalysis: localize(localPath, `closeRiskAnalysis`),
  messageSent: localize(localPath, `messageSent`),
  synchronize: localize(localPath, `synchronize`),
  createDeliveries: localize(localPath, `createDeliveries`),
  uploadSignatureAndSignBL: localize(localPath, `uploadSignatureAndSignBL`),
  uploadSignature: localize(localPath, `uploadSignature`),
  createDelivery: localize(localPath, `createDelivery`),
  removeProduct: localize(localPath, `removeProduct`),
  replaceProduct: localize(localPath, `replaceProduct`),
  updateLegacyConsommables: localize(localPath, `updateLegacyConsommables`),
  updatePreUseChecklistOfDelivery: localize(localPath, `updatePreUseChecklistOfDelivery`),
  editIntervention: localize(localPath, `editIntervention`),
  equipementAvailabilityCaution: localize(localPath, `equipementAvailabilityCaution`)
};
