const getMessageTypesForAttachment = listConversationsTypes => {
  if (!listConversationsTypes) {
    return;
  }

  const patientCreationMessageTypes = ["newPatient", "newContract"];

  return listConversationsTypes
    .filter(type => !patientCreationMessageTypes.includes(type.value))
    .map(outPatientCreationType => outPatientCreationType.value);
};

module.exports = getMessageTypesForAttachment;
