import http from "@/services/http";

export const editPrescribersGroup = (id, prescribersGroup) => http.put(`/prescribers-groups/${id}`, prescribersGroup);

export const fetchPrescriber = prescriberId => http.get(`/prescribers/${prescriberId}`);

export const checkPrescribersGroupEmailAvailability = params =>
  http.get("/prescribers-groups/email-availability", {
    params: { email: params.dataToCheck, id: params.idToCheck }
  });
