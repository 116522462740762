/* eslint-disable no-shadow */

import * as api from "../_api";

const state = {
  notices: [],
  currentCount: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  notices: state => state.notices,
  currentCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_NOTICES: (state, notices) => {
    state.notices = notices;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  fetchNotices(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      api
        .getNotices({ ...params, query: formattedQuery })
        .then(res => {
          if (!res?.data?.body || lastRequestId !== requestId) {
            return resolve();
          }
          const { results, count } = res.data.body;
          context.commit("SET_NOTICES", results);
          context.commit("SET_CURRENT_COUNT", count);
          return resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { updateDate, productIds, creationDate, ...formattedQuery } = query;

    if (productIds?.length) {
      formattedQuery.productIds = productIds.map(item => item._id);
    }

    if (creationDate) {
      formattedQuery.creationDateStart = creationDate.start || undefined;
      formattedQuery.creationDateEnd = creationDate.end || undefined;
    }
    if (updateDate) {
      formattedQuery.updateDateStart = updateDate.start || undefined;
      formattedQuery.updateDateEnd = updateDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  editNotice(context, notice) {
    return api.editNotice(notice);
  },
  archiveNotice(context, noticeId) {
    return api.archiveNotice(noticeId);
  },
  unarchiveNotice(context, noticeId) {
    return api.unarchiveNotice(noticeId);
  },
  deleteNotice(context, noticeId) {
    return api.deleteNotice(noticeId);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
