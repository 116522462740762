import getDefaultDivision from "@ui/helpers/_functions/getDefaultDivision";

import i18n from "@/i18n";

const i18nKey = "router.admin.products";

// View
const ProductViewSheet = () => import(/* webpackChunkName: "product" */ "@ui/pages/products/view/_components/Sheet");
const ProductViewTechnicalNews = () => import(/* webpackChunkName: "product" */ "@ui/pages/products/view/_components/TechnicalNews");

// Edit
const ProductEditSheet = () => import(/* webpackChunkName: "product" */ "@/modules/products/edit/_components/ProductEditSheet");
const ProductEditTechnicalNews = () => import(/* webpackChunkName: "product" */ "@/modules/products/edit/_components/ProductEditTechnicalNews");

const Products = () => import(/* webpackChunkName: "product" */ "@/modules/products/list");
const ProductView = () => import(/* webpackChunkName: "product" */ "@/modules/products/view");

const StockCamion = () => import(/* webpackChunkName: "product" */ "@/modules/products/stock/_components/StockCamion");
const StockPole = () => import(/* webpackChunkName: "product" */ "@/modules/products/stock/_components/StockPole");
const StockTransfert = () => import(/* webpackChunkName: "product" */ "@/modules/products/stock/_components/StockTransfert");

// Bouteilles O2 gazeux
const GasOxygenMonitoring = () => import(/* webpackChunkName: "product" */ "@/modules/products/gas-oxygen-monitoring");
const GasOxygenHistory = () => import(/* webpackChunkName: "product" */ "@/modules/products/gas-oxygen-history");

const {
  menuRights: { VIEW_MENU_STOCK_POLE },
  productsRights: { ACCESS_PRODUCT, EDIT_PRODUCT, ACCESS_GAS_OXYGEN_MONITORING }
} = require("@common/services/acm/rights");

export default [
  {
    path: "products",
    alias: "produits",
    name: "products",
    redirect: () => `products/${getDefaultDivision()}`
  },
  {
    path: "stock-tech",
    alias: "stock-camion",
    name: "productsStock",
    component: StockCamion,
    meta: {
      title: i18n.t(`${i18nKey}.title--productsStock`),
      rights: [ACCESS_PRODUCT]
    }
  },
  {
    path: "stock-pole",
    name: "productsStockPole",
    component: StockPole,
    meta: {
      title: i18n.t(`${i18nKey}.title--productsStockPole`),
      rights: [VIEW_MENU_STOCK_POLE]
    }
  },
  {
    path: "stock-transfert",
    name: "productsStockTransfert",
    component: StockTransfert,
    meta: {
      title: i18n.t(`${i18nKey}.title--productsStockTransfert`),
      rights: [VIEW_MENU_STOCK_POLE]
    }
  },
  {
    path: "products/:division",
    alias: "produits/:division",
    name: "productsList",
    component: Products,
    meta: {
      title: i18n.t(`${i18nKey}.title--productsList`),
      rights: [ACCESS_PRODUCT]
    }
  },
  {
    path: "products/oxygen-gas/monitoring",
    alias: "produits/oxygene-gazeux/suivi",
    name: "productAgaMonitoring",
    component: GasOxygenMonitoring,
    meta: {
      title: i18n.t(`${i18nKey}.title--productAgaMonitoring`),
      rights: [ACCESS_GAS_OXYGEN_MONITORING]
    }
  },
  {
    path: "products/oxygen-gas/history",
    alias: "produits/oxygene-gazeux/historique",
    name: "productAgaHistory",
    component: GasOxygenHistory,
    meta: {
      title: i18n.t(`${i18nKey}.title--productAgaHistory`),
      rights: [ACCESS_GAS_OXYGEN_MONITORING]
    }
  },
  {
    path: "product/:id",
    alias: "produit/:id",
    component: ProductView,
    children: [
      {
        path: "",
        name: "productView",
        meta: {
          title: i18n.t(`${i18nKey}.title--productView`),
          rights: [ACCESS_PRODUCT]
        },
        component: ProductViewSheet
      },
      {
        path: "technical-news",
        alias: "actualite-technique",
        name: "productViewTechnicalNews",
        meta: {
          title: i18n.t(`${i18nKey}.title--productView`),
          rights: [ACCESS_PRODUCT]
        },
        component: ProductViewTechnicalNews
      },
      {
        path: "card/edit",
        alias: "fiche/edition",
        name: "productEditSheet",
        meta: {
          title: i18n.t(`${i18nKey}.title--productEdit`),
          rights: [EDIT_PRODUCT]
        },
        component: ProductEditSheet
      },
      {
        path: "technical-news/edit",
        alias: "actualite-technique/edition",
        name: "productEditTechnicalNews",
        meta: {
          title: i18n.t(`${i18nKey}.title--productEdit`),
          rights: [EDIT_PRODUCT]
        },
        component: ProductEditTechnicalNews
      }
    ]
  }
];
