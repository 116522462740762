import http from "@/services/http";

// Use POST method and send params into body to avoid error 414
export const fetchInterventionsCount = params => http.post("/interventions/count", params);

export const getTourIcal = params =>
  http.get("/interventions/ical", {
    params
  });

export const generateOfflinePdf = (id, data) => http.put(`/interventions/${id}/generate-offline-pdf`, data);
