import i18n from "@/i18n";

const i18nKey = "router.admin.documents";

const DocumentsList = () => import(/* webpackChunkName: "documents" */ "@/modules/documents/list");

const {
  documentsRights: { VIEW_DOCUMENTS }
} = require("@common/services/acm/rights");

export default [
  {
    path: "documents",
    alias: "base-documentaire",
    name: "documentsList",
    component: DocumentsList,
    meta: {
      title: i18n.t(`${i18nKey}.title--documents`),
      rights: [VIEW_DOCUMENTS]
    }
  }
];
