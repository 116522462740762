import http from "@/services/http";

export const searchGasOxygenLifecycles = params => {
  return http.get("/gas-oxygen/lifecycles/search", {
    params
  });
};

export const searchGasOxygenLifecyclesBatchNumbers = params => {
  return http.get("/gas-oxygen/lifecycles/batch-numbers/search", {
    params
  });
};

export const exportGasOxygenLifecyclesMonitoring = params => {
  return http.get("/gas-oxygen/lifecycles/monitoring/export", {
    params
  });
};
