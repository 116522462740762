const documents = [
  {
    slug: "cptcd.pdf",
    filename: "documentBase-2021-06-15-jFfY0FJK-CPTCD.pdf",
    originalname: "PPC - Certificat de décharge pour désappareillage",
    size: 125012,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["recovery", "partialRecovery"]
  },
  {
    slug: "cptppc.pdf",
    filename: "documentBase-2021-06-15-ZHUVkmgE-CPTPPC.pdf",
    originalname: "PPC - Conditions de remboursement",
    size: 1106741,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"]
  },
  {
    slug: "cptrcb2r.pdf",
    filename: "documentBase-2021-06-15-UNm1JLXI-CPTRCB2R.pdf",
    originalname: "PPC - Contrat de consentement au télésuivi (pôle Réunion)",
    size: 736000,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["ppc"],
    authorizedChannelIds: ["RN"]
  },
  {
    slug: "consent-assist-respi.pdf",
    filename: "documentBase-2023-12-15-consentement-assistance-respiratoire.pdf",
    originalname: "ENR - Fiche de consentement patient Assistance Respiratoire",
    size: 73164,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["ppc"],
    prohibitedChannelIds: ["RN"]
  },
  {
    slug: "consent-oxygenotherapie.pdf",
    filename: "documentBase-2023-12-15-consentement-oxygenotherapie.pdf",
    originalname: "ENR - Fiche de consentement patient oxygénothérapie",
    size: 137407,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["oxy"]
  },
  {
    slug: "decharge-o2.pdf",
    filename: "documentBase-2021-08-16-rEV34c16-Decharge_O2.pdf",
    originalname: "Oxygénothérapie - Certificat de décharge pour désappareillage",
    size: 426728,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "information-tabagisme.pdf",
    filename: "documentBase-2022-12-06-information-tabagisme.pdf",
    originalname: "Informations Tabagisme",
    size: 193580,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "inspection-continue-reservoir-o2.pdf",
    filename: "documentBase-2023-05-31-inspection-continue-reservoir-o2.pdf",
    originalname: "Fiche Inspection Continue réservoir O2",
    size: 151435,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "inspection-continue-reservoir-portable-o2.pdf",
    filename: "documentBase-2023-05-31-inspection-continue-reservoir-portable-o2.pdf",
    originalname: "Fiche Inspection Continue réservoir portable O2",
    size: 152054,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "extranet-mode-opératoire-collaborateur.pdf",
    filename: "documentBase-2023-06-21-extranet-mode-operatoire-collaborateur.pdf",
    originalname: "EXTRANET Mode opératoire Collaborateurs",
    size: 511731,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "extranet-mode-opératoire-patient.pdf",
    filename: "documentBase-2023-06-21-extranet-mode-operatoire-patient.pdf",
    originalname: "EXTRANET Mode opératoire Patient",
    size: 3441864,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  }
];

module.exports = documents;
