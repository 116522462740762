const _ = require("lodash");

/**
 * Remove case and accents from a string
 *
 * @param {String} query
 */
export default query => {
  if (_.isNil(query) || !_.isString(query)) {
    return null;
  }

  if (!query) {
    return "";
  }

  return _.deburr(query)
    .replace(/[\u0300-\u036f, '.']/g, "")
    .toLowerCase();
};
