import { componentNameResolver } from "@common/helpers/settings/overrideResolver";
import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin.contracts";
const settings = require(`@common/settings/${__PLATFORM__}`);

const ContractsAddPrescriptionType = () =>
  import(/* webpackChunkName: "contracts" */ "@/modules/contracts/add/_components/ContractsAddPrescriptionType");
const ContractsAddReimbursementType = () =>
  import(/* webpackChunkName: "contracts" */ "@/modules/contracts/add/_components/ContractsAddReimbursementType");
const ContractsAddPrescriptionAddress = () =>
  import(/* webpackChunkName: "contracts" */ "@/modules/contracts/add/_components/ContractsAddPrescriptionAddress");
const ContractsAddForfaitDiabete = () => import(/* webpackChunkName: "contracts" */ "@/modules/contracts/add/_components/ContractsAddForfaitDiabete");

const {
  contractsRights: { CREATE_CONTRACT }
} = require("@common/services/acm/rights");

export default [
  {
    path: "contracts/add",
    alias: "contrats/creation",
    component: () => {
      const component = componentNameResolver("ContractsAdd", settings.overridedModules);
      return import(`@/modules/contracts/add/${component}`);
    },
    redirect: () => {
      return { name: "contractsAddPatient" };
    },
    meta: {
      rights: [CREATE_CONTRACT]
    },
    children: [
      {
        path: "patient/:patientId?",
        alias: "contrats/creation/patient/:patientId?",
        name: "contractsAddPatient",
        props: true,
        meta: {
          title: localize(localPath, "title--contracts"),
          rights: [CREATE_CONTRACT]
        },
        component: () => {
          const component = componentNameResolver("ContractsAddPatient", settings.overridedModules);
          return import(`@/modules/contracts/add/_components/${component}`);
        }
      },
      {
        path: "type",
        alias: "contrats/creation/type",
        name: "contractsAddPrescriptionType",
        component: ContractsAddPrescriptionType,
        meta: {
          title: localize(localPath, "title--contracts"),
          rights: [CREATE_CONTRACT]
        },
        beforeEnter: (to, from, next) => {
          if (
            [
              "contractsAddPatient",
              "contractsAddPrescriptionType",
              "contractsAddReimbursementType",
              "contractsAddPrescriber",
              "ContractsAddForfaitDiabete",
              "contractsAddForfait",
              "contractsAddResume"
            ].includes(from.name)
          ) {
            return next();
          }

          return next({ name: "contractsAddPatient" });
        }
      },
      {
        path: "reimbursement-type",
        alias: "contrats/creation/tiers-payant",
        name: "contractsAddReimbursementType",
        component: ContractsAddReimbursementType,
        meta: {
          title: localize(localPath, "title--contracts"),
          rights: [CREATE_CONTRACT]
        },
        beforeEnter: (to, from, next) => {
          if (
            [
              "contractsAddPatient",
              "contractsAddPrescriptionType",
              "contractsAddReimbursementType",
              "contractsAddPrescriber",
              "ContractsAddForfaitDiabete",
              "contractsAddForfait",
              "contractsAddResume"
            ].includes(from.name)
          ) {
            return next();
          }
          return next({ name: "contractsAddPatient" });
        }
      },
      {
        path: "contracts/add/prescriber",
        alias: "contrats/creation/medecin",
        name: "contractsAddPrescriber",
        component: ContractsAddPrescriptionAddress,
        meta: {
          title: localize(localPath, "title--contracts"),
          rights: [CREATE_CONTRACT]
        },
        beforeEnter: (to, from, next) => {
          if (
            [
              "contractsAddPatient",
              "contractsAddPrescriptionType",
              "contractsAddReimbursementType",
              "contractsAddPrescriber",
              "ContractsAddForfaitDiabete",
              "contractsAddForfait",
              "contractsAddResume"
            ].includes(from.name)
          ) {
            return next();
          }
          return next({ name: "contractsAddPatient" });
        }
      },
      {
        path: "package",
        alias: "contrats/creation/forfait",
        name: "contractsAddForfait",
        component: () => {
          const component = componentNameResolver("ContractsAddForfait", settings.overridedModules);
          return import(`@/modules/contracts/add/_components/${component}`);
        },
        meta: {
          title: localize(localPath, "title--contracts"),
          rights: [CREATE_CONTRACT]
        },
        beforeEnter: (to, from, next) => {
          if (
            [
              "contractsAddPatient",
              "contractsAddPrescriptionType",
              "contractsAddReimbursementType",
              "contractsAddPrescriber",
              "ContractsAddForfaitDiabete",
              "contractsAddForfait",
              "contractsAddResume"
            ].includes(from.name)
          ) {
            return next();
          }
          return next({ name: "contractsAddPatient" });
        }
      },
      {
        path: "package",
        alias: "contrats/creation/forfait",
        name: "contractsAddForfaitDiabete",
        component: ContractsAddForfaitDiabete,
        meta: {
          title: localize(localPath, "title--contracts"),
          rights: [CREATE_CONTRACT]
        },
        beforeEnter: (to, from, next) => {
          if (
            [
              "contractsAddPatient",
              "contractsAddPrescriptionType",
              "contractsAddReimbursementType",
              "contractsAddPrescriber",
              "ContractsAddForfaitDiabete",
              "contractsAddForfait",
              "contractsAddResume"
            ].includes(from.name)
          ) {
            return next();
          }
          return next({ name: "contractsAddPatient" });
        }
      },
      {
        path: "resume",
        alias: "contrats/creation/recapitulatif",
        name: "contractsAddResume",
        component: () => {
          const component = componentNameResolver("ContractsAddResume", settings.overridedModules);
          return import(`@/modules/contracts/add/_components/${component}`);
        },
        meta: {
          title: localize(localPath, "title--contracts"),
          rights: [CREATE_CONTRACT]
        },
        beforeEnter: (to, from, next) => {
          if (from.name === "contractsAddForfait" || from.name === "contractsAddForfaitDiabete") {
            return next();
          }
          return next({ name: "contractsAddPatient" });
        }
      }
    ]
  }
];
